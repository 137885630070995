import React, { useEffect, useRef, useState } from "react";
import { BrandingConfigurationProps } from "./branding-configuration.props";
import { BrandingConfigurationViewModel } from "./branding-configuration.view-model";
import { useAuth } from "../auth/auth-context";
import {
	AlertContent,
	IFileInfo,
	BusinessNumberModel,
} from "src/types/models/apiModels";
import UploadButton from "src/components/molecules/upload-button/upload-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import IPhone from "./iPhone/iPhone";
import Alert from "./alert/alert";
import AndroidPhone from "./androidPhone/androidPhone";

const BrandingConfiguration: React.FC<BrandingConfigurationProps> = ({
	business,
	number,
	scProvidedNumber,
	editNumber,
	onChange,
}) => {
	const defaultImage = "https://via.placeholder.com/150";
	const defaultAlertContent = {
		title: "",
		body: "",
	};
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BrandingConfigurationViewModel | null>(null);

	const [selectedImage, setSelectedImage] = useState<string>(
		editNumber?.imageFile?.signedUrl ?? defaultImage
	);
	const [selectedImageFile, setSelectedImageFile] = useState<IFileInfo>();
	const [setupAlertContent, setSetupAlertContent] = useState<AlertContent>(
		editNumber?.setupPushContent
			? editNumber.setupPushContent
			: defaultAlertContent
	);
	const [cleanupAlertContent, setCleanupAlertContent] =
		useState<AlertContent>(
			editNumber?.cleanupPushContent
				? editNumber.cleanupPushContent
				: defaultAlertContent
		);
	const [defaultIntent, setDefaultIntent] = useState("");
	const [brandingName, setBrandingName] = useState("");
	const [businessNumber, setBusinessNumber] = useState<
		BusinessNumberModel | undefined
	>();
	const [isModified, setIsModified] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BrandingConfigurationViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchNumber(
						business.businessId,
						number.businessNumber
					);
					console.log("fetchData", response);

					if (response) {
						const defaultPic = response.imageFile
							? response.imageFile
							: business.logo;
						const num: BusinessNumberModel = {
							...response,

							imageFile: defaultPic,
							brandingName:
								response.brandingName ?? business.name,
							defaultIntent:
								response.defaultIntent ?? "Default intent",
							setupPushContent: response.setupPushContent ?? {
								title: business.name,
								body: "Incoming call scheduled...",
							},
							cleanupPushContent: response.cleanupPushContent ?? {
								title: business.name,
								body: "Branding cleared",
							},
							scProvidedNumber: scProvidedNumber,
						};

						setBusinessNumber(() => num);
						if (num.setupPushContent) {
							setSetupAlertContent(
								() =>
									num.setupPushContent ?? defaultAlertContent
							);
						}
						if (num.cleanupPushContent) {
							setCleanupAlertContent(
								() =>
									num.cleanupPushContent ??
									defaultAlertContent
							);
						}

						setSelectedImage(
							() => num.imageFile?.signedUrl ?? defaultImage
						);
						setBrandingName(() => num.brandingName ?? "");
						setDefaultIntent(() => num.defaultIntent ?? "");
						onChange(num);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		if (onChange && businessNumber) {
			onChange(businessNumber);
		}
	}, [businessNumber]);

	useEffect(() => {
		console.log("^^^^defaultIntent", defaultIntent);
		if (businessNumber && selectedImageFile) {
			const updatedNumber: BusinessNumberModel = {
				...businessNumber,
				imageFile: selectedImageFile,
			};
			updatedNumber.setupPushContent = setupAlertContent;
			updatedNumber.cleanupPushContent = cleanupAlertContent;
			updatedNumber.defaultIntent = defaultIntent;
			updatedNumber.brandingName = brandingName;
			updatedNumber.imageFile = selectedImageFile;
			setBusinessNumber(() => updatedNumber);
		}
	}, [
		setupAlertContent,
		cleanupAlertContent,
		defaultIntent,
		brandingName,
		selectedImageFile,
	]);

	const handleSetupAlertChange = (key: keyof AlertContent, value: string) => {
		setSetupAlertContent((prevAlert: AlertContent) => ({
			...prevAlert,
			[key]: value,
		}));

		markModified();
	};

	const handleCleanupAlertChange = (
		key: keyof AlertContent,
		value: string
	) => {
		setCleanupAlertContent((prevAlert: AlertContent) => ({
			...prevAlert,
			[key]: value,
		}));

		markModified();
	};

	const handleImageUpload = async (image: IFileInfo | undefined) => {
		if (image) {
			setSelectedImageFile(() => image);
		}

		if (number.imageFile) {
			number.imageFile.signedUrl = image?.signedUrl;
			number.imageFile.fileName = image?.fileName;
		}

		setSelectedImage(image?.signedUrl ?? defaultImage);
		markModified();
	};

	const markModified = () => {
		number.hasUnsavedChanges = true;
		setIsModified(true);
		onChange(number);
	};

	return (
		<>
			<div className="bg-gradient-to-r from-gray-200 to-gray-300 flex flex-col items-center justify-between gap-4 p-6 h-full w-full rounded-lg">
				<div className="flex items-center justify-between gap-12 w-full h-full">
					<div className="flex flex-col items-center justify-start gap-4 h-full text-gray-800">
						<div className="flex items-center gap-4 h-full">
							{/* iPhone */}
							<div className="flex flex-col items-center justify-center gap-4">
								<Alert
									appImage={selectedImage}
									title={setupAlertContent.title}
									body={setupAlertContent.body}
								></Alert>
								<label className="font-semibold text-2xl text-gray-900">
									iPhone
								</label>
								<IPhone
									brandingImage={selectedImage}
									businessName={brandingName}
									intent={defaultIntent}
								></IPhone>
							</div>

							{/* Android */}
							<div className="flex flex-col items-center justify-center gap-4">
								<Alert
									appImage={selectedImage}
									title={cleanupAlertContent.title}
									body={cleanupAlertContent.body}
								></Alert>
								<label className="font-semibold text-2xl text-gray-900">
									Android
								</label>
								<AndroidPhone
									brandingImage={selectedImage}
									businessName={brandingName}
									intent={defaultIntent}
								></AndroidPhone>
							</div>
						</div>
					</div>

					{/* Edit */}
					<div className="bg-gradient-to-bl from-slate-600 to-slate-400 w-full flex flex-col items-center justify-center gap-4 p-6 rounded-lg drop-shadow-2xl">
						<UploadButton
							defaultImage={selectedImage}
							onUploadSuccess={handleImageUpload}
							borderRadius="50%"
							buttonType="Image"
						/>

						<div className="bg-slate-200 w-full flex flex-col gap-4 p-4">
							<label className="font-bold w-full h6 uppercase text-left text-gray-900">
								Incoming call alert
							</label>
							<InputBox
								value={setupAlertContent?.title}
								onChange={(value) => {
									if (!number.setupPushContent) {
										number.setupPushContent = {
											title: "",
											body: "",
										};
									} else {
										number.setupPushContent.title = value;
									}
									handleSetupAlertChange("title", value);
								}}
								label="Title"
								type={"text"}
								required
							></InputBox>
							<InputBox
								value={setupAlertContent?.body}
								onChange={(value) => {
									if (!number.setupPushContent) {
										number.setupPushContent = {
											title: "",
											body: "",
										};
									} else {
										number.setupPushContent.body = value;
									}
									handleSetupAlertChange("body", value);
								}}
								label="Body"
								type={"text"}
								required
							></InputBox>
						</div>
						<div className="bg-slate-200 w-full flex flex-col gap-4 p-4">
							<label className="font-bold w-full h6 uppercase text-left text-gray-900">
								BRANDING CLEAR ALERT
							</label>
							<InputBox
								value={cleanupAlertContent?.title}
								onChange={(value) => {
									if (!number.cleanupPushContent) {
										number.cleanupPushContent = {
											title: "",
											body: "",
										};
									} else {
										number.cleanupPushContent.title = value;
									}
									handleCleanupAlertChange("title", value);
								}}
								label="Title"
								type={"text"}
								required
							></InputBox>
							<InputBox
								value={cleanupAlertContent?.body}
								onChange={(value) => {
									if (!number.cleanupPushContent) {
										number.cleanupPushContent = {
											title: "",
											body: "",
										};
									} else {
										number.cleanupPushContent.body = value;
									}
									handleCleanupAlertChange("body", value);
								}}
								label="Body"
								type={"text"}
								required
							></InputBox>
						</div>
						<div className="bg-slate-200 w-full flex flex-col gap-4 p-4">
							<label className="font-bold w-full h6 uppercase text-left text-gray-900">
								CALL SCREEN
							</label>
							<InputBox
								value={brandingName}
								onChange={(value) => {
									setBrandingName(value);
									number.brandingName = value;
									markModified();
								}}
								label="Branding Name"
								type={"text"}
								required
							></InputBox>
							<InputBox
								value={defaultIntent}
								onChange={(value) => {
									setDefaultIntent(value);
									number.defaultIntent = value;
									markModified();
								}}
								label="Default Intent"
								type={"text"}
								required
							></InputBox>
						</div>
					</div>
				</div>
				{/* <div className="flex items-center justify-center gap-2 w-full">
					<ButtonPro label={"Submit"}></ButtonPro>
					<ButtonPro
						label={"Cancel"}
						variant={ButtonVariant.Secondary}
					></ButtonPro>
				</div> */}
			</div>
		</>
	);
};

export default BrandingConfiguration;
