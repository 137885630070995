import {
	Box,
	Grid,
	TextField,
	FormControl,
	Alert,
	Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import Banner from "src/components/molecules/banner/Banner";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import "./user-profile.css";
import { useNavigate } from "react-router-dom";
import SectionHeader from "src/components/molecules/section-header/section-header";
import { useAuth } from "../auth/auth-context";
import { UserProfileViewModel } from "./user-profile.view-model";

const UserProfile: React.FC = () => {
	const [viewModel, setViewModel] = useState<UserProfileViewModel | null>(
		null
	);
	const { user } = useAuth();
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<
		"success" | "error"
	>("success");
	// State for profile information
	const [profileInfo, setProfileInfo] = useState({
		firstName: "",
		lastName: "",
	});
	const navigate = useNavigate();

	// State for security information
	const [securityInfo, setSecurityInfo] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	useEffect(() => {
		if (user) {
			// Initialize the ViewModel when user data is available
			const userProfileViewModel = new UserProfileViewModel();
			setViewModel(userProfileViewModel);
			setProfileInfo({
				firstName: user?.givenName || "", // Use user.givenName, fallback to empty string if undefined
				lastName: user?.familyName || "", // Use user.familyName, fallback to empty string if undefined
			});
			const userEmail = user?.username || "";
			setEmail(userEmail);
			// Check if email is empty or null, and navigate to login
			if (!userEmail) {
				navigate("/login");
			}
		} else {
			navigate("/login");
		}
	}, [user, navigate]); // This effect runs when the `user` changes

	// Handler for submitting profile information
	const handleProfileSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		// Handle profile information submission logic here
		console.log("Profile Information Submitted", profileInfo);
	};

	// Handler for submitting security information
	const handleUpdatePasswordSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError("");
		// Check if the existing password is empty
		if (!securityInfo.currentPassword) {
			setError("Current password cannot be empty!");
			return;
		}

		// Check if the new password is empty
		if (!securityInfo.newPassword) {
			setError("New password cannot be empty!");
			return;
		}
		// Handle security information submission logic here
		if (securityInfo.newPassword !== securityInfo.confirmPassword) {
			setError("New password and confirm password don't match!");
			return;
		}
		setLoading(true);
		setError("");
		console.log("Updating password", securityInfo);
		try {
			if (viewModel) {
				await viewModel.changePassword(
					email,
					securityInfo.currentPassword,
					securityInfo.newPassword
				);
				setSnackbarMessage("Password updated successfully!");
				setSnackbarSeverity("success");
				setSnackbarOpen(true);
				console.log("Password updated successfully!");
			}
		} catch (err) {
			const errorMessage = (err as Error).message || JSON.stringify(err);
			setError(errorMessage);
			setSnackbarMessage(errorMessage);
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label="USER PROFILE">
				<Grid
					container
					spacing={2}
					justifyContent="center"
					alignItems="flex-start"
					className="userProfile-container"
				>
					<Grid item xs={12} sm={5}>
						<form
							className="userProfile-form"
							onSubmit={handleProfileSubmit}
						>
							<section className="profile-section">
								<SectionHeader title="Profile Information"></SectionHeader>
								<FormControl fullWidth margin="normal">
									<TextField
										fullWidth
										label="First Name"
										variant="filled"
										margin="normal"
										required
										value={profileInfo.firstName}
										onChange={(e) =>
											setProfileInfo({
												...profileInfo,
												firstName: e.target.value,
											})
										}
										disabled // Disable the field
									/>
								</FormControl>
								<FormControl fullWidth margin="normal">
									<TextField
										fullWidth
										label="Last Name"
										variant="filled"
										margin="normal"
										required
										value={profileInfo.lastName}
										onChange={(e) =>
											setProfileInfo({
												...profileInfo,
												lastName: e.target.value,
											})
										}
										disabled // Disable the field
									/>
								</FormControl>
								<PrimaryButton
									type="submit"
									variant="outlined"
									color="info"
									label="UPDATE PROFILE"
									cssClassName="primary-button"
									disabled={true}
								></PrimaryButton>
							</section>
						</form>
					</Grid>
					{/* Flexible empty space */}
					<Grid item xs={12} md={2} />
					<Grid item xs={12} sm={5}>
						<form
							className="security-form"
							onSubmit={handleUpdatePasswordSubmit}
						>
							<section className="profile-section">
								<SectionHeader title="Password"></SectionHeader>
								<FormControl fullWidth margin="normal">
									<TextField
										fullWidth
										label="Current Password"
										type="password"
										variant="filled"
										margin="normal"
										required
										value={securityInfo.currentPassword}
										onChange={(e) =>
											setSecurityInfo({
												...securityInfo,
												currentPassword: e.target.value,
											})
										}
										error={
											!!error &&
											securityInfo.currentPassword === ""
										}
										helperText={
											securityInfo.currentPassword ===
												"" && error
												? "Current password cannot be empty!"
												: ""
										}
									/>
								</FormControl>
								<FormControl fullWidth margin="normal">
									<TextField
										fullWidth
										label="New Password"
										type="password"
										variant="filled"
										margin="normal"
										required
										value={securityInfo.newPassword}
										onChange={(e) =>
											setSecurityInfo({
												...securityInfo,
												newPassword: e.target.value,
											})
										}
										error={
											!!error &&
											securityInfo.newPassword === ""
										}
										helperText={
											securityInfo.newPassword === "" &&
											error
												? "New password cannot be empty!"
												: ""
										}
									/>
								</FormControl>
								<FormControl fullWidth margin="normal">
									<TextField
										fullWidth
										label="Confirm New Password"
										type="password"
										variant="filled"
										margin="normal"
										required
										value={securityInfo.confirmPassword}
										onChange={(e) =>
											setSecurityInfo({
												...securityInfo,
												confirmPassword: e.target.value,
											})
										}
										error={
											!!error &&
											securityInfo.newPassword !==
												securityInfo.confirmPassword
										}
										helperText={
											securityInfo.newPassword !==
												securityInfo.confirmPassword &&
											error
												? "New password and confirm password don't match!"
												: ""
										}
									/>
								</FormControl>
								<PrimaryButton
									type="submit"
									variant="outlined"
									color="info"
									label="UPDATE PASSWORD"
									cssClassName="primary-button"
									disabled={false}
								></PrimaryButton>
							</section>
						</form>
					</Grid>
				</Grid>
			</Banner>
			<LoadingModal open={loading} message="Please wait..." />
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={() => setSnackbarOpen(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setSnackbarOpen(false)}
					severity={snackbarSeverity}
					variant="filled"
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default UserProfile;
