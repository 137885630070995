import { ApiService } from "src/network/apiService";
import { ContactCentreModel } from "src/types/models/apiModels";

export class NewContactCentreViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async validateContactCentre(
    contactCentre: ContactCentreModel
  ): Promise<string[]> {
    const errors: string[] = [];
    if (!contactCentre.name) {
      errors.push("name is required");
    }
    if (contactCentre.whitelistedUrls.length === 0) {
      errors.push("Please add at least one whitelisted url");
    }

    return errors;
  }

  async saveContactCentre(
    contactCentre: ContactCentreModel
  ): Promise<{ result: boolean; error?: string }> {
    const input = {
      name: contactCentre.name,
      productName: contactCentre.productName,
      whitelistedUrls: contactCentre.whitelistedUrls,
    };

    try {
      const response: any = await this.apiService.postAsync(
        "/onboard/contact-centre",
        input
      );

      if (response) {
        if (response.scResponse && response.scResponse.code === 2000) {
          return { result: true };
        } else {
          return { result: false, error: response.scResponse?.message };
        }
      }
    } catch (error) {
      return {
        result: false,
        error: "There was an error performing the operation",
      };
    }

    return {
      result: true,
      error: "There was an error performing the operation",
    };
  }
}
