import { ApiService } from "src/network/apiService";
import { ContactCentreModel } from "src/types/models/apiModels";

export class ContactCentreListViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async fetchList(): Promise<ContactCentreModel[]> {
    const response = await this.apiService.getAsync<any>(
      "/onboard/contact-centre/list"
    );

    if (response) {
      if (response.scResponse && response.scResponse.code === 2000) {
        return response.scResponse.data;
      }

      return [];
    }

    return [];
  }
}
