import { ApiService } from "src/network/apiService";
import { IFileInfo } from "src/types/models/apiModels";

export class AddOutgoingNumberViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async uploadFileAsync(fileData: FormData): Promise<IFileInfo | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await this.apiService.postAsync<any, FormData>(
      "/onboard/upload-file",
      fileData
    );

    if (response) {
      console.log(`Response -> ${JSON.stringify(response)}`);
      if (response.scResponse && response.scResponse.code === 2000) {
        const data = response.scResponse.data;
        return data;
      }
    }

    return undefined;
  }
}
