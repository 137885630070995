export interface IMenuItem {
  name: string;
  display: string;
}

export enum MenuItems {
  RegistrationBusiness = "registrationBusiness",
  RegistrationNumbers = "registrationNumbers",
  RegistrationMobileApps = "registrationMobileApps",
  RegistrationContactCentres = "registrationContactCentres",
  RegistrationTrialUsers = "registrationTrialUsers",
  BrandingConfigurations = "brandingConfigurations",
  BrandingApplication = "brandingApplication",
  BrandingNumbers = "brandingNumbers",
  BillingPayments = "billingPayments",
  BillingPricing = "billingPricing",
  HelpDocumentation = "helpDocumentation",
  HelpSupport = "helpSupport",
  UserProfile = "userProfile",
  UserManagement = "userManagement",
  Dashboard = "dashboard"
}
