import React from "react";
import { StatusChipProps } from "./status-chip.props";
import { Chip } from "@mui/material";

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  let color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning" = "default";

  switch (status) {
    case "Created":
      color = "info";
      break;
    case "Approved":
      color = "warning";
      break;
    case "Active":
      color = "success";
      break;
    default:
      color = "default";
  }
  return <Chip label={status} color={color} variant="outlined" size="small" />;
};

export default StatusChip;
