import React from "react";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";

interface IActionPanel {
	primaryButtonLabel: string;
	primaryButtonEnabled: boolean;
	secondaryButtonLabel: string;
	secondaryButtonEnabled: boolean;
	primaryButtonAction: () => void;
	secondaryButtonAction: () => void;
}

const ActionPanel: React.FC<IActionPanel> = ({
	primaryButtonLabel,
	primaryButtonAction: primaryAction,
	primaryButtonEnabled,
	secondaryButtonLabel,
	secondaryButtonAction: secondaryAction,
	secondaryButtonEnabled,
}) => {
	return (
		<div className="bg-sky-50 flex items-center justify-center gap-2 w-full p-6 rounded-lg mt-6">
			<ButtonPro
				label={primaryButtonLabel}
				disabled={!primaryButtonEnabled}
				onClick={primaryAction}
			></ButtonPro>
			<ButtonPro
				label={secondaryButtonLabel}
				variant={ButtonVariant.Secondary}
				onClick={secondaryAction}
				disabled={!secondaryButtonEnabled}
			></ButtonPro>
		</div>
	);
};

export default ActionPanel;
