import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "src/components/organisms/auth/auth-context";

const ProtectedRoute: React.FC = () => {
	const { isAuthenticated } = useAuth();

	return isAuthenticated ? <Outlet /> : <Navigate to="/signup" />;
};

export default ProtectedRoute;
