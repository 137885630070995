import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { TableHeaderProps } from "./table-header.props";
import "./table-header.css";

const TableHeader: React.FC<TableHeaderProps> = ({ headers }) => {
  return (
    <TableHead className="custom-table-head">
      <TableRow>
        {headers.map((header, index) => (
          <TableCell key={index} className="custom-table-cell">
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
