import { ApiService } from "src/network/apiService";
import {
  BusinessListModel,
  BusinessNumberModel,
  ContactCentreModel,
  TrialUsersModel,
} from "src/types/models/apiModels";

export class NewBusinessTrialUsersViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async validateTrialUser(
    applicationBusinessCode: string,
    brandedNumber: string,
    emailIds: string[]
  ): Promise<string[]> {
    const errors: string[] = [];
    if (!applicationBusinessCode) {
      errors.push("applicationBusinessCode is required");
    }
    if (!emailIds || emailIds.length === 0) {
      errors.push("emailIds are required");
    }
    if (!brandedNumber) {
      errors.push("brandedNumber is required");
    }

    return errors;
  }

  async saveTrialUser(
    applicationBusinessCode: string,
    brandedNumber: string,
    emailIds: string[]
  ): Promise<{ result: boolean; error?: string }> {
    const input = {
      applicationId: applicationBusinessCode,
      businessId: "",
      maxNumberOfTrialEmails: 10,
      maxNumberOfTrialNumbers: 10,
      maxNumberOfTrialPush: 5,
      maxNumberOfTrialCalls: 5,
      users: emailIds.map((p) => {
        return {
          emailId: p,
          businessNumber: brandedNumber,
        };
      }),
    };

    try {
      const response: any = await this.apiService.postAsync(
        "/onboard/branding/application/enable-trial",
        input
      );

      if (response) {
        if (response.scResponse && response.scResponse.code === 2000) {
          return { result: true };
        } else {
          return { result: false, error: response.scResponse?.message };
        }
      }
    } catch (error) {
      return {
        result: false,
        error: "There was an error performing the operation",
      };
    }

    return {
      result: true,
      error: "There was an error performing the operation",
    };
  }

  async fetchNumbersList(businessId: string): Promise<BusinessNumberModel[]> {
    const response = await this.apiService.getAsync<any>(
      `/onboard/business/${businessId}/number/list`
    );

    if (response) {
      console.log(`/onboard/business/${businessId}/number/list`, response);
      if (response.scResponse && response.scResponse.code === 2000) {
        return response.scResponse.data;
      }

      return [];
    }

    return [];
  }

  async fetchBusinessList(): Promise<BusinessListModel[]> {
    const response = await this.apiService.getAsync<any>(
      "/onboard/business/list"
    );

    if (response) {
      if (response.scResponse && response.scResponse.code === 2000) {
        const data = response.scResponse.data;
        return data.filter(
          (p: BusinessListModel) => p.trialEnabled && p.trialEnabled === true
        );
      }

      return [];
    }

    return [];
  }
}
