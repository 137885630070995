import { ThemeProvider } from "@emotion/react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/atoms/protected-route/ProtectedRoute";
import { AuthProvider } from "./components/organisms/auth/auth-context";
import PageNotFound from "./components/pages/Error/PageNotFound";
import theme from "./theme";
import SignUp from "./components/organisms/auth/sign-up";
import Login from "./components/organisms/auth/login";
import Dashboard from "./components/templates/dashboard/Dashboard";
import ForgotPassword from "./components/organisms/auth/forgot-password";
import ConfirmPassword from "./components/organisms/auth/confirm-password";
import Verify from "./components/organisms/auth/verify";

const App: React.FC = () => (
	<ThemeProvider theme={theme}>
		<CssBaseline /> {/* Normalizes styles across browsers */}
		<GlobalStyles
			styles={{
				body: { fontFamily: "Montserrat, sans-serif" },
				"*": { fontFamily: "Montserrat, sans-serif" },
			}}
		/>
		<AuthProvider>
			<Router>
				<div className="App">
					<main>
						<Routes>
							<Route path="/signup" element={<SignUp />} />
							<Route path="/login" element={<Login />} />
							<Route
								path="/forgot-password"
								element={<ForgotPassword />}
							/>
							<Route
								path="/confirm-password"
								element={<ConfirmPassword />}
							/>
							<Route path="/verify" element={<Verify />} />
							<Route
								path="/dashboard"
								element={<Dashboard />}
							></Route>
							<Route path="/" element={<ProtectedRoute />}>
								<Route path="/" element={<Dashboard />}></Route>
							</Route>
							<Route path="*" element={<PageNotFound />} />
						</Routes>
					</main>
				</div>
			</Router>
		</AuthProvider>
	</ThemeProvider>
);

export default App;
