import React from "react";
import { CircularProgress, Modal, Box, Typography } from "@mui/material";

interface LoadingModalProps {
  open: boolean;
  message?: string;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ open, message }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-indicator"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: "translate(-50%, -50%)" }}
        bgcolor="background.paper"
        borderRadius={2}
        boxShadow={24}
        p={4}
      >
        <CircularProgress />
        {message && (
          <Typography variant="h6" style={{ marginTop: 16 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default LoadingModal;
