import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useAuth } from "../auth/auth-context";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import EntityList from "../entity-list/entity-list";
import { ApplicationListProps } from "./application-list.props";
import { ApplicationListViewModel } from "./application-list.view-model";
import { ApplicationModel } from "src/types/models/apiModels";
import ApplicationCredentials from "../application-credentials/application-credentials";

const ApplicationList: React.FC<ApplicationListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<ApplicationModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<ApplicationListViewModel | null>(null);
	const [selectedApplication, setSelectedApplication] = useState<
		string | null
	>(null);
	const [showApplicationCredentials, setShowApplicationCredentials] =
		useState(false);
	const [showEditApplication, setShowEditApplication] = useState(false);

	const handleSelectApplication = (application: ApplicationModel) => {
		setSelectedApplication(application.brandingApplication.id);
		if (onSelect) {
			onSelect(application);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new ApplicationListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData ->", response);
					setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderBusinessItem = (application: ApplicationModel) => (
		<>
			<div className="flex item-start justify-start w-full">
				<div className="flex items-center justify-center gap-2">
					<img
						className="w-8 h-8 rounded-full flex-shrink-0"
						src={application.business.logo?.signedUrl}
						alt=""
					></img>
					<label className="font-semibold">
						{application.business.name}
					</label>
				</div>
				<div className="flex items-center justify-start gap-4 ml-2">
					{/* Mobile application Section */}
					<div
						className="flex item-center justify-center"
						title={
							application.brandingApplication.name ||
							"No Mobile App"
						}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className={`size-6 ${application.brandingApplication ? "text-gray-800" : "text-red-600"}`}
						>
							<path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
							<path
								fillRule="evenodd"
								d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z"
								clipRule="evenodd"
							/>
						</svg>
					</div>

					{/* Contact Center Section */}
					{application.contactCentre && (
						<div
							className={"flex items-center justify-center"}
							title={
								application.contactCentre
									? application.contactCentre.name
									: "No Contact Centre"
							}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className={`size-6 ${application.contactCentre ? "text-gray-800" : "text-red-600"}`}
							>
								<path
									fillRule="evenodd"
									d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
					)}

					{application.brandingOption ===
					"CPaaSManagedCallBranding" ? (
						<span className="font-semibold text-sm bg-green-600 p-2 px-2 text-white rounded-full">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="size-4"
							>
								<path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
								<path
									fillRule="evenodd"
									d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z"
									clipRule="evenodd"
								/>
							</svg>
						</span>
					) : (
						<span className="font-semibold text-sm bg-blue-400 p-2 px-2 text-white rounded-full">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="size-4"
							>
								<path
									fillRule="evenodd"
									d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
									clipRule="evenodd"
								/>
							</svg>
						</span>
					)}

					{application.status === "Active" && (
						<div
							className="flex item-center justify-center gap-2"
							title="Download SDK(s) and Credentials"
						>
							<a
								className="font-semibold text-sm underline text-blue-600"
								href="#"
								onClick={() => {
									setShowApplicationCredentials(true);
								}}
							>
								Download Credentials
							</a>
						</div>
					)}
				</div>
			</div>
			<Dialog
				open={showApplicationCredentials}
				onClose={() => setShowApplicationCredentials(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<ApplicationCredentials
						brandingAppCode={application.brandingApplication.code}
						ccId={application.contactCentre?.code}
						isNewApp={false}
						onOkClick={() => {
							setShowApplicationCredentials(false);
						}}
					></ApplicationCredentials>
				</DialogContent>
			</Dialog>

			<Dialog
				open={showEditApplication}
				onClose={() => setShowEditApplication(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					{/* <ApplicationEdit
						brandingAppCode={
							application.brandingApplication.code
						}
						ccId={application.contactCentre.code}
						isNewApp={false}
						onOkClick={() => {
							setShowApplicationCredentials(false);
						}}
					></ApplicationEdit> */}
				</DialogContent>
			</Dialog>
		</>
	);

	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	return (
		<div className="rounded-lg drop-shadow-2xl">
			<EntityList<ApplicationModel>
				data={data}
				selectMode={selectMode} // or false depending on your requirements
				onSelect={(selectedBusiness) =>
					handleSelectApplication(selectedBusiness)
				}
				renderChildren={renderBusinessItem}
				onAddNewClick={onAddNewClick}
				listLabel={"Choose Your Application"}
				filterText="Filter on business name"
				enableView={false}
				enableDelete={false}
			/>
		</div>
	);
};

export default ApplicationList;
