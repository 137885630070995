import React, { useEffect, useRef, useState } from "react";
import {
	Grid,
	LinearProgress,
	Typography,
	Link,
	IconButton,
	Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import "./upload-button.css";
import { useAuth } from "src/components/organisms/auth/auth-context";

import { UploadButtonProps } from "./upload-button.props";
import { UploadButtonViewModel } from "./upload-button.view-model";
import { IFileInfo } from "src/types/models/apiModels";

const UploadButton: React.FC<UploadButtonProps> = ({
	onUploadSuccess,
	onClear,
	buttonType,
	borderRadius,
	defaultImage,
}) => {
	const blankImage = "https://via.placeholder.com/150";
	const [fileSrc, setFileSrc] = useState<IFileInfo | undefined>(undefined);
	const [uploading, setUploading] = useState(false);
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [uploadController, setUploadController] =
		useState<AbortController | null>(null);
	const [imageUrl, setImageUrl] = useState<string | undefined>(
		defaultImage ?? blankImage
	);

	const { user } = useAuth();
	const viewModel = useRef<UploadButtonViewModel | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new UploadButtonViewModel(user.accessToken);
		}
	}, [user]);

	useEffect(() => {
		if (defaultImage) {
			setImageUrl(() => defaultImage);
		}
	}, [defaultImage]);

	useEffect(() => {
		if (fileSrc?.signedUrl) {
			setImageUrl(() => fileSrc?.signedUrl);
		} else {
			setImageUrl(() => blankImage);
		}

		// console.log("###### fileSrc-useEffect", fileSrc);
		if (fileSrc) {
			onUploadSuccess(fileSrc);
		}
	}, [fileSrc]);

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectedFile = event.target.files?.[0];

		if (selectedFile) {
			console.log(selectedFile.name);
			setUploading(true);
			setUploadError(null);

			// Show preview of the selected image
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target?.result) {
				}
			};
			reader.readAsDataURL(selectedFile);

			// Create an AbortController to be able to cancel the upload
			const controller = new AbortController();
			setUploadController(controller);

			try {
				// Prepare the form data for upload
				// const formData = new FormData();
				// formData.append("file", selectedFile);
				// formData.append("fileName", selectedFile.name);

				const uploadedFile =
					await viewModel.current?.uploadFileAsync(selectedFile);

				if (!uploadedFile) {
					setUploadError(
						"Looks like the upload didn't go through. Try again?"
					);
				} else {
					const fileInfo: IFileInfo = {
						signedUrl: uploadedFile?.signedUrl,
						fileName: selectedFile.name,
					};
					if (uploadedFile) {
						console.log("#####uploadedFile", uploadedFile);
						setFileSrc(() => fileInfo);
					}
				}
			} catch (error) {
				if (error instanceof Error) {
					if (error.name === "AbortError") {
						console.log("Upload cancelled");
					} else {
						setUploadError(
							"Looks like the upload didn't go through. Try again?"
						);
					}
				} else {
					setUploadError("An unexpected error occurred.");
				}
			} finally {
				setUploading(false);
				setUploadController(null);
			}
		}
	};

	const handleCancelUpload = () => {
		if (uploadController) {
			uploadController.abort();
		}
	};

	const handleRemoveImage = () => {
		setFileSrc(undefined); // Reset to the default image
		setUploadError(null);
		if (onClear) {
			onClear();
		}
	};

	const triggerFileInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<Grid>
			<Grid item sx={{ minWidth: "150px", alignItems: "start" }}>
				<Box display="flex" flexDirection="column" alignItems="center">
					{buttonType !== "File" && (
						<img
							src={imageUrl}
							alt="Uploaded"
							className="h-[150px] w-[150px] border-4 p-1 border-white"
							style={{ borderRadius }}
							onClick={
								buttonType === "Image"
									? triggerFileInput
									: undefined
							}
						/>
					)}

					{buttonType === "Link" && (
						<Link
							component="button"
							variant="body2"
							onClick={triggerFileInput}
							disabled={uploading}
							sx={{
								textDecoration: "none",
								cursor: "pointer",
								color: "blue",
								marginTop: 1,
							}}
						>
							UPLOAD
						</Link>
					)}

					{(buttonType === "Primary" ||
						buttonType === "File" ||
						buttonType === undefined) && (
						<PrimaryButton
							variant="outlined"
							color="info"
							label="BROWSE"
							cssClassName="primary-button"
							disabled={uploading}
							onClick={triggerFileInput}
						/>
					)}

					<input
						type="file"
						hidden
						onChange={handleFileChange}
						ref={fileInputRef}
					/>

					{fileSrc && (
						<Link
							component="button"
							variant="body2"
							onClick={handleRemoveImage}
							sx={{
								textDecoration: "none",
								cursor: "pointer",
								color: "blue",
								marginTop: 1,
							}}
						>
							CLEAR
						</Link>
					)}
				</Box>
			</Grid>

			{uploading && (
				<Grid item sx={{ width: "100%", marginTop: 2 }}>
					<LinearProgress />
					<IconButton onClick={handleCancelUpload} size="small">
						<CancelIcon />
					</IconButton>
				</Grid>
			)}

			{uploadError && (
				<Grid item>
					<Typography color="error">{uploadError}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default UploadButton;
