import React from "react";

interface IHyperLinkProps {
	link: string;
	label: string;
	onClick?: () => void;
	target?: string;
}

const HyperLink: React.FC<IHyperLinkProps> = ({
	link,
	label,
	target,
	onClick,
}) => {
	return (
		<a
			target={target}
			href={link}
			onClick={onClick}
			className="text-blue-600 dark:text-blue-500 hover:text-orange-500 hover:underline"
		>
			{label}
		</a>
	);
};

export default HyperLink;
