import {
	APNSCertificateType,
	InputType,
	OutGoingOnMobileOption,
	ServiceStatus,
	TBrandingOptions,
} from "./common";

export enum FormMode {
	List = "List",
	Edit = "Edit",
	New = "New",
}

export interface IFileInfo {
	fileName?: string;
	signedUrl?: string;
}

export interface IPhoneNumber {
	countryCode: string;
	number: string;
}
export interface ContactModel {
	firstName: string;
	lastName: string;
	emailId: string;
	phoneNumber: IPhoneNumber;
}

export interface RegionalAttribute {
	name: string;
	value?: string;
	type: InputType;
	title: string;
}

export interface BusinessDetailsModel {
	fields: RegionalAttribute[];
}

export interface NewNumberModel {
	number: string;
	ownershipDocuments: IFileInfo[];
	status: ServiceStatus;
}

export interface NewBusinessModel {
	primaryContact: ContactModel;
	details?: BusinessDetailsModel;
	info?: BusinessInfoModel;
	numbers: NewNumberModel[];
}

export interface BusinessModel {
	businessId: string;
	businessCode: string;
	name: string;
	logo: IFileInfo;
	status?: ServiceStatus;
}

export interface BusinessListModel {
	businessId: string;
	businessCode: string;
	name: string;
	status: ServiceStatus;
	trialEnabled?: boolean;
	logo: IFileInfo;
}

export interface BusinessInfoModel {
	businessId: string;
	businessCode: string;
	name: string;
	website: string;
	dunsNumber: string;
	status: ServiceStatus;
	countryCode: string;
	logo?: IFileInfo;
	trialEnabled?: boolean;
}

export interface MobileAppModel {
	id: string;
	code: string;
	name: string;
	businessId?: string;
	iOSAppSettings?: IOSAppSettings;
	androidAppSettings?: AndroidAppSettings;
	bundleId: string;
	packageName?: string;
}

export interface IOSAppSettings {
	bundleId: string;
	certificateType: APNSCertificateType;
	certificateFile: IFileInfo;
	p8FileSettings?: IOSAppP8FileSettings;
	p12FileSettings?: IOSAppP12FileSettings;
}

export interface IOSAppP8FileSettings {
	applePlatformTeamID: string;
	applePlatformKeyID: string;
	applePlatformBundleID: string;
}

export interface IOSAppP12FileSettings {
	p12Password?: string;
}
export interface AndroidAppSettings {
	packageName: string;
	certificateFile: IFileInfo;
}

export interface DeviceCredentialModel {
	iosFileUrl: string;
	androidFileUrl: string;
	secret: string;
}

export interface NewApplicationModel {
	countryCode: string; // Country code where the business is registered
	brandingApplicationId: string;
	contactCentreId: string;
	name: string;
	businessId: string; // ID of the business
	businessUnitId: string; // ID of the business unit
	businessCode: string;
	brandingOption: TBrandingOptions;
	businessNumbers: INewBusinessNumberModel[];
	numberProviderConfig?: VonageApplicationConfig;
	scProvidedNumbers: boolean;
}

export interface ApplicationModel {
	applicationId: string;
	brandingApplication: {
		id: string;
		code: string;
		name: string;
	};
	contactCentre: { id: string; code: string; name: string };
	business: BusinessModel;
	brandingOption: TBrandingOptions;
	scProvidedNumbers: boolean;
	status: string;
	numbers: BusinessNumberModel[];
}

export interface ApplicationListModel {
	applicationId: string;
	brandingApplication: {
		id: string;
		code: string;
		name: string;
	};
	contactCentre: { id: string; code: string; name: string };
	business: { id: string; code: string; name: string };
	brandingOption: TBrandingOptions;
	status: string;
}

export interface INewBusinessNumberModel {
	businessNumber: string;
	brandingName: string;
	defaultIntent: string;
	defaultTTLInMinutes: number;
	imageFile?: IFileInfo;
	setupPushContent: AlertContent;
	cleanupPushContent: AlertContent;
	managedNumberSettings?: ManagedNumberSettings;
	scProvidedNumber: boolean;
}

export interface IBusinessNumberUpdateModel {
	businessNumber: string;
	brandingName: string;
	defaultIntent: string;
	defaultTTLInMinutes: number;
	imageFile?: IFileInfo;
	setupPushContent: AlertContent;
	cleanupPushContent: AlertContent;
}

export interface VonageApplicationConfig {
	applicationId: string;
	privateKeyFile: IFileInfo;
	apiSignatureSecret?: string;
}
export interface AlertContent {
	title: string;
	body: string;
}

export interface ManagedNumberSettings {
	alwaysUseDefaultIntent: boolean;
	outGoingOnMobileOption: OutGoingOnMobileOption;
}

export interface BusinessNumberModel {
	businessNumber: string;
	businessId: string;
	status: ServiceStatus;
	imageFile?: IFileInfo;
	defaultTTLInMinutes?: number;
	brandingName: string;
	defaultIntent: string;
	setupPushContent: AlertContent;
	cleanupPushContent: AlertContent;
	managedNumberSettings?: ManagedNumberSettings;
	applicationBusinessId?: string;
	scProvidedNumber: boolean;
	selected: boolean;
	hasUnsavedChanges: boolean;
	applicationBundleCode: string;
}

export type BusinessNumberEditModel = BusinessNumberModel & {
	isModified: boolean;
};

export interface ManagedNumberSettings {
	numberProvider: string;
	screenSettings: any;
	sipEndpointUrl: string;
	alwaysUseDefaultIntent: boolean;
	outGoingOnMobileOption: OutGoingOnMobileOption;
}

export interface IPhoneNumber {
	countryCode: string;
	number: string;
}
export interface ContactModel {
	firstName: string;
	lastName: string;
	emailId: string;
	phoneNumber: IPhoneNumber;
}

export interface ContactCentreModel {
	contactCentreId: string;
	contactCentreCode: string;
	productName: string;
	name: string;
	businessId?: string;
	whitelistedUrls: string[];
}

export interface TrialUsersModel {
	businessId: string;
	emailId: string;
	brandedNumber: string;
}

export { APNSCertificateType };
export type {
	InputType,
	OutGoingOnMobileOption,
	ServiceStatus,
	TBrandingOptions,
};
