import Lottie from "lottie-react";
import loadingAnimationData from "../../../assets/animations/fileLoadingAnimation.json";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import {
	BusinessNumberModel,
	TBrandingOptions,
} from "src/types/models/apiModels";
import { ApplicationSubmitProcessorViewModel } from "./application-submit-processor-viewmodel";

interface IApplicationSubmitProcessorProps {
	businessId: string;
	applicationId: string;
	brandingOption: TBrandingOptions;
	numberToProcess: BusinessNumberModel[];
	onClose: () => void;
}

const ApplicationSubmitProcessor: React.FC<
	IApplicationSubmitProcessorProps
> = ({
	businessId,
	applicationId,
	brandingOption,
	numberToProcess,
	onClose,
}) => {
	const { user } = useAuth();
	const viewModel = useRef<ApplicationSubmitProcessorViewModel | null>(null);
	const [currentlyProcessingNumber, setCurrentlyProcessingNumber] =
		useState(0);
	const [processingTotal, setProcessingTotal] = useState(0);
	const [isProcessing, setIsProcessing] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const delay = (ms: number) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	useEffect(() => {
		console.log(`ApplicationSubmitProcessor loaded`);
	}, []);

	useEffect(() => {
		const processNumbers = async () => {
			if (!user || !user.accessToken) return;

			viewModel.current = new ApplicationSubmitProcessorViewModel(
				user.accessToken
			);

			// Separate numbers into add and update lists
			const numbersToAdd = numberToProcess.filter(
				(p) => !p.applicationBundleCode
			);
			const numbersToUpdate = numberToProcess.filter(
				(p) => p.applicationBundleCode && p.hasUnsavedChanges
			);

			// Set total count for processing
			const totalNumbers = numbersToAdd.length + numbersToUpdate.length;
			setProcessingTotal(totalNumbers);

			let processedCount = 0;

			// Helper function to process each number
			const processNumber = async (
				number: BusinessNumberModel,
				isAdd: boolean
			) => {
				try {
					if (isAdd) {
						await viewModel.current?.submitNumberAdditionAsync(
							applicationId,
							businessId,
							brandingOption,
							number
						);
					} else {
						await viewModel.current?.submitNumberUpdateAsync(
							businessId,
							brandingOption,
							number
						);
					}
				} catch (error: any) {
					console.error("Error processing number:", error);
					setErrors((prevErrors) => [
						...prevErrors,
						`${number.businessNumber}: ${
							error.message || "Unknown error"
						}`,
					]);
				} finally {
					processedCount++;
					setCurrentlyProcessingNumber(processedCount);
				}
			};

			// Process numbers sequentially with a delay
			for (const number of numbersToAdd) {
				await processNumber(number, true);
				await delay(100); // Add a 100ms delay between each API call
			}
			for (const number of numbersToUpdate) {
				await processNumber(number, false);
				await delay(100); // Add a 100ms delay between each API call
			}

			// Mark processing as complete
			setIsProcessing(false);
		};

		processNumbers();
	}, [user, numberToProcess, applicationId, businessId, brandingOption]);

	return (
		<div className="font-Poppins bg-white flex flex-col items-center justify-center w-full h-full p-12 rounded-2xl">
			<span className="font-semibold text-lg">
				{isProcessing
					? "Do not close this window"
					: "Processing Complete"}
			</span>
			<Lottie
				autoplay
				loop={isProcessing}
				animationData={loadingAnimationData}
				style={{ height: "200px", width: "300px" }}
			/>
			{isProcessing && (
				<span className="font-semibold text-lg text-black">
					Processing {currentlyProcessingNumber} / {processingTotal}
				</span>
			)}
			{!isProcessing && errors.length === 0 && (
				<span className="font-semibold text-lg text-green-500">
					All numbers processed successfully!
				</span>
			)}
			{!isProcessing && errors.length > 0 && (
				<div className="text-red-500">
					<p>Some numbers failed to process:</p>
					<ul>
						{errors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</div>
			)}
			<button
				className="p-4 bg-blue-500 rounded-full px-24 font-semibold text-white mt-4"
				onClick={onClose}
				disabled={isProcessing} // Disable button while processing
			>
				Close
			</button>
		</div>
	);
};

export default ApplicationSubmitProcessor;
