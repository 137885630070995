import React from "react";

interface IStatusBadgeProps {
	status: "New" | "Approved" | "Active" | "Paused" | "Added" | "Pending";
}

const StatusBadge: React.FC<IStatusBadgeProps> = ({ status }) => {
	const statusColors: Record<IStatusBadgeProps["status"], string> = {
		New: "bg-blue-500 text-white",
		Approved: "bg-green-400 text-blue-900",
		Active: "bg-green-600 text-white",
		Paused: "bg-orange-300 text-blue-900",
		Added: "bg-violet-500 text-blue-900",
		Pending: "bg-orange-500 text-blue-900",
	};

	return (
		<div className="rounded-2xl">
			<span
				className={`py-1 px-4 ${statusColors[status]} rounded-full text-sm font-semibold`}
			>
				{status}
			</span>
		</div>
	);
};

export default StatusBadge;
