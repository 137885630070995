import { ApiService } from "src/network/apiService";
import { ApplicationModel } from "src/types/models/apiModels";

export class ApplicationListViewModel {
	private apiService: ApiService;

	constructor(accessToken: string) {
		this.apiService = new ApiService({ accessToken: accessToken });
	}

	async fetchList(): Promise<ApplicationModel[]> {
		const response = await this.apiService.getAsync<any>(
			"/onboard/branding/application/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				// console.log(`response -> ${JSON.stringify(response.scResponse.data)}`)
				return response.scResponse.data;
			}

			return [];
		}

		return [];
	}
}
