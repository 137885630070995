import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import "./SidebarItem.css";
import { ReactComponent as AwworIcon } from "../../../assets/right-arrow.svg";
import { SidebarItemProps } from "./SidebarItem.props";

const SidebarItem: React.FC<SidebarItemProps> = ({
	OnItemClick,
	OnSectionClick,
	childs,
	display,
	Icon,
	name,
	open,
	disabled = false, // default to false
}) => {
	const [selectedItem, setSelectedItem] = useState<string | null>(null);

	function handleListItemClick(item: {
		name: string;
		display: string;
	}): void {
		if (disabled) return; // Prevent interaction if disabled
		console.log("############### In handleListItemClick", item);
		setSelectedItem(item.name); // Update the selected item
		if (OnItemClick) {
			OnItemClick(item.name);
		}
	}

	function handleSectionClick(): void {
		if (disabled) return; // Prevent section click if disabled
		OnSectionClick(name);
	}

	return (
		<>
			<ListItemButton
				onClick={handleSectionClick}
				className={`sidebar-section-header ${disabled ? "sidebar-disabled" : ""}`} // Apply disabled style
				disabled={disabled} // Disable the button if disabled is true
			>
				<ListItemIcon
					className={`sidebar-list-item-pre-icon ${disabled ? "sidebar-disabled-icon" : ""}`}
				>
					<Icon />
				</ListItemIcon>
				<ListItemText
					primary={display}
					className={`sidebar-section-header-text ${disabled ? "sidebar-disabled-text" : ""}`}
				/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open && !disabled} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{childs.map((item, index) => (
						<ListItemButton
							key={index}
							className={`sidebar-list-item ${selectedItem === item.name ? "sidebar-list-item-selected" : ""} ${disabled ? "sidebar-disabled" : ""}`}
							onClick={() => handleListItemClick(item)}
							disabled={disabled} // Disable the child button if disabled is true
						>
							<ListItemText
								className={`sidebar-list-item-text ${selectedItem === item.name ? "sidebar-list-item-text-selected" : ""} ${disabled ? "sidebar-disabled-text" : ""}`}
								primary={item.display}
							/>
							<ListItemIcon
								className={`sidebar-list-item-icon ${selectedItem === item.name ? "sidebar-list-item-icon-selected" : ""} ${disabled ? "sidebar-disabled-icon" : ""}`}
							>
								<AwworIcon />
							</ListItemIcon>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>
	);
};

export default SidebarItem;
