import { ApiService } from "src/network/apiService";
import { IFileInfo } from "src/types/models/apiModels";

export class UploadFileViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async uploadAsync(fileToUpload: any): Promise<IFileInfo | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any =
      await this.apiService.postFileAsync<any>(fileToUpload);

    if (response) {
      // console.log(`Response -> ${JSON.stringify(response)}`);
      if (response.scResponse && response.scResponse.code === 2000) {
        const data = response.scResponse.data;
        const fileInfo = data;
        console.log("#####uploadImageAsync Response", fileInfo);
        return fileInfo;
      }
    }

    return undefined;
  }
}
