import React, { useState } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Chip,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ClipboardService } from "src/helpers/clipboard-service";

interface PasswordRevealInputProps {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
}

const PasswordRevealInput: React.FC<PasswordRevealInputProps> = ({
  label,
  value,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCopyToClipboard = () => {
    if (value) {
      ClipboardService.copyToClipboard(value);
      setCopied(true); // Show copied confirmation
      setTimeout(() => {
        setCopied(false); // Hide after 2 seconds
      }, 2000);
    }
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <TextField
        type={showPassword ? "text" : "password"} // Toggle between text and password
        value={value}
        variant="filled"
        label={label}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              <IconButton onClick={handleCopyToClipboard}>
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {copied && (
        <Chip
          label="Copied to clipboard!"
          color="primary"
          size="small"
          sx={{ position: "absolute", top: -30, right: 0 }}
        />
      )}
    </Box>
  );
};

export default PasswordRevealInput;
