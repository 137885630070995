import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { ContactCentreAddNewProps } from "./contact-centre-add-new.props";
import { useAuth } from "../auth/auth-context";
import { NewContactCentreViewModel } from "./contact-centre-add-new.view-model";
import { ContactCentreModel } from "src/types/models/apiModels";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import InputArray from "src/components/molecules/input-array/input-array";
import SectionHeader from "src/components/molecules/section-header/section-header";
import ErrorPopup from "../error-popup/error-popup";
import ValidatedInput from "src/components/molecules/validated-input/validated-input";
import Dropdown from "src/components/atoms/dropdown/dropdown";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";
import ActionPanel from "../action-panel/action-panel";

const ContactCentreAddNew: React.FC<ContactCentreAddNewProps> = ({
	editContactCentre,
	onSave,
	onCancel,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [contactCentre, setContactCentre] = useState<ContactCentreModel>(
		editContactCentre ?? {
			businessId: "",
			name: "",
			whitelistedUrls: [],
			contactCentreCode: "",
			contactCentreId: "",
			productName: "Custom",
		}
	);
	const viewModel = useRef<NewContactCentreViewModel | null>(null);

	useEffect(() => {
		if (user?.accessToken) {
			viewModel.current = new NewContactCentreViewModel(user.accessToken);
		}
	}, [user]);

	const handleFieldChange = (
		field: keyof typeof contactCentre,
		value: string | string[]
	) => {
		setContactCentre((prevInfo) => ({
			...prevInfo,
			[field]: value,
		}));
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const handleOnChange = (inputStrings: string[]) => {
		handleFieldChange("whitelistedUrls", inputStrings);
	};

	const handleSubmit = async (): Promise<void> => {
		console.log(
			`Saving contact centre -> ${JSON.stringify(contactCentre)}`
		);
		if (onSave && contactCentre) {
			onSave(contactCentre);
		}

		if (contactCentre) {
			if (contactCentre.contactCentreId) {
				// this is a update scenario
				console.log(
					`Contact centre to be updated... ${contactCentre.contactCentreId}`
				);
			} else {
				const validate =
					await viewModel.current?.validateContactCentre(
						contactCentre
					);
				if (validate && validate.length > 0) {
					setError(() => validate.join(","));
					toggleError(true);
				} else {
					const response =
						await viewModel.current?.saveContactCentre(
							contactCentre
						);

					if (response?.result === true) {
						if (onSave) {
							onSave(contactCentre);
						}
					} else {
						setError(() => response?.error ?? "");
						toggleError(true);
					}
				}
			}
		}
	};

	return (
		<div className="rounded-lg drop-shadow-2xl">
			<SectionHeader title="Whitelisted urls"></SectionHeader>
			<Grid container spacing={2} className="form-section">
				<Grid item xs={12} sm={12}>
					<ValidatedInput
						label="Name"
						value={contactCentre?.name ?? ""}
						type="RequiredString"
						onChange={(value) => handleFieldChange("name", value)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Dropdown
						label="Contact centre provider"
						value={contactCentre?.productName ?? "AU"}
						onChange={(value) =>
							handleFieldChange("productName", value)
						}
						menuItems={[
							{ value: "Custom", label: "Custom" },
							{ value: "Avaya", label: "Avaya" },
							{ value: "Cisco-UCCE", label: "Cisco-UCCE" },
							{ value: "Cisco-Webex", label: "Cisco-Webex" },
							{ value: "Genesys Cloud", label: "Genesys Cloud" },
							{
								value: "Genesys Engage",
								label: "Genesys Engage",
							},
							{
								value: "Genesys PureConnect",
								label: "Genesys PureConnect",
							},
							{ value: "NICE-CXone", label: "NICE-CXone" },
							{ value: "Five9", label: "Five9" },
							{
								value: "Microsoft Dynamics 365",
								label: "Microsoft Dynamics 365",
							},
							{
								value: "Azure Communication Services",
								label: "Azure Communication Services",
							},
							{
								value: "Amazon Connect",
								label: "Amazon Connect",
							},
							{ value: "RingCentral", label: "RingCentral" },
							{
								value: "Mitel-MiContact",
								label: "Mitel-MiContact",
							},
							{ value: "Twilio Flex", label: "Twilio Flex" },
							{ value: "Vonage", label: "Vonage" },
							{ value: "SAP", label: "SAP" },
							{ value: "Talkdesk", label: "Talkdesk" },
							{ value: "8x8", label: "8x8" },
							{ value: "Zendesk Talk", label: "Zendesk Talk" },
							{ value: "Zendesk Suite", label: "Zendesk Suite" },
						]}
					/>
				</Grid>

				<Grid item xs={12} sm={12}>
					<InputArray
						inputString={editContactCentre?.whitelistedUrls}
						label="Whitelisted URLs"
						onChange={handleOnChange}
					/>
				</Grid>
			</Grid>
			<ErrorPopup
				message={error}
				open={errorDialogOpen}
				onClose={closeError}
			></ErrorPopup>

			<ActionPanel
				primaryButtonLabel={"submit"}
				primaryButtonEnabled={
					contactCentre &&
					contactCentre.name != "" &&
					contactCentre.whitelistedUrls.length > 0
				}
				secondaryButtonLabel={"cancel"}
				secondaryButtonEnabled={true}
				primaryButtonAction={handleSubmit}
				secondaryButtonAction={handleCancel}
			></ActionPanel>
		</div>
	);
};

export default ContactCentreAddNew;
