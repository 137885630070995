import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import "./application-edit.css";
import HelpText from "src/components/atoms/help-text/help-text";
import HelpIcon from "@mui/icons-material/Help";
import ConfigureBranding from "../configureBranding/configureBranding";
import BusinessNumbersList from "../business-numbers-list/business-numbers-list";
import {
	ApplicationModel,
	BusinessModel,
	BusinessNumberModel,
} from "src/types/models/apiModels";
import ApplicationCredentials from "../application-credentials/application-credentials";
import { ApplicationEditProps } from "./application-edit.props";
import { EditApplicationViewModel } from "./application-edit.view-model";
import mobileApp from "../mobile-app/mobile-app";
import Card from "src/components/molecules/card/card";
import ErrorPopup from "../error-popup/error-popup";
import BrandingConfiguration from "../branding-configuration/branding-configuration";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import CheckBox from "src/components/molecules/check-box/check-box";
import ActionPanel from "../action-panel/action-panel";
import { BusinessNumbersConfigureViewModel } from "../business-numbers-configure/business-numbers-configure.view-model";
import StatusBadge from "src/components/molecules/status-badge/status-badge";
import ApplicationSubmitProcessor from "./application-submit-processor";

const ApplicationEdit: React.FC<ApplicationEditProps> = ({
	onSubmitClick,
	onCancelClick,
	editApplicationModel,
}) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(false);
	const [applicationSubmitDialogOpen, setApplicationSubmitDialogOpen] =
		useState(false);
	const [businessNumberFilteredValue, setBusinessNumberFilteredValue] =
		useState<string>("");
	const [businessNumbers, setBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);
	const [application, setApplication] = useState<
		ApplicationModel | undefined
	>(undefined);
	const [brandingDialogOpen, setBrandingDialogOpen] =
		useState<boolean>(false);
	const [numberDialogOpen, setNumberDialogOpen] = useState<boolean>(false);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [showApplicationCredentials, setShowApplicationCredentials] =
		useState(false);
	const [showHelp, setShowHelp] = useState(false);
	const [error, setError] = useState<string>("");
	const viewModel = useRef<EditApplicationViewModel | null>(null);
	const businessNumbersConfigureViewModel =
		useRef<BusinessNumbersConfigureViewModel | null>(null);
	const [business, setBusiness] = useState<BusinessModel | undefined>(
		undefined
	);
	const [approvedBusinessNumbers, setApprovedBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				if (user && user.accessToken) {
					viewModel.current = new EditApplicationViewModel(
						user.accessToken
					);

					console.log(
						"^^^^editApplicationModel",
						editApplicationModel
					);

					const app = await viewModel.current?.fetchApplication(
						editApplicationModel.applicationId
					);
					console.log("^^^^app", app);

					if (app) {
						setApplication(app);
						setBusiness(app.business); // Set business here
						setBusinessNumbers(app.numbers);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		console.log(
			`UPDATE - number are ${businessNumbers.length} : ${JSON.stringify(businessNumbers)}`
		);
	}, [businessNumbers]);

	useEffect(() => {
		const fetchBusinessNumbers = async () => {
			console.log(`fetch business numbers`);

			if (business) {
				try {
					if (user && user.accessToken) {
						businessNumbersConfigureViewModel.current =
							new BusinessNumbersConfigureViewModel(
								user.accessToken
							);

						const response =
							await businessNumbersConfigureViewModel.current?.fetchList(
								business.businessId
							);

						console.log(
							`number response -> ${JSON.stringify(response)}`
						);

						setApprovedBusinessNumbers(
							response.filter(
								(number) => number.status === "Approved"
							)
						);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
				}
			}
		};

		if (business) {
			fetchBusinessNumbers(); // Call when business is set
		}
	}, [business, user]); // Depend on `business` and `user`

	const toggleAccordion = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const handleSubmitApplication = async () => {
		setApplicationSubmitDialogOpen(true);
		return;
	};
	// try {
	// 	if (user && user.accessToken) {
	// 		viewModel.current = new EditApplicationViewModel(
	// 			user.accessToken
	// 		);
	// 		if (application) {
	// 			const numbers = businessNumbers.map((item) => ({
	// 				businessNumber: item.businessNumber,
	// 				brandingName: item.brandingName || "", // Provide default empty string if undefined
	// 				defaultIntent: item.defaultIntent || "", // Provide default empty string if undefined
	// 				defaultTTLInMinutes: item.defaultTTLInMinutes || 10, // Provide default value 0 if undefined
	// 				imageFile: item.imageFile,
	// 				setupPushContent: item.setupPushContent || {
	// 					title: "",
	// 					body: "",
	// 				}, // Provide default empty AlertContent if undefined
	// 				cleanupPushContent: item.cleanupPushContent || {
	// 					title: "",
	// 					body: "",
	// 				}, // Provide default empty AlertContent if undefined
	// 			}));

	// 			const saveResult = await viewModel.current?.updateNumbers(
	// 				application.business.businessId,
	// 				numbers
	// 			);
	// 			console.log("response", saveResult);
	// 			if (saveResult?.result === true) {
	// 				// onSubmitClick(business);
	// 				setShowApplicationCredentials(true);
	// 			} else {
	// 				setError(() => saveResult?.error ?? "");
	// 				toggleError(true);
	// 			}
	// 		}
	// 	}
	// } catch (error) {
	// 	console.error("Error fetching data:", error);
	// } finally {
	// 	setLoading((prevValue) => {
	// 		if (prevValue) {
	// 			return false;
	// 		}
	// 		return prevValue;
	// 	});
	// }

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const toggleHelp = () => {
		setShowHelp((prev) => !prev);
	};

	const handleCancel = () => {
		if (onCancelClick) {
			onCancelClick();
		}
	};

	const toggleBrandingDialog = () => {
		console.log("In toggleBrandingDialog");
		setBrandingDialogOpen((prevValue) => !prevValue);
	};

	const toggleNumberDialog = () => {
		setNumberDialogOpen((prevValue) => !prevValue);
	};

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		);
	}

	return (
		<>
			{/* Help Text */}
			{showHelp === true && (
				<HelpText
					help={[
						"You can modify the branding configuration from this section. However, only certain details, such as branding for the numbers, can be updated here",
						"To make changes to other settings, such as business details or contact center configurations, please create a new application. If needed, you can delete this application by clicking the delete button at the bottom",
						"Please note, once deleted, the application cannot be recovered",
					]}
					alignment="center"
					onCloseClick={toggleHelp}
				/>
			)}

			{showHelp === false && (
				<Box
					sx={{
						position: "relative",
						marginBottom: 3,
						marginTop: 3,
					}}
				>
					<IconButton
						onClick={toggleHelp}
						sx={{ position: "absolute", top: 0, right: 0 }}
					>
						<HelpIcon />
					</IconButton>
				</Box>
			)}

			{application && (
				<div className="flex flex-col gap-4 w-full">
					<div className="flex items-center justify-start gap-2 w-full">
						<Card
							heading={"Business"}
							code={application.business?.businessCode}
							name={application.business?.name}
						></Card>

						<Card
							heading={"Mobile Application"}
							code={application.brandingApplication?.code}
							name={application.brandingApplication?.name}
						></Card>

						<Card
							heading={"Contact center"}
							code={application.contactCentre?.code}
							name={application.contactCentre?.name}
						></Card>
					</div>
					<div className="flex flex-col items-start justify-start w-full gap-4">
						<span className="font-semibold text-lg uppercase">
							Branding Options
						</span>
						<div className="flex items-center justify-start w-full gap-4">
							<CheckBox
								title={"PSTN"}
								checked
								onChange={function (value: boolean): void {
									console.log("this is display purpose only");
								}}
								disabled
							></CheckBox>
							<CheckBox
								title={"In-App"}
								checked={
									application.brandingOption ===
									"CPaaSManagedCallBranding"
								}
								onChange={function (value: boolean): void {
									console.log("this is display purpose only");
								}}
								disabled
							></CheckBox>
						</div>
					</div>

					<div className=" flex flex-col items-start justify-center w-full mt-6 gap-4">
						<span className="font-semibold text-lg uppercase">
							Branding Numbers ({approvedBusinessNumbers.length})
						</span>

						{application && application.business && (
							<>
								<div className="flex items-center justify-start w-full">
									<ButtonAddNew
										text="Add more"
										onClick={toggleNumberDialog}
										disabled={
											approvedBusinessNumbers.length === 0
										}
									></ButtonAddNew>
								</div>
								<>
									<InputBox
										value={businessNumberFilteredValue}
										label={"Filter"}
										type={"text"}
										onChange={function (
											value: string
										): void {
											setBusinessNumberFilteredValue(
												value
											);
										}}
									></InputBox>

									{/* todo: consider to move this to its own component later. */}
									{businessNumbers && (
										<>
											{/* <div className="flex items-center justify-start w-full">
										<CheckBox
											title="Show Modified only"
											onChange={(
												value: boolean
											): void => {
												setShowModifiedBusinessNumbersOnly(
													value
												);
											}}
											checked={
												showModifiedBusinessNumbersOnly
											}
										></CheckBox>
									</div> */}
											<div
												id="accordion"
												className="w-full"
											>
												<div className="flex items-center justify-start w-full p-2">
													<span className="font-semibold text-sm uppercase">
														Click on individual
														numbers to configure
														branding.
													</span>
												</div>
												{businessNumbers
													.filter(
														(p) =>
															p.status ===
																"Active" ||
															p.status ===
																"Approved"
													)
													.map((item, index) => (
														<div
															key={index}
															className="pb-2"
														>
															<h2
																className={`${item.hasUnsavedChanges ? "bg-gradient-to-r from-sky-400 to-sky-200 hover:bg-sky-200" : ""} flex items-center justify-between w-full py-3 px-4 pe-6 font-medium text-gray-800 bg-slate-200 rounded-xl border-gray-200 hover:bg-gray-100`}
															>
																{/* Accordion toggle area */}
																<button
																	type="button"
																	className="flex items-center justify-start gap-2 w-full"
																	onClick={() =>
																		toggleAccordion(
																			index
																		)
																	}
																	aria-expanded={
																		activeIndex ===
																		index
																	}
																	aria-controls={`accordion-body-${index}`}
																>
																	<img
																		className="w-12 h-12 rounded-full flex-shrink-0"
																		src={
																			item.imageFile
																				? item
																						.imageFile
																						?.signedUrl
																				: business
																						?.logo
																						.signedUrl
																		}
																		alt=""
																	/>

																	<span
																		className={`${item.hasUnsavedChanges ? "text-white" : "text-gray-900"} font-semibold text-lg`}
																	>
																		+
																		{
																			item.businessNumber
																		}
																	</span>
																</button>

																{/* Action buttons */}
																<div className="flex items-center justify-end gap-2 w-fit">
																	{/* Remove button */}
																	<StatusBadge
																		status={
																			item.status
																		}
																	></StatusBadge>

																	{item.applicationBundleCode ? (
																		<div className="rounded-2xl bg-blue-500 w-full">
																			<button
																				className={`py-1 px-4 rounded-full text-sm font-semibold text-white text-nowrap`}
																			>
																				{
																					"Linked"
																				}
																			</button>
																		</div>
																	) : (
																		<div className="rounded-2xl bg-blue-500 w-full">
																			<button
																				className={`py-1 px-4 rounded-full text-sm font-semibold text-white text-nowrap`}
																			>
																				{
																					"Pending"
																				}
																			</button>
																		</div>
																	)}
																	{item.hasUnsavedChanges && (
																		<div className="rounded-2xl bg-orange-500 w-full">
																			<button
																				className={`py-1 px-4 rounded-full text-sm font-semibold text-white text-nowrap`}
																			>
																				{
																					"Modified"
																				}
																			</button>
																		</div>
																	)}
																</div>
															</h2>

															{/* Accordion content */}
															<div
																id={`accordion-body-${index}`}
																className={`overflow-hidden transition-all duration-300 ${
																	activeIndex ===
																	index
																		? "max-h-screen"
																		: "max-h-0"
																}`}
															>
																<div className="pt-2 text-gray-500">
																	{/* {item.content} */}
																	<>
																		{business && (
																			<BrandingConfiguration
																				business={
																					business
																				}
																				number={
																					item
																				}
																				editNumber={
																					undefined
																				}
																				scProvidedNumber={
																					true
																				}
																				onChange={(
																					value
																				) => {
																					setBusinessNumbers(
																						(
																							prevNumbers
																						) => {
																							let isUpdated =
																								false;

																							const updatedNumbers =
																								prevNumbers.map(
																									(
																										num
																									) => {
																										if (
																											num.businessNumber ===
																											value.businessNumber
																										) {
																											isUpdated =
																												true; // Track if a match was found and updated
																											return {
																												...num,
																												...value,
																											};
																										}
																										return num;
																									}
																								);

																							// If no match was found, return the previous array unchanged
																							return isUpdated
																								? updatedNumbers
																								: prevNumbers;
																						}
																					);
																				}}
																			/>
																		)}
																	</>
																</div>
															</div>
														</div>
													))}
											</div>
										</>
									)}
								</>
							</>
						)}
					</div>

					<ActionPanel
						primaryButtonLabel={"Submit"}
						primaryButtonEnabled={
							application.business != undefined &&
							mobileApp != undefined &&
							(businessNumbers.filter(
								(p) => !p.applicationBundleCode
							).length > 0 ||
								businessNumbers.filter(
									(p) => p.hasUnsavedChanges
								).length > 0)
						}
						secondaryButtonLabel={"Cancel"}
						secondaryButtonEnabled={true}
						primaryButtonAction={handleSubmitApplication}
						secondaryButtonAction={handleCancel}
					></ActionPanel>

					<Dialog
						open={applicationSubmitDialogOpen}
						onClose={(event, reason) => {
							// Prevent closing when clicking outside or pressing Escape
							if (
								reason !== "backdropClick" &&
								reason !== "escapeKeyDown"
							) {
								toggleNumberDialog(); // Handle other close actions, if required
							}
						}}
						maxWidth="md"
						fullWidth
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
								width: "100%",
								alignContent: "centre",
								justifyContent: "center",
							},
						}}
					>
						<DialogContent>
							<ApplicationSubmitProcessor
								onClose={() => {
									setApplicationSubmitDialogOpen(false);
									handleCancel();
								}}
								numberToProcess={businessNumbers}
								businessId={application.business.businessId}
								applicationId={application.applicationId}
								brandingOption={application.brandingOption}
							></ApplicationSubmitProcessor>
						</DialogContent>
					</Dialog>

					<Dialog
						open={numberDialogOpen}
						onClose={toggleNumberDialog}
						maxWidth="md"
						fullWidth
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
								width: "100%",
								alignContent: "centre",
								justifyContent: "center",
							},
						}}
					>
						<DialogContent>
							<BusinessNumbersList
								businessNumbers={approvedBusinessNumbers}
								onAddNewClick={function (): void {
									console.log(`not required.`);
								}}
								onClose={toggleNumberDialog}
								onSelect={function (
									selectedNumbers: BusinessNumberModel[]
								): void {
									setBusinessNumbers((prevNumbers) => {
										// Create a map of the existing business numbers for quick lookup
										const existingNumbersMap = new Map(
											prevNumbers.map((num) => [
												num.businessNumber,
												num,
											])
										);

										// Add or update numbers from selectedNumbers
										selectedNumbers.forEach((num) => {
											existingNumbersMap.set(
												num.businessNumber,
												{
													...existingNumbersMap.get(
														num.businessNumber
													),
													...num,
													selected: true, // Mark as selected
												}
											);
										});

										// Remove numbers not in selectedNumbers from businessNumbers unless they have `applicationBundleCode`
										const selectedNumbersSet = new Set(
											selectedNumbers.map(
												(num) => num.businessNumber
											)
										);
										const updatedNumbers = Array.from(
											existingNumbersMap.values()
										).filter(
											(num) =>
												selectedNumbersSet.has(
													num.businessNumber
												) || num.applicationBundleCode // Keep numbers with `applicationBundleCode`
										);

										// Update approvedBusinessNumbers to mark unselected numbers
										setApprovedBusinessNumbers(
											(prevApprovedNumbers) => {
												const approvedNumbersMap =
													new Map(
														prevApprovedNumbers.map(
															(num) => [
																num.businessNumber,
																num,
															]
														)
													);

												// Update the selected property for numbers in approvedBusinessNumbers
												updatedNumbers.forEach(
													(num) => {
														if (
															num.status ===
															"Approved"
														) {
															approvedNumbersMap.set(
																num.businessNumber,
																{
																	...approvedNumbersMap.get(
																		num.businessNumber
																	),
																	...num,
																	selected:
																		num.selected,
																}
															);
														}
													}
												);

												// Mark numbers not in selectedNumbers as unselected
												prevApprovedNumbers.forEach(
													(num) => {
														if (
															!selectedNumbersSet.has(
																num.businessNumber
															) &&
															!num.applicationBundleCode // Only mark as unselected if it doesn't have `applicationBundleCode`
														) {
															approvedNumbersMap.set(
																num.businessNumber,
																{
																	...num,
																	selected:
																		false,
																}
															);
														}
													}
												);

												return Array.from(
													approvedNumbersMap.values()
												);
											}
										);

										return updatedNumbers;
									});
								}}
								selectMode={true}
							></BusinessNumbersList>
						</DialogContent>
					</Dialog>

					<Dialog
						open={brandingDialogOpen}
						onClose={toggleBrandingDialog}
						maxWidth="lg"
						fullWidth
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
								width: "100%",
								alignContent: "centre",
								justifyContent: "center",
							},
						}}
					>
						<DialogContent>
							<ConfigureBranding
								businessId={
									application?.business?.businessCode ?? ""
								}
								businessName={
									application?.business?.name ?? "placeholder"
								}
								defaultIntent="demo intent"
								number="1324354678"
								image={
									application?.business?.logo?.signedUrl ?? ""
								}
							></ConfigureBranding>
						</DialogContent>
					</Dialog>

					<Dialog
						open={showApplicationCredentials}
						onClose={() => setShowApplicationCredentials(false)}
						maxWidth="md"
						fullWidth
						// PaperProps={{
						//   style: {
						//     backgroundColor: "transparent",
						//     boxShadow: "none",
						//     width: "100%",
						//     alignContent: "centre",
						//     justifyContent: "center",
						//   },
						// }}
					>
						<DialogContent>
							<ApplicationCredentials
								brandingAppCode=""
								ccId=""
								isNewApp={true}
								onOkClick={handleCancel}
							></ApplicationCredentials>
						</DialogContent>
					</Dialog>

					<ErrorPopup
						message={error}
						open={errorDialogOpen}
						onClose={closeError}
					></ErrorPopup>
				</div>
			)}
		</>
	);
};

export default ApplicationEdit;
