import React from "react";

interface IAlertProps {
	appImage: string;
	title: string;
	body: string;
}

const Alert: React.FC<IAlertProps> = ({ appImage, title, body }) => {
	return (
		<>
			<div className="bg-gradient-to-bl from-gray-700 to-gray-900 rounded-2xl h-[75px] w-[300px] flex items-center justify-start ps-4 border-4 border-gray-700 p-8">
				<img
					src={appImage}
					alt=""
					className="w-12 h-12 rounded-full"
				></img>
				<div className="flex flex-col items-start justify-center gap-2 w-full ps-2">
					<label className="text-white text-sm text-nowrap">
						{title}
					</label>
					<label className="text-white text-sm text-nowrap">
						{body}
					</label>
				</div>
			</div>
		</>
	);
};

export default Alert;
