import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmailVerification from "./email-verification/email-verification";
import Logo from "src/components/molecules/logo/Logo";

const Verify: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const username = location.state?.businessEmail;

	// useEffect(() => {
	// 	if (!username) {
	// 		navigate("/login");
	// 	}
	// }, [username, navigate]);

	const handleEmailVerification = (isSuccess: boolean) => {
		if (isSuccess) {
			console.log("Mobile verification successful");
			navigate("/");
		} else {
			console.log("Mobile verification failed");
		}
	};

	return (
		<>
			<div className="flex flex-col items-center justify-start md:justify-center h-screen w-screen py-8">
				<div className="bg-white text-gray-900 flex flex-col gap-6 items-center justify-start md:justify-center h-fit w-fit p-12 rounded-2xl md:shadow-[0_30px_50px_rgba(0,0,0,.5)]">
					<Logo></Logo>
					<span className="text-3xl md:text-4xl font-bold font-Poppins">
						Verify your email address
					</span>
					<EmailVerification
						emailAddress={username}
						onVerification={handleEmailVerification}
					></EmailVerification>
				</div>
			</div>
		</>
	);
};

export default Verify;
