import React from "react";
import { CircularProgress } from "@mui/material";

interface LoadingPlaceholderProps {
	message?: string; // Optional message prop
}

const LoadingPlaceHolder: React.FC<LoadingPlaceholderProps> = ({ message }) => {
	return (
		<>
			{message && (
				<div className="flex items-center justify-center bg-white p-2 gap-4">
					<CircularProgress />
					<span className="font-semibold text-lg text-black">
						{message}
					</span>
				</div>
			)}
		</>
	);
};

export default LoadingPlaceHolder;
