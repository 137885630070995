export interface ApiServiceConfig {
	accessToken?: string;
}

export class ApiService {
	private accessToken?: string;

	constructor(config: ApiServiceConfig) {
		this.accessToken = config.accessToken;
	}

	private getHeaders(): Record<string, string> {
		const headers: Record<string, string> = {};
		if (this.accessToken) {
			headers["Authorization"] = `Bearer ${this.accessToken}`;
		}

		return headers;
	}

	private getFileUploadHeaders(
		contentType: string,
		fileName: string
	): Record<string, string> {
		const headers: Record<string, string> = this.getHeaders();

		headers["Content-Type"] = contentType;
		headers["X-Filename"] = fileName;
		return headers;
	}

	private async request<TResponse, TRequest = undefined>(
		url: string,
		method: string,
		body?: TRequest
	): Promise<TResponse> {
		const headers = this.getHeaders();

		const response = await fetch(
			`https://${process.env.REACT_APP_BE_DOMAIN}${url}`,
			{
				method,
				headers,
				body: body ? JSON.stringify(body) : (body as any),
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response.json();
	}

	public getAsync<TResponse>(url: string): Promise<TResponse> {
		return this.request<TResponse>(url, "GET");
	}

	public async postFileAsync<TResponse>(file: any): Promise<TResponse> {
		const url = "/onboard/upload-file";
		const method = "POST";
		const contentType = file.type;
		const fileName = file.name;
		const headers = this.getFileUploadHeaders(contentType, fileName);

		const response = await fetch(
			`https://${process.env.REACT_APP_BE_DOMAIN}${url}`,
			{
				method,
				headers,
				body: file,
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response.json();
	}

	public postAsync<TResponse, TRequest>(
		url: string,
		body: TRequest
	): Promise<TResponse> {
		return this.request<TResponse, TRequest>(url, "POST", body);
	}

	public putAsync<TResponse, TRequest>(
		url: string,
		body: TRequest
	): Promise<TResponse> {
		return this.request<TResponse, TRequest>(url, "PUT", body);
	}

	public delete<TResponse>(url: string): Promise<TResponse> {
		return this.request<TResponse>(url, "DELETE");
	}
}
