import React, { useEffect, useState } from "react";
import {
	Drawer,
	IconButton,
	List,
	Divider,
	Box,
	Grid,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { ReactComponent as RegistrationIcon } from "../../../assets/registration.svg";
import { ReactComponent as BillingIcon } from "../../../assets/billing.svg";
import { ReactComponent as HelpIcon } from "../../../assets/help.svg";
import { ReactComponent as BrandingIcon } from "../../../assets/branding.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import Logo from "src/components/molecules/logo/Logo";
import SidebarItem from "src/components/molecules/sidebar-item/SidebarItem";
import { SidebarProps } from "./Sidebar.props";
import { MenuItems } from "src/types/models/menu";
import { useAuth } from "../auth/auth-context";

const Sidebar: React.FC<SidebarProps> = ({ OnToggleDrawer, OnItemClick }) => {
	type Section = "registration" | "branding" | "billing" | "help";
	const { user, logout } = useAuth();
	const userRole = user ? user.role : undefined;
	const [drawerOpen, setDrawerOpen] = useState(true);
	const [showMenu, setShowMenu] = useState(false);
	const userManagementRoles = ["RootUser", "AccountAdmin"];
	const [openSections, setOpenSections] = useState({
		registration: true,
		branding: true,
		billing: true,
		help: true,
	});

	useEffect(() => {
		OnToggleDrawer(drawerOpen);
	}, [drawerOpen]);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const toggleDrawer = () => {
		setDrawerOpen((prevValue) => {
			return !prevValue;
		});
	};

	const handleSectionClick = (sectionName: string) => {
		const sec = sectionName as Section;
		setOpenSections((prevState) => ({
			...prevState,
			[sectionName]: !prevState[sec],
		}));
	};

	const handleItemClick = (itemName: string) => {
		if (OnItemClick) {
			OnItemClick(itemName);
		}
	};

	const handleProfile = () => {
		// Navigate to the profile page
		OnItemClick("userProfile");
		setShowMenu(false);
	};

	const handleUserManagement = () => {
		// Navigate to the user management page
		OnItemClick("userManagement");
		setShowMenu(false);
	};

	const handleLogout = () => {
		logout(); // Call the logout function to log the user out
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "stretch",
				height: "100vh", // Ensure the sidebar takes the full height
			}}
		>
			{/* Mini Drawer (IconButton only) */}
			<Box
				sx={{
					width: drawerOpen ? 0 : 56, // The width of the mini-drawer
					transition: "width 0.3s ease",
					overflow: "hidden",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "#f5f5f5",
				}}
			>
				<IconButton
					onClick={toggleDrawer}
					edge="start"
					color="inherit"
					aria-label="open drawer"
				>
					<MenuIcon />
				</IconButton>
			</Box>

			{/* Drawer */}
			<Drawer
				variant="persistent"
				open={drawerOpen}
				sx={{
					width: drawerOpen ? 320 : 0,
					flexShrink: 0,
					transition: "width 0.3s ease",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between", // To push footer down
					"& .MuiDrawer-paper": {
						width: 320,
						boxSizing: "border-box",
						backgroundColor: "#f5f5f5",
						display: "flex",
						flexDirection: "column",
					},
				}}
			>
				<Box
					className="sidebar-container"
					role="presentation"
					sx={{ flexGrow: 1, padding: "20px", overflowY: "auto" }} // Make the content scrollable
				>
					{/* Drawer Header */}
					<Box className="sidebar-header">
						<div
							className="flex items-center justify-center"
							onClick={() => {
								handleItemClick(MenuItems.HelpDocumentation);
							}}
						>
							<Logo size={75} />
						</div>
						<SCLabel color="grey" fontSize={10}>
							Powered by Expertstack
						</SCLabel>
						<IconButton
							onClick={toggleDrawer}
							edge="start"
							color="inherit"
							aria-label="open drawer"
							className="menu-toggle-button"
						>
							<ChevronLeftIcon />
						</IconButton>
					</Box>

					{/* Navigation List */}
					<List component="nav" className="sidebar-nav-list">
						<SidebarItem
							name="registration"
							display="Registration"
							open={openSections.registration}
							OnSectionClick={handleSectionClick}
							OnItemClick={handleItemClick}
							childs={[
								{
									name: MenuItems.RegistrationBusiness,
									display: "Businesses",
								},
								{
									name: MenuItems.RegistrationNumbers,
									display: "Numbers",
								},
								{
									name: MenuItems.RegistrationMobileApps,
									display: "Mobile Apps",
								},
								{
									name: MenuItems.RegistrationContactCentres,
									display: "Contact Centres",
								},
								// {
								//   name: MenuItems.RegistrationTrialUsers,
								//   display: "Trial users",
								// },
							]}
							Icon={RegistrationIcon}
						/>
						<Divider />
						<SidebarItem
							name="branding"
							display="Branding"
							open={openSections.branding}
							OnSectionClick={handleSectionClick}
							OnItemClick={OnItemClick}
							childs={[
								// {
								//   name: MenuItems.BrandingConfigurations,
								//   display: "Brandings",
								// },
								{
									name: MenuItems.BrandingApplication,
									display: "Applications",
								},
								// { name: MenuItems.BrandingNumbers, display: "Numbers" },
							]}
							Icon={BrandingIcon}
						/>
						<Divider />
						<SidebarItem
							name="billing"
							display="Billing"
							disabled={true}
							open={openSections.billing}
							OnSectionClick={handleSectionClick}
							OnItemClick={OnItemClick}
							childs={[
								{
									name: "billingPayments",
									display: "Payments",
								},
								{ name: "billingPricing", display: "Pricing" },
							]}
							Icon={BillingIcon}
						/>
						<Divider />
						<SidebarItem
							name="help"
							display="Help"
							open={openSections.help}
							OnSectionClick={handleSectionClick}
							OnItemClick={OnItemClick}
							disabled={true}
							childs={[
								{
									name: "helpDocumentation",
									display: "Documentation",
								},
								{ name: "helpSupport", display: "Support" },
							]}
							Icon={HelpIcon}
						/>
						<Divider />
					</List>
				</Box>

				{/* Footer Section - remains outside the scroll */}
				<div className="bg-gradient-to-r from-sky-700 to-sky-950 p-4 text-center  text-white border-t-4 border-white relative">
					<Grid container alignItems="center" justifyContent="center">
						<Grid item>
							<span className="font-semibold text-md text-white uppercase">
								{user?.givenName} {user?.familyName}
							</span>
						</Grid>
						<Grid item>
							<IconButton
								onClick={toggleMenu}
								color="inherit"
								aria-label="toggle menu"
								sx={{ marginLeft: "8px" }}
							>
								{showMenu ? <CloseIcon /> : <MenuIcon />}
							</IconButton>
						</Grid>
					</Grid>

					{/* Dropdown Menu */}
					{showMenu && (
						<Box
							sx={{
								position: "absolute",
								bottom: "80px",
								width: "95%",
								backgroundColor: "#FFF",
								color: "#000",
								zIndex: 1, // Ensure menu is on top
							}}
						>
							<List>
								<ListItem button onClick={handleProfile}>
									<ListItemIcon>
										<AccountCircleIcon />
									</ListItemIcon>
									<ListItemText primary="Profile" />
								</ListItem>
								<Divider />
								{userRole &&
									userManagementRoles.includes(userRole) && (
										<>
											<ListItem
												button
												onClick={handleUserManagement}
											>
												<ListItemIcon>
													<SupervisorAccountIcon />
												</ListItemIcon>
												<ListItemText primary="User Management" />
											</ListItem>
											<Divider />
										</>
									)}
								<ListItem button onClick={handleLogout}>
									<ListItemIcon>
										<PowerSettingsNewIcon />
									</ListItemIcon>
									<ListItemText primary="Logout" />
								</ListItem>
							</List>
						</Box>
					)}
				</div>
			</Drawer>
		</Box>
	);
};

export default Sidebar;
