import { ApiService } from "src/network/apiService";

import {
	ApplicationModel,
	IBusinessNumberUpdateModel,
} from "src/types/models/apiModels";

export class EditApplicationViewModel {
	private apiService: ApiService;

	constructor(accessToken: string) {
		this.apiService = new ApiService({ accessToken: accessToken });
	}

	async validateApplication(
		application: ApplicationModel
	): Promise<string[]> {
		const errors: string[] = [];
		if (!application.brandingOption) {
			errors.push("brandingOption is required");
		}
		// if (!application.businessId) {
		// 	errors.push("businessId is required");
		// }
		// if (!application.brandingApplicationId) {
		// 	errors.push("brandingApplicationId required");
		// }
		return errors;
	}

	async updateNumbers(
		businessId: string,
		businessNumbers: IBusinessNumberUpdateModel[]
	): Promise<{ result: boolean; error?: string }> {
		console.log("####businessNumbers", businessNumbers);
		for (let index = 0; index < businessNumbers.length; index++) {
			const element = businessNumbers[index];
			const input = {
				brandingName: element.brandingName,
				defaultIntent: element.defaultIntent,
				defaultTTLInMinutes: element.defaultTTLInMinutes,
				setupPushContent: element.setupPushContent,
				cleanupPushContent: element.cleanupPushContent,
				imageFile: element.imageFile,
			};
			const number = element.businessNumber;
			console.log("####input", input);
			try {
				const response: any = await this.apiService.putAsync(
					`/onboard/business/${businessId}/number/${number}`,
					input
				);
				if (response) {
					if (
						response.scResponse &&
						response.scResponse.code === 2000
					) {
						return { result: true };
					} else {
						return {
							result: false,
							error: response.scResponse?.message,
						};
					}
				}
			} catch (error) {
				return {
					result: false,
					error: "There was an error performing the operation",
				};
			}
		}

		return {
			result: true,
		};
	}

	// async fetchBusinessList(): Promise<BusinessListModel[]> {
	// 	const response = await this.apiService.getAsync<any>(
	// 		"/onboard/business/list"
	// 	);

	// 	if (response) {
	// 		if (response.scResponse && response.scResponse.code === 2000) {
	// 			return response.scResponse.data;
	// 		}

	// 		return [];
	// 	}

	// 	return [];
	// }

	async fetchApplication(
		applicationId: string
	): Promise<ApplicationModel | undefined> {
		const response = await this.apiService.getAsync<any>(
			`/onboard/branding/application/${applicationId}`
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				console.log(`data from the application API ${JSON.stringify(response.scResponse.data)}`)
				return response.scResponse.data;
			}

			return undefined;
		}

		return undefined;
	}
	// async fetchMobileAppList(): Promise<MobileAppModel[]> {
	// 	const response = await this.apiService.getAsync<any>(
	// 		"/onboard/branding-app/list"
	// 	);

	// 	if (response) {
	// 		if (response.scResponse && response.scResponse.code === 2000) {
	// 			return response.scResponse.data;
	// 		}

	// 		return [];
	// 	}

	// 	return [];
	// }
}
