import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";

import { ApplicationProps } from "./application.props";
import "./application.css";
import ApplicationAddNew from "../application-add-new/application-add-new";
import {
	ApplicationModel,
	FormMode,
	NewApplicationModel,
} from "src/types/models/apiModels";
import ApplicationList from "../application-list/application-list";
import ApplicationEdit from "../application-edit/application-edit";

const Application: React.FC<ApplicationProps> = ({ openInEditMode }) => {
	const [editMode, setEditMode] = useState<FormMode>(FormMode.List);
	const [title, setTitle] = useState("");
	const [inAppCallingOptionSelected, setInAppCallingOptionSelected] =
		useState(false);
	const [selectedApplication, setSelectedApplication] = useState<
		ApplicationModel | undefined
	>(undefined);

	const handleAddApplication = useCallback(() => {
		console.log("addBusinessCallBack!");
		setEditMode(FormMode.New);
	}, []); // No dependencies, so this callback won't change across renders

	const handleSubmitApplication = useCallback(
		(application: NewApplicationModel) => {
			console.log("addBusinessCallBack!", application);
			setEditMode(FormMode.List);
		},
		[]
	);

	const handleOnAdd = useCallback(() => {
		// console.log("cancelBusinessCallBack!");
		setEditMode(FormMode.New);
	}, []);

	const handleCancelApplication = useCallback(() => {
		console.log("cancelBusinessCallBack!");
		setEditMode(FormMode.List);
	}, []);

	const handleAfterEdit = useCallback(() => {
		// console.log("cancelBusinessCallBack!");
		setEditMode(FormMode.Edit);
	}, []);

	useEffect(() => {
		if (editMode === FormMode.List) {
			setTitle("Branding application list");
		} else if (editMode === FormMode.Edit) {
			setTitle("Modify Branding application");
		} else {
			setTitle("Branding application");
		}
	}, [editMode]);

	// Functions to handle opening and closing the dialog

	return (
		<Banner label={title}>
			{editMode === FormMode.List ? (
				<ApplicationList
					onAddNewClick={handleOnAdd}
					onSelect={(application: ApplicationModel) => {
						setSelectedApplication(application);
						setEditMode(FormMode.Edit); // Switch to Edit mode when an item is selected
					}}
				/>
			) : editMode === FormMode.New ? (
				<ApplicationAddNew
					onSubmitClick={handleSubmitApplication}
					onCancelClick={handleCancelApplication}
				/>
			) : editMode === FormMode.Edit && selectedApplication ? (
				<ApplicationEdit
					onSubmitClick={handleAfterEdit}
					onCancelClick={() => setEditMode(FormMode.List)} // Go back to List mode on cancel
					editApplicationModel={selectedApplication}
				/>
			) : (
				<div>Please select an application to edit.</div> // Fallback for when selectedApplication is undefined
			)}
		</Banner>
	);
};

export default Application;
