import React from 'react';
import { Card, Typography, Box, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

export interface PhoneComponentProps {
  image: string;
  title: string;
  subtitle: string;
  borderRadius?: string; // Optional borderRadius prop
}

const PhoneComponent: React.FC<PhoneComponentProps> = ({
  image,
  title,
  subtitle,
  borderRadius = '48px', // Default value if not provided
}) => {
  return (
    <Card
      sx={{
        background: 'linear-gradient(to bottom, #353535, #515151)',
        borderRadius: borderRadius,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '300px',
        height: '550px',
        margin: 'auto',
        position: 'relative',
        overflow: 'hidden',
        padding: '20px',
        paddingTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute space
        alignItems: 'center',
        border: '10px solid black', // Added border
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <img
            src={image}
            alt="Company Logo"
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
            }}
          />
        </Grid>
        <Grid item style={{ marginLeft: '10px', maxWidth: '150px' }}> {/* Adding some space between the image and text */}
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ color: 'white', textAlign: 'left', marginBottom: '2px', wordWrap: 'break-word' }} // Allow text wrapping
          >
            {subtitle}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }} // Bold for title
          >
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          bottom: '40px',
          width: '80%',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start', // Align items to the start (left)
          background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1))',
          borderRadius: '25px',
          padding: '0 10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <PhoneIcon sx={{ color: 'green' }} />
        </Box>

        <Typography
          sx={{
            color: 'white',
            marginLeft: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            background: 'linear-gradient(to right, black, grey)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }}
        >
          slide to answer
        </Typography>
      </Box>
    </Card>
  );
};

export default PhoneComponent;