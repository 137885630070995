import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";

import { useAuth } from "../auth/auth-context";

import {
	BusinessListModel,
	BusinessNumberModel,
	ContactCentreModel,
	TrialUsersModel,
} from "src/types/models/apiModels";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import InputArray from "src/components/molecules/input-array/input-array";
import SectionHeader from "src/components/molecules/section-header/section-header";
import ErrorPopup from "../error-popup/error-popup";
import ValidatedInput from "src/components/molecules/validated-input/validated-input";
import Dropdown from "src/components/atoms/dropdown/dropdown";
import { BusinessTrialUsersAddNewProps } from "./business-trial-users-add-new.props";
import { NewBusinessTrialUsersViewModel } from "./business-trial-users-add-new.view-model";
import HelpText from "src/components/atoms/help-text/help-text";
import HelpIcon from "@mui/icons-material/Help";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const BusinessTrialUsersAddNew: React.FC<BusinessTrialUsersAddNewProps> = ({
	editTrialUser: editContactCentre,
	onSave,
	onCancel,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [showHelp, setShowHelp] = useState(true);
	const [loading, setLoading] = useState(false);
	const [businessList, setBusinessList] = useState<BusinessListModel[]>([]);
	const [businessId, setBusinessId] = useState<string | undefined>(undefined);
	const [businessNumbers, setBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);
	const [businessNumber, setBusinessNumber] = useState<string | undefined>(
		undefined
	);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [emailIds, setEmailIds] = useState<string[]>([]);
	const viewModel = useRef<NewBusinessTrialUsersViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new NewBusinessTrialUsersViewModel(
						user.accessToken
					);
					const businessList =
						await viewModel.current?.fetchBusinessList();

					if (businessList) {
						setBusinessList(() => businessList);
						if (businessList.length > 0) {
							setBusinessId(() => businessList[0].businessId);
						}
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};
		fetchData();
	}, [user]);

	useEffect(() => {
		const fetchData = async () => {
			if (businessId) {
				const businessNumberList =
					await viewModel.current?.fetchNumbersList(businessId);

				if (businessNumberList) {
					setBusinessNumbers(() => businessNumberList);
					if (businessNumberList.length > 0) {
						setBusinessNumber(
							() => businessNumberList[0].businessNumber
						);
					}
				}
			}
		};
		fetchData();
	}, [businessId]);

	// const handleFieldChange = (
	//   field: keyof typeof trialUser,
	//   value: string | string[]
	// ) => {
	//   setTrialUser((prevInfo) => ({
	//     ...prevInfo,
	//     [field]: value,
	//   }));
	// };
	function handleBusinessChange(value: string): void {
		setBusinessId(value);
	}
	function handleBusinessNumberChange(value: string): void {
		setBusinessNumber(value);
	}
	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};
	const toggleHelp = () => {
		setShowHelp((prev) => !prev);
	};
	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const handleOnChange = (inputStrings: string[]) => {
		setEmailIds(() => inputStrings);
	};

	const handleSubmit = async (): Promise<void> => {
		// if (onSave && trialUser) {
		//   onSave(trialUser);
		// }
		if (businessId && businessNumber) {
			const validate = await viewModel.current?.validateTrialUser(
				businessId,
				businessNumber,
				emailIds
			);
			if (validate && validate.length > 0) {
				setError(() => validate.join(","));
				toggleError(true);
			} else {
				const response = await viewModel.current?.saveTrialUser(
					businessId,
					businessNumber,
					emailIds
				);
				if (response?.result === true) {
					// if (onSave) {
					//   onSave(trialUser);
					// }
				} else {
					setError(() => response?.error ?? "");
					toggleError(true);
				}
			}
		}
	};

	return (
		<>
			{/* Help Text */}
			{showHelp === true && (
				<HelpText
					help={[
						<>
							You can configure trial users for your business
							here, with a limit of up to 3 users per business.
							Please select your business, choose the number of
							users, enter their email addresses, and submit
						</>,
						"If your business isn't listed, it may still be awaiting approval or the trial option has not been enabled",
					]}
					alignment="center"
					onCloseClick={toggleHelp}
				/>
			)}

			{showHelp === false && (
				<Box
					sx={{ position: "relative", marginBottom: 3, marginTop: 3 }}
				>
					<IconButton
						onClick={toggleHelp}
						sx={{ position: "absolute", top: 0, right: 0 }}
					>
						<HelpIcon />
					</IconButton>
				</Box>
			)}
			<SectionHeader title="Trial users"></SectionHeader>
			<Grid container spacing={2} className="form-section">
				<Grid item xs={12} sm={12}>
					<SCLabel color="#000" align="left">
						Business
					</SCLabel>
					<Tooltip
						title="If your business isn't listed, it may still be awaiting approval or the trial option has not been enabled"
						arrow
					>
						<Box
							sx={{
								borderRadius: 1,
								marginBottom: 1,
								padding: 1,
								display: "flex",
								alignItems: "center",
								marginTop: 1, // Adds space between the list and the selected item display
							}}
						>
							<Dropdown
								label="Country code"
								value={businessId ?? ""}
								onChange={(value) =>
									handleBusinessChange(value)
								}
								menuItems={businessList.map((bus) => ({
									value: bus.businessId,
									label: `${bus.name} (${bus.businessCode})`,
								}))}
							/>
						</Box>
					</Tooltip>
				</Grid>
				<Grid item xs={12} sm={12}>
					<SCLabel color="#000" align="left">
						Business number
					</SCLabel>
					<Box
						sx={{
							borderRadius: 1,
							marginBottom: 1,
							padding: 1,
							display: "flex",
							alignItems: "center",
							marginTop: 1, // Adds space between the list and the selected item display
						}}
					>
						<Dropdown
							label="Country code"
							value={businessNumber ?? ""}
							onChange={(value) =>
								handleBusinessNumberChange(value)
							}
							menuItems={businessNumbers.map((num) => ({
								value: num.businessNumber,
								label: `${num.businessNumber} (${num.status})`,
							}))}
						/>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12}>
					<SCLabel color="#000" align="left">
						Emails
					</SCLabel>
					<InputArray label="Emails" onChange={handleOnChange} />
				</Grid>
			</Grid>
			<ErrorPopup
				message={error}
				open={errorDialogOpen}
				onClose={closeError}
			></ErrorPopup>
			<PrimaryButton
				variant="outlined"
				color="info"
				label="SUBMIT"
				cssClassName="primary-button"
				disabled={false}
				onClick={handleSubmit}
			></PrimaryButton>
			<PrimaryButton
				variant="outlined"
				color="info"
				label="CANCEL"
				cssClassName="primary-button"
				disabled={false}
				onClick={handleCancel}
			></PrimaryButton>
		</>
	);
};

export default BusinessTrialUsersAddNew;
