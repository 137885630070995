import React, { useState } from "react";

interface IRadioOption {
	label: string;
	value: string;
	disabled?: boolean; // Optional prop to disable a specific radio button
}

interface IRadioGroupProps {
	options: IRadioOption[]; // Array of radio button options
	onChange: (selectedOption: IRadioOption) => void; // Callback to notify the parent with the selected option
	defaultSelected?: string; // Default selected value
}

const RadioGroup: React.FC<IRadioGroupProps> = ({
	options,
	onChange,
	defaultSelected,
}) => {
	const [selectedValue, setSelectedValue] = useState<string | undefined>(
		defaultSelected
	);

	const handleSelect = (option: IRadioOption) => {
		if (option.disabled) return; // Do nothing if the radio button is disabled
		setSelectedValue(option.value); // Update selected value
		onChange(option); // Notify the parent with the selected option
	};

	return (
		<div className="flex items-start justify-center gap-4">
			{options.map((option) => (
				<div
					key={option.value}
					className={`flex items-center gap-2 ${
						option.disabled
							? "cursor-not-allowed"
							: "cursor-pointer"
					}`}
					onClick={() => handleSelect(option)}
				>
					{/* Radio Button */}
					<div className="w-8 h-8 flex items-center justify-center">
						{selectedValue === option.value ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className={`size-8 font-semibold ${
									option.disabled
										? "text-green-300"
										: "text-green-600"
								}`}
							>
								<path
									fillRule="evenodd"
									d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
									clipRule="evenodd"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className={`size-8 font-semibold ${
									option.disabled
										? "text-gray-400"
										: "text-red-600"
								}`}
							>
								<path
									fillRule="evenodd"
									d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
									clipRule="evenodd"
								/>
							</svg>
						)}
					</div>

					{/* Label */}
					<span
						className={`font-semibold text-md ${
							option.disabled ? "text-gray-400" : "text-gray-800"
						}`}
					>
						{option.label}
					</span>
				</div>
			))}
		</div>
	);
};

export default RadioGroup;
