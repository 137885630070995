import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ContactCentreProps } from "./contact-centre.props";
import Banner from "src/components/molecules/banner/Banner";
import ContactCentreAddNew from "../contact-centre-add-new/contact-centre-add-new";
import ContactCentreList from "../contact-centre-list/contact-centre-list";
import { ContactCentreModel } from "src/types/models/apiModels";

const ContactCentre: React.FC<ContactCentreProps> = ({ openInEditMode }) => {
	const [title, setTitle] = useState("Contact Centre List");
	const [editMode, setEditMode] = useState<boolean>(openInEditMode ?? false);
	{
		console.log("Current Edit Mode: ", editMode);
	}

	const [contactCentre, setContactCentre] = useState<
		ContactCentreModel | undefined
	>(undefined);

	const handleSave = useCallback((contactCentre: ContactCentreModel) => {
		console.log("submitContactCentreCallBack!", contactCentre);
		toggleEditMode();
	}, []);

	const handleOnCancel = useCallback(() => {
		console.log("cancelBusinessCallBack!");
		toggleEditMode();
	}, []);

	const handleOnAdd = useCallback(() => {
		console.log("cancelBusinessCallBack!");
		toggleEditMode();
	}, []);

	const toggleEditMode = () => {
		console.log("In toggleEditMode");
		setEditMode((prevValue) => !prevValue);
	};

	const handleOnSelected = useCallback(
		(contactCentre: ContactCentreModel) => {
			console.log(
				`ContactCentreModel => ${JSON.stringify(contactCentre)}`
			);
			setContactCentre(contactCentre);
			toggleEditMode();
		},
		[]
	);

	useEffect(() => {
		if (editMode) {
			setTitle("Add / Edit Contact centre");
		} else {
			setTitle("Contact centre List");
			setContactCentre(undefined);
		}
	}, [editMode]);

	// Functions to handle opening and closing the dialog

	return (
		<Banner label={title}>
			{editMode === false ? (
				<ContactCentreList
					onSelect={(contactCentre) =>
						handleOnSelected(contactCentre)
					}
					onAddNewClick={handleOnAdd}
				></ContactCentreList>
			) : (
				<ContactCentreAddNew
					editContactCentre={contactCentre}
					onSave={handleSave}
					onCancel={handleOnCancel}
				></ContactCentreAddNew>
			)}
		</Banner>
	);
};

export default ContactCentre;
