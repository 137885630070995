import { updateUserInfo, changePassword } from "../auth";
export class UserProfileViewModel {
  async changePassword(
    username: string,
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      changePassword(username, oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }
}
