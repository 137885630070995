import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { ConfirmationPopupProps } from "./confirmation-popup.props";
import AccordionActions from "@mui/material/AccordionActions";

import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FieldInfo from "src/components/molecules/field-info/field-info";
import "./confirmation-popup.css";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  open,
  onClose,
  onConfirm,
  business,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <SCLabel color="#000" fontSize={24}>
          Confirm Your Business Information
        </SCLabel>
      </DialogTitle>
      <DialogContent>
        <Box>
          {/* Primary Contact */}
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <SCLabel color="#000" fontSize={24}>
                Primary Contact
              </SCLabel>
            </AccordionSummary>
            <AccordionDetails>
              <FieldInfo
                label="Name"
                value={`${business?.primaryContact?.firstName || ""} ${business?.primaryContact?.lastName || ""}`.trim()}
              />
              <FieldInfo
                label="Email"
                value={business?.primaryContact?.emailId}
              />
              <FieldInfo
                label="Phone"
                value={`${business?.primaryContact?.phoneNumber.countryCode || ""} ${business?.primaryContact?.phoneNumber.number || ""}`.trim()}
              />
            </AccordionDetails>
          </Accordion>
          {/* Business Information */}
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <SCLabel color="#000" fontSize={24}>
                Business Information
              </SCLabel>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <img
                  src={business?.info?.logo?.signedUrl}
                  alt="Uploaded"
                  className="image-upload-preview"
                  style={{
                    borderRadius: "12px",
                    height: "100px",
                    width: "100px",
                  }}
                />
              </Box>
              <FieldInfo label="Name" value={business?.info?.name} />
              <FieldInfo label="Website" value={business?.info?.website} />
              <FieldInfo
                label="DUNS Number"
                value={business?.info?.dunsNumber}
              />
              <FieldInfo label="Status" value={business?.info?.status} />
              <FieldInfo
                label="Country Code"
                value={business?.info?.countryCode}
              />
            </AccordionDetails>
          </Accordion>
          {/* Business details */}
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <SCLabel color="#000" fontSize={24}>
                Business Details
              </SCLabel>
            </AccordionSummary>
            <AccordionDetails>
              {business?.details?.fields.map((field: any, index: any) => (
                <FieldInfo key={index} label={field.name} value={field.value} />
              ))}
            </AccordionDetails>
          </Accordion>
          {/* Business numbers */}
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <SCLabel color="#000" fontSize={24}>
                Business Numbers
              </SCLabel>
            </AccordionSummary>
            <AccordionDetails>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <SCLabel color="#000" fontSize={12} align="left">
                        Number
                      </SCLabel>
                    </TableCell>
                    <TableCell align="right">
                      <SCLabel color="#000" fontSize={12} align="right">
                        Status
                      </SCLabel>
                    </TableCell>
                    <TableCell align="right">
                      <SCLabel color="#000" fontSize={12} align="right">
                        Documents
                      </SCLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {business?.numbers.map((number: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <SCLabel color="#000" fontSize={12} align="left">
                          {number.number}
                        </SCLabel>
                      </TableCell>
                      <TableCell>
                        <SCLabel color="#000" fontSize={12} align="center">
                          {number.status}
                        </SCLabel>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {number.ownershipDocuments.map(
                          (doc: any, docIndex: any) => (
                            <Box key={docIndex} sx={{ ml: 2 }}>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontWeight: "bold", marginLeft: "8px" }}
                              >
                                {doc.fileName}
                              </Typography>
                            </Box>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
