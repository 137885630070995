import { ApiService } from "src/network/apiService";
import { BusinessNumberModel, INewBusinessNumberModel, TBrandingOptions } from "src/types/models/apiModels";

export class ApplicationSubmitProcessorViewModel {
    private apiService: ApiService;

    constructor(accessToken: string) {
        this.apiService = new ApiService({ accessToken: accessToken });
    }

    async submitNumberAdditionAsync(
        applicationId: string,
        businessId: string,
        brandingOption: TBrandingOptions,
        numberToAdd: BusinessNumberModel
    ): Promise<{ result: boolean; error?: string }> {
        const url = "/onboard/branding/application/add-numbers";

        console.log(`submitNumberAdditionAsync ${url} ${JSON.stringify(numberToAdd)}`)


        const body: any = {
            applicationId: applicationId,
            businessId: businessId,
            businessNumbers: [
                {
                    brandingName: numberToAdd.brandingName,
                    businessNumber: numberToAdd.businessNumber,
                    defaultIntent: numberToAdd.defaultIntent,
                    defaultTTLInMinutes: 1,
                    imageFile: numberToAdd.imageFile,
                    setupPushContent: numberToAdd.setupPushContent,
                    cleanupPushContent: numberToAdd.cleanupPushContent,
                    scProvidedNumber: numberToAdd.scProvidedNumber,
                    managedNumberSettings:
                        brandingOption === "CPaaSManagedCallBranding"
                            ? {
                                numberProvider: "Vonage",
                                screenSettings: {
                                    appLogo: {
                                        size: {
                                            width: 200,
                                            height: 100,
                                        },
                                    },
                                    otherButton: {
                                        color: {
                                            background: "#484848",
                                            foreground: "#FEFEFE",
                                        },
                                    },
                                    endCallButton: {
                                        color: {
                                            background: "#D70013",
                                            foreground: "#FEFEFE",
                                        },
                                    },
                                    answerCallButton: {
                                        color: {
                                            background: "#248A3D",
                                            foreground: "#FEFEFE",
                                        },
                                    },
                                    background: {
                                        color: "#2B2B2B",
                                    },
                                },
                                sipEndpointUrl: "sip:12037796621@securedcalls.3cx.com.au:5060",
                                alwaysUseDefaultIntent: false,
                                outGoingOnMobileOption: "None",
                            }
                            : undefined, // Optional, only included for CPaaSManagedCallBranding
                },
            ],
        };

        // todo: make sure to specify the request and response types
        const response: any = this.apiService.postAsync(url, body);

        if (response) {
            if (response.scResponse && response.scResponse.code === 2000) {
                return { result: true };
            } else {
                return {
                    result: false,
                    error: response.scResponse?.message,
                };
            }
        }

        return { result: false, error: "response not returned" }
    }

    async submitNumberUpdateAsync(
        businessId: string,
        brandingOption: TBrandingOptions,
        numberToUpdate: BusinessNumberModel
    ): Promise<{ result: boolean; error?: string }> {
        const url = `/onboard/business/${businessId}/number/${numberToUpdate.businessNumber}`

        console.log(`submitNumberUpdateAsync ${url} ${JSON.stringify(numberToUpdate)}`)

        const body: INewBusinessNumberModel = {
            brandingName: numberToUpdate.brandingName,
            businessNumber: numberToUpdate.businessNumber,
            defaultIntent: numberToUpdate.defaultIntent,
            defaultTTLInMinutes: 1,
            imageFile: numberToUpdate.imageFile,
            setupPushContent: numberToUpdate.setupPushContent,
            cleanupPushContent: numberToUpdate.cleanupPushContent,
            scProvidedNumber: numberToUpdate.scProvidedNumber,
            managedNumberSettings:
                brandingOption ===
                    "CPaaSManagedCallBranding"
                    ? {
                        numberProvider: "Vonage",
                        screenSettings: {
                            appLogo: {
                                size: {
                                    width: 200,
                                    height: 100,
                                },
                            },
                            otherButton: {
                                color: {
                                    background:
                                        "#484848",
                                    foreground:
                                        "#FEFEFE",
                                },
                            },
                            endCallButton: {
                                color: {
                                    background:
                                        "#D70013",
                                    foreground:
                                        "#FEFEFE",
                                },
                            },
                            answerCallButton: {
                                color: {
                                    background:
                                        "#248A3D",
                                    foreground:
                                        "#FEFEFE",
                                },
                            },
                            background: {
                                color: "#2B2B2B",
                            },
                        },
                        sipEndpointUrl:
                            "sip:12037796621@securedcalls.3cx.com.au:5060",
                        alwaysUseDefaultIntent: false,
                        outGoingOnMobileOption:
                            "None",
                    }
                    : undefined, // This is optional, pass as-is
        };

        // todo: make sure to specify the request and response types
        const response: any = this.apiService.putAsync(url, body);

        if (response) {
            if (response.scResponse && response.scResponse.code === 2000) {
                return { result: true };
            } else {
                return {
                    result: false,
                    error: response.scResponse?.message,
                };
            }
        }

        return { result: false, error: "response not returned" }
    }
}