import { on } from "events";
import React from "react";
import { ServiceStatus } from "src/types/models/common";
import StatusBadge from "../status-badge/status-badge";

interface ICardProps {
	heading: string;
	code: string;
	name: string;
	status?: ServiceStatus;
	onEditClick?: () => void;
}

const Card: React.FC<ICardProps> = ({
	heading,
	code,
	name,
	status,
	onEditClick,
}) => {
	return (
		<>
			<div className="bg-gradient-to-r from-sky-700 to-sky-950 min-w-96 w-full flex flex-col items-center justify-center pt-4 text-white text-md gap-2 rounded-t-md drop-shadow-lg">
				<div className="flex items-center justify-center gap-2">
					<label className="font-bold text-lg uppercase tracking-widest">
						{heading}
					</label>
					{onEditClick && (
						<button onClick={onEditClick}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className={`size-6 ${true ? "text-gray-100" : "text-gray-300"}`}
							>
								<path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
								<path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
							</svg>
						</button>
					)}
				</div>
				<div className="bg-white flex flex-col items-center justify-center w-full p-4 text-gray-800 text-md gap-2">
					{code ? (
						<label className="font-Poppins font-semibold tracking-tight">
							{code}
						</label>
					) : (
						<label className="font-Poppins font-semibold tracking-tight">
							{"NOT PROVIDED"}
						</label>
					)}
					{status && <StatusBadge status={status}></StatusBadge>}
					{name ? (
						<label className="font-Poppins font-semibold uppercase tracking-tight">
							{name}
						</label>
					) : (
						<label className="font-Poppins font-semibold uppercase tracking-tight">
							{"NOT PROVIDED"}
						</label>
					)}
				</div>
			</div>
		</>
	);
};

export default Card;
