import HyperLink from "src/components/atoms/hyper-link/hyper-link";
import CopyRight from "src/components/molecules/copy-right/CopyRight";

const Footer = () => {
	return (
		<div className="flex flex-col md:flex-row items-center justify-center fixed inset-x-0 bottom-0 py-2 text-center bg-gradient-to-r from-sky-700 to-sky-950 space-x-12 text-white">
			<CopyRight />
			<div className="text-sm font-medium flex items-center justify-center gap-1">
				<HyperLink
					target="blank"
					link="https://securedcalls.com/terms"
					label="Terms of Service."
				></HyperLink>
				|
				<HyperLink
					target="blank"
					link="https://securedcalls.com/privacy-policy"
					label="Privacy Policy"
				></HyperLink>
			</div>
		</div>
	);
};

export default Footer;
