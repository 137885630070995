import { forgotPassword } from "../auth";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import React, { useEffect, useState } from "react";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import ButtonPro from "src/components/atoms/primary-button/primary-button";
import Logo from "src/components/molecules/logo/Logo";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import { validateEmail } from "src/helpers/email-address-helper";

const ForgotPassword: React.FC = () => {
	const location = useLocation();
	const username = location.state?.businessEmail;
	const [email, setEmail] = useState(username);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	// Get email from query parameters on component mount
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const emailParam = params.get("email");
		if (emailParam) {
			setEmail(emailParam); // Prefill the email if present
		}
	}, [location]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setError("");

		if (!email) {
			setError("Please enter your registered email address");
			return;
		}

		if (!validateEmail(email)) {
			setError("Please enter a valid email address");
			return;
		}

		setLoading(true);
		forgotPassword(email, (err, result) => {
			if (err) {
				setLoading(false);
				setError(err.message || JSON.stringify(err));
			} else {
				setLoading(false);
				navigate("/confirm-password", {
					state: { businessEmail: email },
				});
			}
		});
	};

	return (
		<>
			<div className="flex flex-col items-center justify-start md:justify-center h-screen w-screen py-8">
				<div className="bg-white text-gray-900 flex flex-col gap-6 items-center justify-start md:justify-center h-fit w-fit p-12 rounded-2xl md:shadow-[0_30px_50px_rgba(0,0,0,.5)]">
					<Logo></Logo>
					<span className="text-3xl md:text-4xl font-bold font-Poppins">
						Reset your password?
					</span>
					<label className="ms-2 text-md font-semibold">
						Don&apos;t have a secured calls account?{" "}
						<a
							href="/signup"
							className="text-blue-600 dark:text-blue-500 hover:underline"
						>
							Sign-up here.
						</a>
					</label>
					<form
						className="flex flex-col items-center justify-center gap-6 w-full"
						onSubmit={handleSubmit}
					>
						<InputBox
							value={email}
							label={"Registered email address"}
							type={"text"}
							onChange={function (value: string): void {
								setEmail(value);
							}}
							required
						></InputBox>
						<ButtonPro label={"SEND VERIFICATION CODE"}></ButtonPro>
						<span className="text-red-500 font-semibold">
							{error}
						</span>
					</form>
					<label className="ms-2 text-md font-semibold text-white md:text-gray-900">
						<a
							href="/login"
							className="text-blue-600 dark:text-blue-500 hover:underline flex items-center"
						>
							<ArrowLeft className="mr-2" />{" "}
							{/* Add margin to separate arrow from text */}
							Back to login
						</a>
					</label>
					<LoadingModal open={loading} message="Please wait..." />
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
