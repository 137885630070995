import { jwtDecode } from "jwt-decode";

export interface ITokenProperties {
  username: string;
  account_id: string;
  role?: string;
  email: string;
  name: string;
}

export interface ITokenHelper {
  decodeToken(bearerToken: string): ITokenProperties;
}

export class TokenHelper implements ITokenHelper {
  private readonly _decode: typeof jwtDecode;

  // Constructor that accepts the jwtDecode function
  constructor(decode: typeof jwtDecode = jwtDecode) {
    this._decode = decode;
  }

  decodeToken(bearerToken: string): ITokenProperties {
    if (!bearerToken) throw new Error("Bearer token cannot be empty");

    const token = bearerToken.replace("Bearer ", "");
    const decodedToken: ITokenProperties = this._decode(token); // Type-safe decoding

    return {
      username: decodedToken.username,
      email: decodedToken.email,
      name: decodedToken.name,
      account_id: decodedToken.account_id,
      role: decodedToken.role,
    };
  }
}
