import React from "react";
import ButtonWithIcon from "src/components/atoms/button-with-icon/button-with-icon";

interface SelectableListItemProps {
	selected: boolean;
	selectMode: boolean;
	children: React.ReactNode;
	item: any;
	onSelect?: (item: any) => void;
	enableView?: boolean;
	enableEdit?: boolean;
	enableDelete?: boolean;
}

const SelectableListItem: React.FC<SelectableListItemProps> = ({
	selected,
	item,
	onSelect,
	selectMode,
	children,
	enableView = true,
	enableEdit = true,
	enableDelete = true,
}) => {
	const onEditClicked = () => {
		if (onSelect) {
			onSelect(item);
		}
	};

	return (
		<>
			<div
				className="flex items-center justify-center w-full p-2 border-neutral-500 rounded-lg bg-gradient-to-r from-sky-100 to-sky-50 hover:bg-sky-200"
				onClick={selectMode ? onEditClicked : undefined}
			>
				{children}

				{!selectMode && (
					<div className="flex items-center justify-center">
						<ButtonWithIcon
							disabled={!enableView}
							onClick={() => {
								console.log(`not implemented`);
							}}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-6"
								>
									<path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
									<path
										fillRule="evenodd"
										d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
										clipRule="evenodd"
									/>
								</svg>
							}
						></ButtonWithIcon>
						<ButtonWithIcon
							disabled={!enableEdit}
							onClick={onEditClicked}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-6"
								>
									<path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
									<path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
								</svg>
							}
						></ButtonWithIcon>
						<ButtonWithIcon
							disabled={!enableDelete}
							onClick={() => {
								console.log(`not implemented`);
							}}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-6"
								>
									<path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
									<path
										fillRule="evenodd"
										d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087Zm6.133 2.845a.75.75 0 0 1 1.06 0l1.72 1.72 1.72-1.72a.75.75 0 1 1 1.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 1 1-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 1 1-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 0 1 0-1.06Z"
										clipRule="evenodd"
									/>
								</svg>
							}
						></ButtonWithIcon>
					</div>
				)}
			</div>
		</>
	);
};

export default SelectableListItem;
