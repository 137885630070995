import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import { ContactCentreListProps } from "./contact-centre-list.props";
import { ContactCentreListViewModel } from "./contact-centre-list.view-model";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import EntityList from "../entity-list/entity-list";
import { ContactCentreModel } from "src/types/models/apiModels";

const ContactCentreList: React.FC<ContactCentreListProps> = ({
	onAddNewClick,
	onSelect,
	selectMode,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<ContactCentreModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<ContactCentreListViewModel | null>(null);
	const [selectedContactCentre, setSelectedContactCentre] = useState<
		string | null
	>(null);

	const handleSelectContactCentre = (contactCentre: ContactCentreModel) => {
		setSelectedContactCentre(contactCentre.contactCentreId);
		if (onSelect) {
			onSelect(contactCentre);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new ContactCentreListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData", response);
					setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderContactCentreItem = (cc: ContactCentreModel) => (
		<>
			<div className="flex flex-col items-start justify-center gap-2 w-full">
				<div className="flex items-center justify-center gap-2">
					<span className="text-lg font-bold">{cc.name}</span>
					<span className="text-sm font-semibold">
						({cc.contactCentreCode})
					</span>
				</div>
				<span className="text-sm font-semibold">{cc.productName}</span>
			</div>
		</>
	);

	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your contact centres, please hold on!" />
		); // Pass the optional message
	}

	return (
		<div className="drop-shadow-2xl">
			<EntityList<ContactCentreModel>
				data={data}
				selectMode={selectMode} // or false depending on your requirements
				onSelect={(cc) => handleSelectContactCentre(cc)}
				renderChildren={renderContactCentreItem}
				onAddNewClick={onAddNewClick}
				listLabel={"Choose Your Contact Centre"}
			/>
		</div>
	);
};

export default ContactCentreList;
