import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../molecules/logo/Logo";
import { signUp } from "src/components/organisms/auth";
import { IPhoneNumber } from "src/types/models/apiModels";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import ButtonPro from "src/components/atoms/primary-button/primary-button";
import LeftSection from "./left-section";
import { validateEmail } from "src/helpers/email-address-helper";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import { Error } from "src/components/atoms/error/error";
import HyperLink from "src/components/atoms/hyper-link/hyper-link";
import Footer from "./footer";

const SignUp: React.FC = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [firstName, setFirstName] = useState<string | undefined>(undefined);
	const [familyName, setFamilyName] = useState<string | undefined>(undefined);
	const [businessEmail, setBusinessEmail] = useState<string>("");
	const [mobileNumber, setMobileNumber] = useState<string>("");
	const [password, setPassword] = useState<string | undefined>(undefined);
	const [confirmPassword, setConfirmPassword] = useState<string | undefined>(
		undefined
	);
	const [firstNameError, setFirstNameError] = useState<string | null>(null);
	const [familyNameError, setFamilyNameError] = useState<string | null>(null);
	const [businessEmailError, setBusinessEmailError] = useState<string | null>(
		null
	);
	const [mobileNumberError, setMobileNumberError] = useState<string | null>(
		null
	);
	const [passwordError, setPasswordError] = useState<string | null>(null);

	const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermsAgreed(event.target.checked);
	};

	const handleSignUp = (event: React.FormEvent) => {
		event.preventDefault();

		let hasError = false;
		setError("");

		if (!firstName) {
			setFirstNameError("First name is required");
			hasError = true;
		} else {
			setFirstNameError(null);
		}

		if (!familyName) {
			setFamilyNameError("Last name is required");
			hasError = true;
		} else {
			setFamilyNameError(null);
		}

		if (!businessEmail) {
			setError("Business email is required");
			hasError = true;
		} else {
			console.log(1111);
			setBusinessEmailError(null);
			if (!validateEmail(businessEmail)) {
				setError("Please enter valid email address");
				hasError = true;
				return;
			}
		}

		if (password && confirmPassword && password !== confirmPassword) {
			setError("Password and confirm password does not match");
			hasError = true;
			return;
		}

		if (!mobileNumber) {
			setMobileNumberError("Mobile number is required");
			hasError = true;
		} else {
			setMobileNumberError(null);
		}

		if (!password) {
			setPasswordError("Password is required");
			hasError = true;
		} else {
			setPasswordError(null);
		}

		if (!hasError) {
			setLoading(true);
			signUp(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				businessEmail!,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				password!,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				firstName!,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				familyName!,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				mobileNumber!,
				(err, result) => {
					setLoading(false);
					if (err) {
						setError(err.message || JSON.stringify(err));
					} else {
						setError("");
						navigate("/verify", {
							state: { businessEmail, password },
						});
					}
				}
			);
		}

		setLoading(false);
	};

	return (
		<>
			{/* main */}
			<div className="bg-slate-200 flex items-start justify-center md:items-center md:justify-center h-screen">
				<div className="flex">
					<LeftSection></LeftSection>
					{/* right section */}
					<div className="bg-transparent md:bg-white md:flex md:flex-col md:items-center md:justify-evenly w-screen max-w-[550px] px-12 pt-6 rounded-r-2xl md:shadow-[0_30px_50px_rgba(0,0,0,.5)]">
						<div className="flex flex-col items-center justify-center gap-4 md:gap-2">
							<div className="md:hidden py-4">
								<Logo></Logo>
							</div>
							<span className="bg-gradient-to-bl from-sky-600 to-sky-800 text-2xl md:text-6xl font-bold lg:text-4xl bg-clip-text text-transparent uppercase drop-shadow-2xl">
								CREATE ACCOUNT
							</span>
							<label className="ms-2 mb-12 text-sm font-semibold text-gray-900 uppercase">
								Have an account?{" "}
								<HyperLink
									link="/login"
									label="Sign-in here."
								></HyperLink>
							</label>
						</div>

						<form
							className="flex flex-col items-center justify-between gap-6 w-full"
							onSubmit={handleSignUp}
						>
							<InputBox
								value={firstName}
								onChange={(value) => {
									setFirstName(value);
								}}
								label="First Name"
								type={"text"}
								required
							></InputBox>

							<InputBox
								value={familyName}
								onChange={(value) => {
									setFamilyName(value);
								}}
								label="Last Name"
								type={"text"}
								required
							></InputBox>

							<InputBox
								value={mobileNumber}
								onChange={(value) => {
									setMobileNumber(value);
								}}
								label="Phone Number"
								type={"text"}
								required
							></InputBox>

							<InputBox
								value={businessEmail}
								onChange={(value) => {
									setBusinessEmail(value);
								}}
								label="Business email"
								type={"email"}
								required
							></InputBox>

							<InputBox
								value={password}
								onChange={(value) => {
									setPassword(value);
								}}
								label="Password"
								type={"password"}
								required
							></InputBox>

							<InputBox
								value={confirmPassword}
								onChange={(value) => {
									setConfirmPassword(value);
								}}
								label="Confirm Password"
								type={"password"}
								required
							></InputBox>
							<div className="flex items-center text-left w-full">
								<input
									id="link-checkbox"
									type="checkbox"
									checked={termsAgreed}
									onChange={(e) =>
										setTermsAgreed(e.target.checked)
									}
									className="w-4 h-4 text-blue-600 bg-gray-800 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
								></input>
								<label
									htmlFor="link-checkbox"
									className="ms-2 text-sm font-semibold text-gray-900 "
								>
									I agree with the{" "}
									<HyperLink
										target="blank"
										link="https://securedcalls.com/terms"
										label="terms and conditions."
									></HyperLink>
								</label>
							</div>
							<Error error={error}></Error>
							<div className="pt-12 md:pt-2 pb-4 w-full">
								<ButtonPro
									label={"Submit"}
									onClick={() => handleSignUp}
									disabled={
										!termsAgreed ||
										!firstName ||
										!familyName ||
										!mobileNumber ||
										!validateEmail(businessEmail) ||
										!password ||
										!confirmPassword
									}
								></ButtonPro>
								<LoadingModal
									open={loading}
									message="Please wait..."
								/>
							</div>
						</form>
					</div>
				</div>
				<Footer></Footer>
			</div>
		</>
	);
};

export default SignUp;
