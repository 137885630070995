export const is_aString = (value: any): boolean => {
  return typeof value === "string";
};

export const is_aNumber = (value: any): boolean => {
  return !isNaN(Number(value));
};

export const is_aPhoneNumber = (value: any): boolean => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;
  return !phoneRegex.test(value);
};

export const is_aEmail = (value: any): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return false;
  }
  return true;
};

export const is_aUrl = (value: any): boolean => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!urlPattern.test(value);
};
