import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import { Dialog, DialogContent } from "@mui/material";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import { ApplicationAddNewProps } from "./application-add-new.props";
import { NewApplicationViewModel } from "./application-add-new-view-model";
import BusinessList from "../business-list/business-list";
import ContactCentreList from "../contact-centre-list/contact-centre-list";
import MobileAppList from "../mobile-app-list/mobile-app-list";

import {
	NewApplicationModel,
	BusinessListModel,
	BusinessNumberModel,
	ContactCentreModel,
	MobileAppModel,
	BusinessModel,
} from "src/types/models/apiModels";
import { TBrandingOptions } from "src/types/models/common";
import Card from "src/components/molecules/card/card";
import CheckBox from "src/components/molecules/check-box/check-box";
import { BusinessNumbersConfigureViewModel } from "../business-numbers-configure/business-numbers-configure.view-model";
import BrandingConfiguration from "../branding-configuration/branding-configuration";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import BusinessNumbersList from "../business-numbers-list/business-numbers-list";
import ActionPanel from "../action-panel/action-panel";
import StatusBadge from "src/components/molecules/status-badge/status-badge";

const ApplicationAddNew: React.FC<ApplicationAddNewProps> = ({
	onSubmitClick,
	onCancelClick,
}) => {
	const defaultCC = {
		contactCentreId: "NA",
		contactCentreCode: "NA",
		productName: "NA",
		name: "Not Applicable",
		whitelistedUrls: [],
	};
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const PLEASESELECT = "PLEASE SELECT";
	const NA = "NA";
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(false);
	const [hasMoreThanOneApp, setHasMoreThanOneApp] = useState(true);
	const [business, setBusiness] = useState<BusinessModel | undefined>(
		undefined
	);
	const [businessListItem, setBusinessListItem] = useState<
		BusinessListModel | undefined
	>(undefined);
	const [contactCentre, setContactCentre] = useState<
		ContactCentreModel | undefined
	>(undefined);
	const [contactCentreId, setContactCentreId] = useState<string>("NA");
	const [brandingOption, setBrandingOption] = useState<TBrandingOptions>(
		"UnmanagedCallBranding"
	);
	const [
		showModifiedBusinessNumbersOnly,
		setShowModifiedBusinessNumbersOnly,
	] = useState(false);
	const [contactCentreList, setContactCentreList] = useState<
		ContactCentreModel[]
	>([defaultCC]);
	const [approvedBusinessNumbers, setApprovedBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);
	// const [selectedBusinessNumbers, setSelectedBusinessNumbers] = useState<
	// 	BusinessNumberModel[]
	// >([]);
	const [mobileApp, setMobileApp] = useState<MobileAppModel | undefined>(
		undefined
	);
	const [selectBusinessDialogOpen, setSelectBusinessDialogOpen] =
		useState<boolean>(false);
	const [selectCCDialogOpen, setSelectCCDialogOpen] =
		useState<boolean>(false);
	const [selectAppDialogOpen, setSelectAppDialogOpen] =
		useState<boolean>(false);
	const [brandingDialogOpen, setBrandingDialogOpen] =
		useState<boolean>(false);
	const [numberDialogOpen, setNumberDialogOpen] = useState<boolean>(false);
	// const [businessList, setBusinessList] = useState<BusinessListModel[]>([]);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [scProvidedNumbers, setSCProvidedNumbers] = useState(false);
	const [showApplicationCredentials, setShowApplicationCredentials] =
		useState(false);
	const [showHelp, setShowHelp] = useState(true);
	const [error, setError] = useState<string>("");
	const [businessNumberFilteredValue, setBusinessNumberFilteredValue] =
		useState<string>("");
	const viewModel = useRef<NewApplicationViewModel | null>(null);
	const businessNumbersConfigureViewModel =
		useRef<BusinessNumbersConfigureViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new NewApplicationViewModel(
						user.accessToken
					);
					const businessList =
						await viewModel.current?.fetchBusinessList();

					if (businessList && businessList.length === 1) {
						setBusinessListItem(() => businessList[0]);
					}

					const appList =
						await viewModel.current?.fetchMobileAppList();

					if (appList && appList.length === 1) {
						setHasMoreThanOneApp(false);
						setMobileApp(() => appList[0]);
					}

					const ccList =
						await viewModel.current?.fetchContactCentreList();

					if (ccList) {
						ccList.push(defaultCC);
						setContactCentreList(() => ccList);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};
		fetchData();
	}, [user]);

	useEffect(() => {
		const fetchBusinessNumbers = async () => {
			if (business) {
				try {
					if (user && user.accessToken) {
						businessNumbersConfigureViewModel.current =
							new BusinessNumbersConfigureViewModel(
								user.accessToken
							);

						const response =
							await businessNumbersConfigureViewModel.current?.fetchList(
								business.businessId
							);

						console.log(
							`number response -> ${JSON.stringify(response)}`
						);

						setApprovedBusinessNumbers(() =>
							response.filter(
								(number) => number.status === "Approved"
							)
						);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
				} finally {
					setLoading((prevValue) => {
						if (prevValue) {
							return false;
						}
						return prevValue;
					});
				}
			}
		};
		fetchBusinessNumbers();
	}, [business]);

	useEffect(() => {
		const bus = businessListItem
			? {
					businessId: businessListItem.businessId,
					businessCode: businessListItem.businessCode,
					name: businessListItem.name,
					logo: businessListItem.logo,
				}
			: undefined;
		setBusiness(() => bus);
	}, [businessListItem]);

	useEffect(() => {
		const fetchData = async () => {
			const cc = contactCentreList.find(
				(p) => (p.contactCentreId = contactCentreId)
			);
			setContactCentre(() => cc);
		};
		fetchData();
	}, [contactCentreId]);

	useEffect(() => {
		console.log(
			"Approved Business Numbers Updated:",
			approvedBusinessNumbers
		);
	}, [approvedBusinessNumbers]);

	const toggleAccordion = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const handleSubmitApplication = async () => {
		setLoading(true);
		try {
			if (user && user.accessToken) {
				viewModel.current = new NewApplicationViewModel(
					user.accessToken
				);

				if (mobileApp && business && brandingOption) {
					const request: NewApplicationModel = {
						countryCode: "AU",
						brandingApplicationId: mobileApp?.id,
						contactCentreId:
							contactCentre?.contactCentreId === "NA" ||
							contactCentre?.contactCentreId === undefined
								? ""
								: contactCentre?.contactCentreId,
						businessId: business?.businessId,
						businessUnitId: "",
						businessCode: business.businessCode,
						brandingOption: brandingOption,
						name: "",
						businessNumbers: approvedBusinessNumbers
							.filter((p) => p.selected) // only send the selected numbers in the application request.
							.map((item) => ({
								businessNumber: item.businessNumber,
								brandingName: item.brandingName, // Provide default empty string if undefined
								defaultIntent: item.defaultIntent, // Provide default empty string if undefined
								defaultTTLInMinutes:
									item.defaultTTLInMinutes || 10, // Provide default value 0 if undefined
								imageFile: item.imageFile,
								setupPushContent: item.setupPushContent,
								cleanupPushContent: item.cleanupPushContent,
								managedNumberSettings:
									brandingOption ===
									"CPaaSManagedCallBranding"
										? {
												numberProvider: "Vonage",
												screenSettings: {
													appLogo: {
														size: {
															width: 200,
															height: 100,
														},
													},
													otherButton: {
														color: {
															background:
																"#484848",
															foreground:
																"#FEFEFE",
														},
													},
													endCallButton: {
														color: {
															background:
																"#D70013",
															foreground:
																"#FEFEFE",
														},
													},
													answerCallButton: {
														color: {
															background:
																"#248A3D",
															foreground:
																"#FEFEFE",
														},
													},
													background: {
														color: "#2B2B2B",
													},
												},
												sipEndpointUrl:
													"sip:12037796621@securedcalls.3cx.com.au:5060",
												alwaysUseDefaultIntent: false,
												outGoingOnMobileOption:
													"Fallback",
											}
										: undefined, // This is optional, pass as-is
								scProvidedNumber: item.scProvidedNumber, // Pass as-is
							})),
						scProvidedNumbers: scProvidedNumbers,
					};
					const saveResult =
						await viewModel.current?.submitApplication(request);
					console.log("response", saveResult);
					if (saveResult?.result === true) {
						setLoading(false);
						onSubmitClick(request);
						setShowApplicationCredentials(true);
					} else {
						setLoading(false);
						setError(() => saveResult?.error ?? "");
						toggleError(true);
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading((prevValue) => {
				if (prevValue) {
					return false;
				}
				return prevValue;
			});
		}
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const toggleHelp = () => {
		setShowHelp((prev) => !prev);
	};

	const handleCancel = () => {
		console.log("In handleCancel");
		if (onCancelClick) {
			onCancelClick();
		}
	};

	const toggleBusinessDialog = () => {
		console.log("In toggleBusinessDialog");
		setSelectBusinessDialogOpen((prevValue) => !prevValue);
	};

	const toggleCCDialog = () => {
		setSelectCCDialogOpen((prevValue) => !prevValue);
	};

	const toggleAppDialog = () => {
		setSelectAppDialogOpen((prevValue) => !prevValue);
	};

	const toggleBrandingDialog = () => {
		setBrandingDialogOpen((prevValue) => !prevValue);
	};

	const toggleNumberDialog = () => {
		setNumberDialogOpen((prevValue) => !prevValue);
	};

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		);
	}

	function handleOnAddBusiness(): void {
		throw new Error("Function not implemented.");
	}

	function handleOnSelectBusiness(business: BusinessListModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setBusinessListItem(business);
		toggleBusinessDialog();
	}

	function showBusinessList(): void {
		toggleBusinessDialog();
	}

	function handleOnSelectCC(contactCentre: ContactCentreModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setContactCentre(contactCentre);
		toggleCCDialog();
	}

	function handleOnSelectNumber(number: BusinessNumberModel): void {
		console.log("handleOnSelectNumber", number);
		toggleNumberDialog();
	}

	function showCCList(): void {
		toggleCCDialog();
	}

	function handleOnSelectApp(mobileApp: MobileAppModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setMobileApp(mobileApp);
		toggleAppDialog();
	}

	function showAppList(): void {
		toggleAppDialog();
	}

	return (
		<>
			<div className="flex flex-col items-start justify-start gap-6 w-full">
				<div className="flex gap-2 w-full">
					<div className="flex flex-col items-start justify-start gap-2 w-full">
						<Card
							heading={"Business"}
							code={
								business ? business.businessCode : PLEASESELECT
							}
							name={business ? business.name : PLEASESELECT}
							status={business?.status}
							onEditClick={showBusinessList}
						/>
					</div>
					<div className="flex flex-col items-start justify-start gap-2 w-full">
						<Card
							heading={"Mobile App"}
							code={mobileApp ? mobileApp.code : PLEASESELECT}
							name={mobileApp ? mobileApp.name : PLEASESELECT}
							onEditClick={showAppList}
						/>
					</div>
					<div className="flex flex-col items-start justify-start gap-2 w-full">
						<Card
							heading={"Contact Center"}
							code={
								contactCentre?.contactCentreCode === NA
									? PLEASESELECT
									: contactCentre?.contactCentreCode ||
										PLEASESELECT // Provide a default fallback
							}
							name={
								contactCentre?.contactCentreCode === NA
									? PLEASESELECT
									: contactCentre?.name || PLEASESELECT // Provide a default fallback
							}
							onEditClick={showCCList}
						/>
					</div>
				</div>
				<div className="flex flex-col items-start justify-center gap-2 w-full">
					<div className="flex items-start justify-start gap-2 w-full">
						<span className="font-semibold text-lg uppercase">
							Branding Options
						</span>
						<span className="font-semibold text-xs p-2 text-gray-900 rounded-lg">
							(Secured calls SDKs have PSTN branding by default.)
						</span>
					</div>
					<div className="flex items-start justify-start gap-4 w-full">
						<CheckBox
							title={"PSTN"}
							onChange={function (value: boolean): void {
								console.log(value);
							}}
							checked={true}
							disabled
						></CheckBox>
						<CheckBox
							title={"In-App"}
							onChange={(value: boolean): void => {
								console.log(`on changed ${value}`);
								if (value) {
									setBrandingOption(
										"CPaaSManagedCallBranding"
									);
								} else {
									setBrandingOption("UnmanagedCallBranding");
								}
							}}
							checked={
								brandingOption === "CPaaSManagedCallBranding"
							}
						></CheckBox>
					</div>
				</div>
				{business &&
					brandingOption === "CPaaSManagedCallBranding" &&
					approvedBusinessNumbers.length <= 0 && (
						<span className="bg-red-600 font-semibold p-2 text-gray-100 rounded-lg">
							You currently do not have approved numbers for this
							business. If you require number provisioning, please
							proceed to submit this form. Our backend team will
							assist with provisioning the numbers. Once the
							numbers are provisioned, you can return here to
							apply branding to them.
						</span>
					)}

				<div className="flex flex-col items-start justify-center gap-4 w-full">
					<span className="font-semibold text-lg uppercase">
						Branding Numbers ({approvedBusinessNumbers.length})
					</span>
					<div className="flex items-center justify-start w-full">
						<ButtonAddNew
							text="Select Numbers"
							onClick={toggleNumberDialog}
							disabled={approvedBusinessNumbers.length === 0}
						></ButtonAddNew>
					</div>
					
					{approvedBusinessNumbers.filter((p) => p.selected).length >
						0 && (
						<>
							<InputBox
								value={businessNumberFilteredValue}
								label={"Filter"}
								type={"text"}
								onChange={function (value: string): void {
									setBusinessNumberFilteredValue(value);
								}}
							></InputBox>

							{/* todo: consider to move this to its own component later. */}
							{approvedBusinessNumbers.map((p) => p.selected)
								.length > 0 && (
								<>
									{/* <div className="flex items-center justify-start w-full">
										<CheckBox
											title="Show Modified only"
											onChange={(
												value: boolean
											): void => {
												setShowModifiedBusinessNumbersOnly(
													value
												);
											}}
											checked={
												showModifiedBusinessNumbersOnly
											}
										></CheckBox>
									</div> */}
									<div id="accordion" className="w-full">
										<div className="flex items-center justify-start w-full p-2">
											<span className="font-semibold text-sm uppercase">
												Click on individual numbers to
												configure branding.
											</span>
										</div>
										{approvedBusinessNumbers
											.filter((p) => p.selected === true)
											.map((item, index) => (
												<div
													key={index}
													className="pb-2"
												>
													<h2
														className={`${item.hasUnsavedChanges ? "bg-gradient-to-r from-sky-400 to-sky-200 hover:bg-sky-200" : ""} flex items-center justify-between w-full py-3 px-4 pe-6 font-medium text-gray-800 bg-slate-200 rounded-xl border-gray-200 hover:bg-gray-100`}
													>
														{/* Accordion toggle area */}
														<button
															type="button"
															className="flex items-center justify-start gap-2 w-full"
															onClick={() =>
																toggleAccordion(
																	index
																)
															}
															aria-expanded={
																activeIndex ===
																index
															}
															aria-controls={`accordion-body-${index}`}
														>
															<img
																className="w-12 h-12 rounded-full flex-shrink-0"
																src={
																	item
																		.imageFile
																		?.signedUrl
																}
																alt=""
															/>

															<span
																className={`${item.hasUnsavedChanges ? "text-white" : "text-gray-900"} font-semibold text-lg`}
															>
																+
																{
																	item.businessNumber
																}
															</span>
														</button>

														{/* Action buttons */}
														<div className="flex items-center justify-end gap-2 w-fit">
															{/* Remove button */}
															{/* {item.hasUnsavedChanges && (
																<div className="rounded-2xl bg-blue-500 w-full">
																	<button
																		className={`py-1 px-4 rounded-full text-sm font-semibold text-white text-nowrap`}
																	>
																		{
																			"Discard changes"
																		}
																	</button>
																</div>
															)} */}
															<StatusBadge
																status={
																	item.status
																}
															></StatusBadge>
														</div>
													</h2>

													{/* Accordion content */}
													<div
														id={`accordion-body-${index}`}
														className={`overflow-hidden transition-all duration-300 ${
															activeIndex ===
															index
																? "max-h-screen"
																: "max-h-0"
														}`}
													>
														<div className="pt-2 text-gray-500">
															{/* {item.content} */}
															<>
																{business && (
																	<BrandingConfiguration
																		business={
																			business
																		}
																		number={
																			item
																		}
																		editNumber={
																			undefined
																		}
																		scProvidedNumber={
																			true
																		}
																		onChange={(
																			value
																		) => {
																			console.log(
																				`changed number -> ${JSON.stringify(value)}`
																			);
																			setApprovedBusinessNumbers(
																				(
																					prevNumbers
																				) =>
																					prevNumbers.map(
																						(
																							num
																						) =>
																							num.businessNumber ===
																							value.businessNumber
																								? {
																										...num,
																										...value,
																									}
																								: num
																					)
																			);
																		}}
																	/>
																)}
															</>
														</div>
													</div>
												</div>
											))}
									</div>
								</>
							)}

							{/* <BusinessNumberAccordion
								items={approvedBusinessNumbers
									.filter((p) => p.selected)
									.filter(
										(number) =>
											number.businessNumber
												.toLowerCase()
												.includes(
													businessNumberFilteredValue.toLowerCase()
												) // Filter based on the input value
									)
									.map((number) => ({
										key: number.businessNumber, // Ensure unique key
										image: number.imageFile
											? number.imageFile.signedUrl
											: business?.logo.signedUrl,
										status: number.status,
										title: number.businessNumber, // Use business number as the title
										content: (
											<>
												{business && (
													<BrandingConfiguration
														business={business}
														number={number}
														editNumber={undefined}
														scProvidedNumber={true}
														onChange={(value) => {
															console.log(
																"Updating approvedBusinessNumbers with:",
																value
															);
															setApprovedBusinessNumbers(
																(prevNumbers) =>
																	prevNumbers.map(
																		(
																			num
																		) =>
																			num.businessNumber ===
																			value.businessNumber
																				? {
																						...num,
																						...value,
																					}
																				: num
																	)
															);
															console.log(
																"Updated approvedBusinessNumbers:",
																approvedBusinessNumbers
															);
														}}
													/>
												)}
											</>
										),
									}))}
							/> */}
						</>
					)}
				</div>
				<ActionPanel
					primaryButtonLabel={"Submit"}
					primaryButtonEnabled={
						business != undefined && mobileApp != undefined
					}
					secondaryButtonLabel={"Cancel"}
					secondaryButtonEnabled={true}
					primaryButtonAction={handleSubmitApplication}
					secondaryButtonAction={handleCancel}
				></ActionPanel>
			</div>

			{/* business number selection */}
			<Dialog
				open={numberDialogOpen}
				onClose={toggleNumberDialog}
				maxWidth="md"
				fullWidth
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: "100%",
						alignContent: "centre",
						justifyContent: "center",
					},
				}}
			>
				<DialogContent>
					<BusinessNumbersList
						businessNumbers={approvedBusinessNumbers}
						onAddNewClick={function (): void {
							console.log(`not required.`);
						}}
						onSelect={function (
							selectedNumbers: BusinessNumberModel[]
						): void {
							console.log(
								`count - numbers ${selectedNumbers.length}`
							);
							if (selectedNumbers.length > 0) {
								console.log(
									`count - selected  ${selectedNumbers.filter((p) => p.selected).length}`
								);
								// Update the approvedBusinessNumbers list
								setApprovedBusinessNumbers((prevNumbers) =>
									prevNumbers.map(
										(item) =>
											selectedNumbers.some(
												(selected) =>
													selected.businessNumber ===
													item.businessNumber
											)
												? { ...item, selected: true } // Mark as selected
												: { ...item, selected: false } // Unmark as selected
									)
								);
								// setSelectedBusinessNumbers(() =>
								// 	selectedNumbers.filter((p) => p.selected)
								// ); // Update the selected business numbers
							}
						}}
						onClose={toggleNumberDialog}
						selectMode={true}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				open={selectBusinessDialogOpen}
				onClose={toggleBusinessDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectBusiness}
						selectMode={true}
						withStatuses={["Approved", "Active"]}
					></BusinessList>
				</DialogContent>
			</Dialog>

			<Dialog
				open={selectAppDialogOpen}
				onClose={toggleAppDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<MobileAppList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectApp}
						selectMode={true}
					></MobileAppList>
				</DialogContent>
			</Dialog>

			<Dialog
				open={selectCCDialogOpen}
				onClose={toggleCCDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<ContactCentreList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectCC}
						selectMode={true}
					></ContactCentreList>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ApplicationAddNew;
