import React from "react";
import { Grid } from "@mui/material";
import { FieldInfoProps } from "./field-info.props";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const FieldInfo: React.FC<FieldInfoProps> = ({ label, value }) => {
  return (
    <Grid container alignItems="center" sx={{ marginBottom: 1 }}>
      <Grid item xs={3}>
        <SCLabel color="#000" fontSize={16} align="left">
          {label}
        </SCLabel>
      </Grid>
      <Grid item xs={9}>
        <SCLabel color="#000" fontSize={14} align="left" textTransform="none">
          {value}
        </SCLabel>
      </Grid>
    </Grid>
  );
};

export default FieldInfo;
