import React from "react";
import { Typography } from "@mui/material";
import { SCLabelProps } from "./sc-lable.props";

const SCLabel: React.FC<SCLabelProps> = ({
  children,
  textTransform = "uppercase", // Default text transform
  fontWeight = "bold", // Default font weight
  align = "center", // Default alignment
  color = "#ffffff", // Default color
  ...props
}) => {
  return (
    <Typography
      variant="subtitle1"
      align={align}
      // paragraph={true}
      sx={{ textTransform, fontWeight, color }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default SCLabel;
