import React from "react";
import { Card, Typography, Box, Grid, Button, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CallEndIcon from "@mui/icons-material/CallEnd";
export interface AndroidPhoneComponentProps {
  image: string;
  title: string;
  subtitle: string;
  borderRadius?: string; // Optional borderRadius prop
}

const AndroidPhoneComponent: React.FC<AndroidPhoneComponentProps> = ({
  image,
  title,
  subtitle,
  borderRadius = "48px", // Default value if not provided
}) => {
  return (
    <Card
      sx={{
        background: "linear-gradient(to bottom, #353535, #515151)",
        borderRadius: borderRadius,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        width: "300px",
        height: "550px",
        margin: "auto",
        position: "relative",
        overflow: "hidden",
        padding: "20px",
        paddingTop: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Distribute space
        alignItems: "center",
        border: "10px solid black", // Added border
      }}
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            variant="h6"
            component="div"
            sx={{ color: "white", textAlign: "center", fontWeight: "normal" }}
          >
            Incoming call
          </Typography>
          <br></br>
          <br></br>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ color: "white", textAlign: "center", marginBottom: "8px" }}
          >
            {subtitle}
          </Typography>
        </Grid>
        <br></br>
        <Grid item>
          <img
            src={image}
            alt="Company Logo"
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between", // Space out buttons
          alignItems: "center",
          padding: "0 20px", // Add some padding to the sides
          position: "absolute",
          bottom: "40px",
        }}
      >
        <IconButton
          sx={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <PhoneIcon sx={{ color: "green" }} />
        </IconButton>

        <IconButton
          sx={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <CallEndIcon sx={{ color: "red" }} />
        </IconButton>
      </Box>
    </Card>
  );
};

export default AndroidPhoneComponent;
