import React, { useState } from "react";
import { TextField, Grid } from "@mui/material";
import { PhoneNumberInputProps } from "./phone-number-input.props";
import { IPhoneNumber } from "src/types/models/apiModels";

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>(value);

  const handleCountryCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCountryCode = event.target.value;
    const updatedPhoneNumber = { ...phoneNumber, countryCode: newCountryCode };
    setPhoneNumber(updatedPhoneNumber);
    if (onChange) {
      onChange(updatedPhoneNumber);
    }
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = event.target.value;
    const updatedPhoneNumber = { ...phoneNumber, number: newNumber };
    setPhoneNumber(updatedPhoneNumber);
    if (onChange) {
      onChange(updatedPhoneNumber);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          className="input-field"
          variant="filled"
          label="Country Code"
          value={phoneNumber.countryCode}
          onChange={handleCountryCodeChange}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          className="input-field"
          variant="filled"
          label="Phone Number"
          value={phoneNumber.number}
          onChange={handleNumberChange}
          fullWidth
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneNumberInput;
