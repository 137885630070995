import React from "react";

interface IAndroidPhoneProps {
	brandingImage: string;
	businessName: string;
	intent: string;
}

const AndroidPhone: React.FC<IAndroidPhoneProps> = ({
	brandingImage,
	businessName,
	intent,
}) => {
	return (
		<>
			<div className="flex flex-col items-center justify-center gap-4 drop-shadow-2xl">
				<div className="relative mx-auto border-gray-950 dark:border-gray-950 bg-gray-800 border-[14px] rounded-xl h-[600px] w-[300px] shadow-xl">
					<div className="w-[148px] h-[18px] bg-black top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
					<div className="h-[32px] w-[3px] bg-gray-950 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
					<div className="h-[46px] w-[3px] bg-gray-950 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
					<div className="h-[46px] w-[3px] bg-gray-950 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
					<div className="h-[64px] w-[3px] bg-gray-950 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
					<div className="rounded-xl overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
						<div className="bg-gradient-to-bl from-gray-700 to-gray-900 h-full w-full flex flex-col items-center justify-evenly pb-8 pt-16 px-4">
							<label className="font-Poppins text-white text-xl">
								Incoming call
							</label>
							{/* Business Name and Intent */}
							<div className="flex flex-col items-center justify-center gap-2 mt-12">
								<label className="font-Poppins text-white text-xl font-bold text-center max-w-full">
									{businessName}
								</label>

								<label className="font-Poppins font-normal text-white text-center">
									{intent}
								</label>
							</div>
							{/* Main Logo */}
							<img
								src={brandingImage}
								alt=""
								className="w-32 h-32 rounded-full mt-12"
							></img>
							<div className="flex items-stretch justify-between w-full px-2 mt-12">
								{/* Green Button */}
								<div className="flex items-center justify-center bg-white h-14 w-14 rounded-full">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="size-6 text-green-600"
									>
										<path
											fillRule="evenodd"
											d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
								{/* Red Button */}
								<div className="flex items-center justify-center bg-white h-14 w-14 rounded-full">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="size-6 text-red-600"
									>
										<path
											fillRule="evenodd"
											d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AndroidPhone;
