import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import "./business-details.css";

import { useAuth } from "../auth/auth-context";
import { BusinessDetailsViewModel } from "./business-details.view-model";
import { BusinessDetailsProps } from "./business-details.props";

import ValidatedInput from "src/components/molecules/validated-input/validated-input";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SectionHeader from "src/components/molecules/section-header/section-header";
import { BusinessDetailsModel } from "src/types/models/apiModels";

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
	businessDetails,
	onChange,
}) => {
	const [details, setDetails] = useState<BusinessDetailsModel>(
		businessDetails ?? {
			fields: [],
		}
	);
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BusinessDetailsViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessDetailsViewModel(
						user.accessToken
					);
					const countryCode = `${process.env.REACT_APP_COUNTRY_CODE}`;
					const response =
						await viewModel.current?.initBusinessDetails(
							user,
							countryCode,
							businessDetails
						);
					console.log("initBusiness", response);
					setDetails(() => response);
				}
			} catch (error) {
				console.error("Error fetching initBusiness:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		if (onChange) {
			onChange(details);
		}
	}, [details]);

	const handleFieldChange = (field: string, value: string) => {
		setDetails((prevInfo) => ({
			...prevInfo,
			fields: prevInfo.fields.map((f) =>
				f.name === field ? { ...f, value: value } : f
			),
		}));
	};

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		); // Pass the optional message
	}

	return (
		<>
			<SectionHeader title="Additional Information"></SectionHeader>
			<Grid container spacing={2} className="form-section">
				{details.fields.map((item: any, index: any) => (
					<Grid key={index} item xs={12} sm={12}>
						<ValidatedInput
							type={item.type}
							label={item.title}
							value={item.value ?? ""}
							onChange={(value) =>
								handleFieldChange(item.name, value)
							}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default BusinessDetails;
