interface ErrorProps {
	error: string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
	return (
		<>
			{error && (
				<span className="bg-red-600/80 text-white px-4 py-1 rounded-md font-semibold inline-flex items-center">
					{error}
				</span>
			)}
		</>
	);
};
