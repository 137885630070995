import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ButtonInviteUserProps } from "./button-invite-user.props";
import "./button-invite-user.css";

const ButtonInviteUser: React.FC<ButtonInviteUserProps> = ({
  onClick,
  disabled = false,
}) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<AddCircleOutlineIcon />}
      className="invite-user-button"
      disabled={disabled}
    >
      INVITE USER
    </Button>
  );
};

export default ButtonInviteUser;
