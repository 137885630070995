import React, { useState } from "react";
import { Alert, Box, Divider, IconButton, Typography } from "@mui/material";
import { HelpTextProps } from "./help-text.props";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";

const HelpText: React.FC<HelpTextProps> = ({
  help,
  alignment = "left",
  display = "alert",
  onCloseClick,
}) => {
  const [showHelp, setShowHelp] = useState(true);

  const toggleHelp = () => {
    setShowHelp((prev) => !prev);
    if (onCloseClick) {
      onCloseClick();
    }
  };

  if (!showHelp) return null;

  return (
    <Box sx={{ position: "relative", marginBottom: 3 }}>
      <IconButton
        onClick={toggleHelp}
        sx={{ position: "absolute", top: 0, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <>
        {display === "alert" ? (
          <Alert
            severity="info"
            icon={<HelpIcon />}
            sx={{ marginBottom: 3, textAlign: alignment }}
          >
            {help.map((paragraph, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                {paragraph}
              </Typography>
            ))}
          </Alert>
        ) : (
          <>
            {help.map((paragraph, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{ marginBottom: 2, textAlign: alignment, marginTop: 2 }}
              >
                {paragraph}
              </Typography>
            ))}
            <Divider sx={{ marginBottom: 3 }} />
          </>
        )}
      </>
    </Box>
  );
};

export default HelpText;
