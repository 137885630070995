import { Box, Typography } from "@mui/material";
import { SectionHeaderProps } from "./section-header.props";
import "./section-header.css";
import SectionTitle from "src/components/atoms/section-title/section-title";

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  return (
    <Box className="section-header">
      <SectionTitle title={props.title}></SectionTitle>
      <Typography className="section-sub-title" align="left">
        {props.subTitle}
      </Typography>
    </Box>
  );
};

export default SectionHeader;
