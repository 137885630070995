import React, { useEffect, useRef, useState } from "react";
import { ListItemText } from "@mui/material";

import { useAuth } from "../auth/auth-context";

import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import EntityList from "../entity-list/entity-list";
import {
	ContactCentreModel,
	TrialUsersModel,
} from "src/types/models/apiModels";
import { BusinessTrialUsersListViewModel } from "./business-trial-users-list.view-model";
import { BusinessTrialUsersListProps } from "./business-trial-users-list.props";

const BusinessTrialUsersList: React.FC<BusinessTrialUsersListProps> = ({
	onAddNewClick,
	onSelect,
	selectMode,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<TrialUsersModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BusinessTrialUsersListViewModel | null>(null);
	const [selectedContactCentre, setSelectedContactCentre] = useState<
		string | null
	>(null);

	const handleSelectContactCentre = (trialUser: TrialUsersModel) => {
		// setSelectedContactCentre(trialUser.);
		// if (onSelect) {
		//   onSelect(contactCentre);
		// }
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessTrialUsersListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData", response);
					// setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderContactCentreItem = (user: TrialUsersModel) => (
		<>
			<ListItemText
				primary={
					<SCLabel
						color="#000"
						fontWeight="normal"
						align="left"
						textTransform="none"
					>
						{user.brandedNumber} ({user.emailId})
					</SCLabel>
				}
				secondary={<>{user.businessId}</>}
				primaryTypographyProps={{
					sx: {
						color:
							selectedContactCentre === user.brandedNumber
								? "white"
								: "inherit",
						fontWeight:
							selectedContactCentre === user.brandedNumber
								? "bold"
								: "normal",
					},
				}}
			/>
		</>
	);

	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your contact centres, please hold on!" />
		); // Pass the optional message
	}

	return (
		<EntityList<TrialUsersModel>
			data={data}
			selectMode={selectMode} // or false depending on your requirements
			// onSelect={(cc) => handleSelectContactCentre(cc)}
			renderChildren={renderContactCentreItem}
			onAddNewClick={onAddNewClick}
			listLabel={"Choose Your Contact Centre"}
		/>
	);
};

export default BusinessTrialUsersList;
