import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import { useEffect, useState } from "react";
import SectionHeader from "src/components/molecules/section-header/section-header";
import { BusinessNumbersProps } from "./business-numbers.props";
import AddOutgoingNumber from "../add-outgoing-number/add-outgoing-number";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import TableHeader from "src/components/molecules/table-header/table-header";
import {
  IFileInfo,
  NewNumberModel,
  ServiceStatus,
} from "src/types/models/apiModels";

const NewBusinessNumbers: React.FC<BusinessNumbersProps> = ({
  editNumbers,
  onChange,
}) => {
  const [numberAddError, setNumberAddError] = useState("");
  const [showNumberAddingError, setShowNumberAddingError] = useState(false);
  const [businessNumbers, setBusinessNumbers] = useState<NewNumberModel[]>(
    editNumbers ?? []
  );
  const [addNumberDialogOpen, setAddNumberDialogOpen] =
    useState<boolean>(false);
  useEffect(() => {
    if (onChange) {
      onChange(businessNumbers);
    }
  }, [businessNumbers]);

  const addNumber = (number: string, fileInfo: IFileInfo) => {
    console.log("In handleAddNumberCallback");
    setShowNumberAddingError(false);
    setNumberAddError("");

    setBusinessNumbers((prevNumbers) => {
      // Check if the number already exists in the list
      const numberExists = prevNumbers.some((num) => num.number === number);

      if (numberExists) {
        // console.log("Number already exists. Update rejected.");
        setShowNumberAddingError(true);
        setNumberAddError("Number already exists. Update rejected.");
        // You can add more logic here, like showing an error message
        return prevNumbers; // Return the existing numbers without adding
      }

      const updatedNumbers = [
        ...prevNumbers,
        {
          number: number,
          ownershipDocuments: [fileInfo],
          status: "New" as ServiceStatus,
        },
      ];

      return updatedNumbers;
    });

    setAddNumberDialogOpen(false);
  };

  const deleteNumber = (number: string) => {
    console.log("In handleDeleteNumberCallback");

    setBusinessNumbers((prevNumbers) => {
      const updatedNumbers = prevNumbers.filter((num) => num.number !== number);
      return updatedNumbers; // Return the updated array
    });

    setAddNumberDialogOpen(false);
  };

  const handleAddNewNumberClick = () => {
    setAddNumberDialogOpen(true);
  };
  const toggleDialog = () => {
    console.log("In toggleEditMode");
    setAddNumberDialogOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <SectionHeader title="Add Outgoing Numbers"></SectionHeader>
      <Table sx={{ minWidth: 650, borderCollapse: "collapse" }}>
        <TableHeader
          headers={["OUTGOING NO.", "PROOF OF OWNERSHIP", "STATUS", "ACTIONS"]}
        ></TableHeader>
        <TableBody>
          {businessNumbers.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                {row.number}
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                <IconButton size="small" color="primary">
                  <DownloadIcon />
                </IconButton>
                {row.ownershipDocuments[0].fileName}
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                {row.status}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => deleteNumber(row.number)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {showNumberAddingError && (
        <>
          <br></br>
          <Alert severity="error">{numberAddError}</Alert>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <ButtonAddNew onClick={handleAddNewNumberClick}></ButtonAddNew>
      </Box>
      <Dialog
        open={addNumberDialogOpen}
        onClose={toggleDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>
          <AddOutgoingNumber onAdd={addNumber}></AddOutgoingNumber>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewBusinessNumbers;
