import React, { useEffect, useRef, useState } from "react";
import { TextField, Autocomplete, Box, FormControl } from "@mui/material";
import Banner from "src/components/molecules/banner/Banner";
import "./invite-user.css";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import PhoneNumberField from "src/components/molecules/phoneNumberField/PhoneNumberField";
import {
	ContactsModel,
	InviteUserModel,
	InviteUserViewModel,
} from "./invite-user.view-model";
import { useAuth } from "src/components/organisms/auth/auth-context";
import { useNavigate } from "react-router-dom";
import { InviteUserProps } from "./invite-user.props";
import { IPhoneNumber } from "src/types/models/apiModels";

// Define a type for the user role option
interface RoleType {
	label: string;
}

const InviteUser: React.FC<InviteUserProps> = ({ onInvite }) => {
	// List of all user roles
	const userRoles: RoleType[] = [
		{ label: "AccountAdmin" },
		{ label: "BusinessAdmin" },
		{ label: "UnitAdmin" },
		{ label: "Moderator" },
	];

	const { user } = useAuth();
	const navigate = useNavigate();

	const viewModel = useRef<InviteUserViewModel | null>(null);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new InviteUserViewModel(user.accessToken);
		} else {
			navigate("/login");
		}
	}, [user, navigate]);
	const [email, setEmail] = useState<string>("");
	// Combine phone number and full phone number into one state
	const [phoneNumberState, setPhoneNumberState] = useState<{
		phoneNumber: IPhoneNumber;
		fullPhoneNumber: string;
	}>({
		phoneNumber: {
			countryCode: "",
			number: "",
		},
		fullPhoneNumber: "",
	});
	const [userRole, setUserRole] = useState<RoleType | null>(null);

	// Form submission handler
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const userToInvite: InviteUserModel = {
			countryCode: "AU", // Example country code, you can replace it with a dynamic value
			contacts: [
				{
					emailId: email, // Email from form state
					phoneNumber: phoneNumberState.phoneNumber, // IPhoneNumber from form state
					role: userRole?.label || "", // Role from form state, default to empty if null
				},
			],
		};
		const contact: ContactsModel = {
			emailId: email,
			phoneNumber: phoneNumberState.phoneNumber,
			role: userRole?.label || "",
		};
		console.log("User to Invite:", userToInvite);
		onInvite(contact);
		//console.log("Business ID:", businessId);
		/*viewModel.current?.inviteUser(businessId, userToInvite).then((response) => {
      if (response.result) {
        console.log("User invited successfully");
      } else {
        console.error("Error inviting user:", response.error);
      }
    });*/
	};

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				justifyContent: "center",
				alignItems: "center",
				display: "flex",
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label="INVITE USER">
				<form className="inviteUser-form" onSubmit={handleSubmit}>
					{/* Email input */}
					<FormControl fullWidth margin="normal">
						<TextField
							label="Email"
							name="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							variant="filled"
							className="inviteUser-input"
						/>
					</FormControl>
					{/* Phone number input */}
					<FormControl fullWidth margin="normal">
						<PhoneNumberField
							phoneNumber={phoneNumberState.phoneNumber}
							value={phoneNumberState.fullPhoneNumber}
							onChange={(updatedPhoneNumber, fullValue) =>
								setPhoneNumberState({
									phoneNumber: updatedPhoneNumber,
									fullPhoneNumber: fullValue,
								})
							}
						></PhoneNumberField>
					</FormControl>
					{/* User role input */}
					<FormControl fullWidth margin="normal">
						<Autocomplete
							options={userRoles}
							getOptionLabel={(option) => option.label}
							value={userRole}
							onChange={(event, value) => setUserRole(value)}
							isOptionEqualToValue={(option, value) =>
								option.label === value?.label
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label="User Role"
									variant="filled"
									required
									className="inviteUser-input"
								/>
							)}
						/>
					</FormControl>
					{/* Submit button */}
					<PrimaryButton
						type="submit"
						variant="outlined"
						color="info"
						label="INVITE USER"
						cssClassName="primary-button"
						disabled={false}
					></PrimaryButton>
				</form>
			</Banner>
		</Box>
	);
};

export default InviteUser;
