import React, { useEffect, useRef, useState } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import "./high-level-statistics.css";
import { useAuth } from "../auth/auth-context";
import { HighLevelStatisticsViewModel } from "./high-level-statistics.view-model";
import {
	BusinessListModel,
	MobileAppModel,
	NewNumberModel,
} from "src/types/models/apiModels";
import Banner from "src/components/molecules/banner/Banner";
import CallIcon from "@mui/icons-material/Call";
import StatisticsCard from "./statistics-card";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const HighLevelStatistics = ({}) => {
	const { user } = useAuth();
	const viewModel = useRef<HighLevelStatisticsViewModel | null>(null);
	const [loading, setLoading] = useState(false);
	const [businessCount, setBusinessCount] = useState<number>(0);
	const [businessNumbersCount, setBusinessNumbersCount] = useState<number>(0);
	const [mobileAppCount, setMobileAppCount] = useState<number>(0);
	const [contactCentreCount, setContactCentreCount] = useState<number>(0);
	const [appCallCounts, setAppCallCounts] = useState<
		{ mobileApp: MobileAppModel; countOfCalls: number }[]
	>([]);
	const [loadingBusinessData, setLoadingBusinessData] = useState(false);
	const [loadingNumberData, setLoadingNumberData] = useState(false);
	const [loadingAppsData, setLoadingAppsData] = useState(false);
	const [loadingCCData, setLoadingCCData] = useState(false);
	let dataLoaded = false;

	useEffect(() => {
		setLoadingBusinessData(true);
		setLoadingNumberData(true);
		setLoadingAppsData(true);
		setLoadingCCData(true);

		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new HighLevelStatisticsViewModel(
						user.accessToken
					);
					const businessList: {
						business: BusinessListModel;
						numbers: NewNumberModel[];
					}[] = await viewModel.current?.fetchBusinessList();

					const totalNumberCount = businessList.reduce(
						(acc, current) => {
							return acc + current.numbers.length;
						},
						0
					);

					if (businessList) {
						setBusinessCount(() => businessList.length);
						setBusinessNumbersCount(() => totalNumberCount);
						setLoadingBusinessData(false);
						setLoadingNumberData(false);
					}
					const appList =
						await viewModel.current?.fetchMobileAppList();
					if (appList) {
						setMobileAppCount(() => appList.length);
						setAppCallCounts(() => appList);
						setLoadingAppsData(false);
					}
					const ccList =
						await viewModel.current?.fetchContactCentreList();
					if (ccList) {
						setContactCentreCount(() => ccList.length);
						setLoadingCCData(false);
					}

					dataLoaded = true;
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};
		fetchData();
	}, [user]);

	return (
		<Banner label="YOUR ACCOUNT STATISTICS">
			<Grid
				container
				spacing={2}
				justifyContent="center"
				alignItems="center"
			>
				{/* Businesses Count */}
				<StatisticsCard
					value={`${businessCount}`}
					title={"Businesses"}
					isLoading={loadingBusinessData}
				></StatisticsCard>
				<StatisticsCard
					value={`${businessNumbersCount}`}
					title={"OG Numbers"}
					isLoading={loadingNumberData}
				></StatisticsCard>
				<StatisticsCard
					value={`${mobileAppCount}`}
					title={"Configured Apps"}
					isLoading={loadingAppsData}
				></StatisticsCard>
				<StatisticsCard
					value={`${contactCentreCount}`}
					title={"Contact Centres"}
					isLoading={loadingCCData}
				></StatisticsCard>
			</Grid>

			{/* Calls Per App Section */}
			<Box
				sx={{
					marginTop: 4,
					width: "100%",
				}}
			>
				{appCallCounts.filter((app) => app.mobileApp.name !== "")
					.length > 0 && (
					<SCLabel color="#003060" fontSize={48}>
						Calls per application
					</SCLabel>
				)}
				{appCallCounts.filter((app) => app.mobileApp.name !== "")
					.length === 0 &&
					dataLoaded && (
						<SCLabel color="Red" fontSize={48}>
							No Applications configured
						</SCLabel>
					)}
				{appCallCounts.filter((app) => app.mobileApp.name !== "")
					.length > 0 && (
					<Grid container spacing={2}>
						{appCallCounts
							.filter((app) => app.mobileApp.name !== "")
							.map((app, index) => (
								<Grid item xs={12} sm={6} md={3} key={index}>
									<StatisticsCard
										value={`${app.countOfCalls}`}
										title={app.mobileApp.name}
										isLoading={loadingCCData}
									/>
								</Grid>
							))}
					</Grid>
				)}
			</Box>
		</Banner>
	);
};

export default HighLevelStatistics;
