import { createTheme } from "@mui/material/styles";

const fontFamily = "Montserrat, sans-serif";

const theme = createTheme({
  typography: {
    fontFamily, // Set the default font family for all typography
    h1: { fontFamily }, // Override any specific typographies if needed
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    body1: { fontFamily },
    body2: { fontFamily },
    button: { fontFamily },
    caption: { fontFamily },
    overline: { fontFamily },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily, // Set the global font family for the body
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily, // Apply the font family to TextField root
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily,
          fontWeight: 600,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily,
          fontWeight: 600,
        },
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily,
          fontWeight: 500,
        },
        input: {
          fontFamily,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily,
        },
        input: {
          fontFamily,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily,
          fontSize: "0.75rem",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#3498db",
    },
    secondary: {
      main: "#2C3E50",
    },
    info: {
      main: "#3498db",
    },
  },
});

export default theme;