import React from "react";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	SelectChangeEvent,
} from "@mui/material";

import { DropdownProps } from "./dropdown.props";

const Dropdown: React.FC<DropdownProps> = ({
	onChange,
	value,
	label,
	menuItems,
}) => {
	const handleChange = (event: SelectChangeEvent<string>) => {
		onChange?.(event.target.value);
	};
	return (
		<FormControl fullWidth variant="filled">
			<InputLabel id="country-code-label">{label}</InputLabel>
			<Select
				labelId="country-code-label"
				variant="filled"
				id="country-code-select"
				value={value}
				onChange={handleChange}
				label={label}
				required
				sx={{
					textAlign: "left", // Ensure text is left-aligned
					minWidth: "fit-content", // Make width fit to content
				}}
			>
				{menuItems.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default Dropdown;
