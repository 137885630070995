import React from "react";
import { ButtonAddNewProps } from "./button-add-new.props";
import "./button-add-new.css";

const ButtonAddNew: React.FC<ButtonAddNewProps> = ({
	text = "ADD NEW",
	onClick,
	disabled = false,
}) => {
	return (
		<button
			className={`p-2 drop-shadow-lg flex items-center justify-center gap-2 w-fit ${
				disabled
					? "bg-gray-300 border-b-4 border-gray-800 cursor-not-allowed text-gray-500"
					: "bg-white border-b-4 border-blue-400 text-blue-700"
			}`}
			onClick={onClick}
			disabled={disabled}
		>
			<div className="flex items-center justify-center gap-2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className={`size-6 ${
						disabled ? "text-gray-500" : "text-blue-700"
					}`}
				>
					<path
						fillRule="evenodd"
						d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
						clipRule="evenodd"
					/>
				</svg>

				<span
					className={`font-semibold text-sm uppercase ${
						disabled ? "text-gray-500" : "text-blue-700"
					}`}
				>
					{text}
				</span>
			</div>
		</button>
	);
};

export default ButtonAddNew;
