import Button from "@mui/material/Button";
import React from "react";
import "./Primary-Button.styles.css";
import { PrimaryButtonProps } from "./Primary-Button.props";

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  type = "button",
  color,
  variant,
  cssClassName,
  onClick,
  disabled,
  label,
  children,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Find the input element within the button and trigger the click event
    const inputElement = e.currentTarget.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.click(); // Now TypeScript knows this is an HTMLInputElement
    }
    // Call the original onClick handler
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      className={cssClassName}
      onClick={handleClick}
      disabled={disabled}
    >
      {label} {children}
    </Button>
  );
};

export default PrimaryButton;
