import React, { useEffect, useRef, useState } from "react";
import {
	Avatar,
	Box,
	Dialog,
	DialogContent,
	ListItemAvatar,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";

import { BusinessNumbersProps } from "../business-numbers/business-numbers.props";
import BusinessList from "../business-list/business-list";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import StatusChip from "src/components/atoms/status-chip/status-chip";
import { UserManagementViewModel } from "./user-management-viewmodel";
import { useAuth } from "../auth/auth-context";
import ButtonInviteUser from "src/components/atoms/button-invite-user/button-invite-user";
import InviteUser from "../invite-user/invite-user";
import TableHeader from "src/components/molecules/table-header/table-header";
import {
	ContactsModel,
	InviteUserModel,
} from "../invite-user/invite-user.view-model";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import { BusinessListModel } from "src/types/models/apiModels";

const UserManagement: React.FC<BusinessNumbersProps> = ({
	editNumbers,
	onChange,
}) => {
	const { user } = useAuth();
	const viewModel = useRef<UserManagementViewModel>();

	const [business, setBusiness] = useState<BusinessListModel | undefined>(
		undefined
	);

	const [businessId, setBusinessId] = useState<string>("");

	const [selectBusinessDialogOpen, setSelectBusinessDialogOpen] =
		useState<boolean>(false);

	const [contacts, setContacts] = useState<ContactsModel[]>([]);

	const [inviteUserDialogOpen, setInviteUserDialogOpen] =
		useState<boolean>(false);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new UserManagementViewModel(user?.accessToken);
		}
	}, []);

	const toggleBusinessDialog = () => {
		console.log("In toggleBusinessDialog");
		setSelectBusinessDialogOpen((prevValue) => !prevValue);
	};

	const toggleDialog = () => {
		setInviteUserDialogOpen((prevValue) => !prevValue);
	};

	const addContact = (contact: ContactsModel) => {
		console.log("In handleAddNumberCallback");
		setContacts((prevContacts) => {
			const updatedContacts = [...prevContacts, contact];
			return updatedContacts;
		});

		setInviteUserDialogOpen(false);
	};

	function handleOnSelectBusiness(business: BusinessListModel): void {
		console.log("handleOnSelectBusiness", business.businessId);
		setBusiness(business);
		setBusinessId(business.businessId);
		toggleBusinessDialog();
	}

	function showBusinessList(): void {
		toggleBusinessDialog();
	}

	function handleOnAddBusiness(): void {
		throw new Error("Function not implemented.");
	}

	const handleInviteUserClick = () => {
		setInviteUserDialogOpen(true);
	};

	const handleSubmit = async () => {
		console.log("Business ID:", businessId);
		const userToInvite: InviteUserModel = {
			countryCode: "AU", // Example country code, you can replace it with a dynamic value
			contacts: contacts,
		};
		viewModel.current
			?.inviteUsers(businessId, userToInvite)
			.then((response) => {
				if (response.result) {
					setContacts([]);
					console.log("User invited successfully");
				} else {
					console.error("Error inviting user:", response.error);
				}
			});
	};

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label="USER MANAGEMENT">
				<Box className="form-container">
					<SCLabel color="#000">
						{business ? (
							<span
								onClick={showBusinessList}
								style={{
									display: "flex",
									cursor: "pointer",
								}}
							>
								<ListItemAvatar>
									<Avatar
										sx={{
											width: "75px",
											height: "75px",
											marginRight: 2,
										}}
										src={business.logo.signedUrl}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										<SCLabel
											color="#000"
											fontSize={24}
											fontWeight="medium"
											align="left"
											textTransform="none"
										>
											{business.name}
										</SCLabel>
									}
									secondary={
										<StatusChip status={business.status} />
									}
									primaryTypographyProps={{
										sx: {
											fontWeight: "normal",
										},
									}}
									secondaryTypographyProps={{
										sx: {
											textAlign: "left", // Ensures that the secondary element is left-aligned
										},
									}}
								/>
							</span>
						) : (
							<button
								onClick={showBusinessList}
								style={{
									display: "flex",
									cursor: "pointer",
									color: "blue",
									background: "none",
									border: "none",
									textDecoration: "underline",
								}}
							>
								<SCLabel color="">
									Click here to select a business
								</SCLabel>
							</button>
						)}
					</SCLabel>
					<br></br>
					<Table
						sx={{
							minWidth: 650,
							borderCollapse: "collapse",
						}}
					>
						<TableHeader
							headers={["EMAIL", "PHONE NUMBER", "USER ROLE"]}
						></TableHeader>
						<TableBody>
							{contacts.map((row, index) => (
								<TableRow key={index}>
									<TableCell>
										<SCLabel color="#000" align="center">
											{row.emailId}
										</SCLabel>
									</TableCell>
									<TableCell>
										<SCLabel color="#000" align="center">
											{`${row.phoneNumber.countryCode}${row.phoneNumber.number}`}
										</SCLabel>
									</TableCell>
									<TableCell>
										<SCLabel color="#000" align="center">
											{row.role}
										</SCLabel>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: 2,
						marginBottom: 2,
					}}
				>
					<ButtonInviteUser
						disabled={!business}
						onClick={handleInviteUserClick}
					></ButtonInviteUser>
				</Box>
				<PrimaryButton
					variant="outlined"
					color="info"
					label="SUBMIT"
					cssClassName="primary-button"
					disabled={!business || contacts.length === 0}
					onClick={handleSubmit}
				></PrimaryButton>
			</Banner>
			{/* Add number popup */}
			<Dialog
				open={inviteUserDialogOpen}
				onClose={toggleDialog}
				maxWidth="sm"
				fullWidth
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
			>
				<DialogContent>
					<InviteUser onInvite={addContact}></InviteUser>
				</DialogContent>
			</Dialog>
			{/* Select business pop-up */}
			<Dialog
				open={selectBusinessDialogOpen}
				onClose={toggleBusinessDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectBusiness}
						selectMode={true}
					></BusinessList>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default UserManagement;
