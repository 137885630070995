import React from "react";
import defaultLogo from "../../../assets/sq-logo.png";

interface LogoProps {
	image?: string;
	isCircular?: boolean; // Optional prop to control circular or rounded corners
	size?: number; // Optional prop to control the size of the logo in pixels
}

const Logo: React.FC<LogoProps> = ({
	image,
	isCircular = false,
	size = 90,
}) => {
	return (
		<div className="w-full flex items-center justify-center p-2">
			<img
				src={image || defaultLogo}
				alt="Logo"
				className={isCircular ? "rounded-full" : "rounded-lg"} // Apply circular or rounded corners
				style={{
					height: `${size}px`,
					width: `${size}px`, // Ensure the image is square
					boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
					objectFit: "cover", // To handle image fit within the specified height/width
				}}
			/>
		</div>
	);
};

export default Logo;
