import { IBannerProps } from "./Banner.props";

const Banner: React.FC<IBannerProps> = (props) => {
	return (
		<div className="flex flex-col items-center justify-start w-full m-6">
			<span
				className="bg-gradient-to-r from-sky-700 to-sky-950 text-white rounded-t-lg
			 min-h-24 flex items-center justify-center uppercase drop-shadow-lg text-4xl tracking-wider font-semibold border-b-8 border-orange-600 w-full"
			>
				{props.label}
			</span>
			<div className="bg-white p-8 rounded-b-lg drop-shadow-2xl w-full">
				{props.children}
			</div>
		</div>
	);
};

export default Banner;
