import React, { useEffect, useState } from "react";

interface ICheckBoxProps {
	title?: string;
	onChange: (value: boolean) => void;
	checked?: boolean; // Controlled prop for external state management
	disabled?: boolean; // Optional prop to disable the checkbox
}

const CheckBox: React.FC<ICheckBoxProps> = ({
	title,
	onChange,
	checked: controlledChecked,
	disabled = false,
}) => {
	// Internal state for uncontrolled usage
	const [internalChecked, setInternalChecked] = useState<boolean>(false);

	// Determine whether the component is controlled or uncontrolled
	const isControlled = controlledChecked !== undefined;

	// Handle toggle behavior
	const handleToggle = () => {
		if (disabled) return; // Do nothing if disabled

		// If controlled, notify parent; otherwise, update internal state
		if (isControlled) {
			onChange(!controlledChecked);
		} else {
			const newValue = !internalChecked;
			setInternalChecked(newValue);
			onChange(newValue); // Notify the parent about the change
		}
	};

	// Sync internal state with controlled prop
	useEffect(() => {
		if (isControlled) {
			setInternalChecked(controlledChecked!);
		}
	}, [controlledChecked, isControlled]);

	return (
		<div
			className={`flex flex-col items-center justify-center gap-2 select-none ${
				disabled ? "cursor-not-allowed text-gray-400" : "cursor-pointer"
			}`}
			onClick={handleToggle} // Toggle on click if not disabled
		>
			<div className="h-12 w-12 flex items-center justify-center">
				{internalChecked ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="currentColor"
						className={`size-24 font-semibold ${disabled ? "text-green-300" : "text-green-600"}`}
					>
						<path
							fillRule="evenodd"
							d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
							clipRule="evenodd"
						/>
					</svg>
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="currentColor"
						className={`size-24 font-semibold ${disabled ? "text-gray-400" : "text-green-400"}`}
					>
						<path
							fillRule="evenodd"
							d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
							clipRule="evenodd"
						/>
					</svg>
				)}
			</div>
			{title && (
				<span
					className={`font-semibold text-md ${disabled ? "text-gray-400" : ""}`}
				>
					{title}
				</span>
			)}
		</div>
	);
};

export default CheckBox;
