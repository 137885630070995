import React, { useEffect, useRef, useState } from "react";
import Banner from "src/components/molecules/banner/Banner";
import PhoneNumberField from "src/components/molecules/phoneNumberField/PhoneNumberField";
import { AddOutgoingNumberProps } from "./add-outgoing-number.props";
import { useAuth } from "../auth/auth-context";
import { AddOutgoingNumberViewModel } from "./add-outgoing-number.view-model";
import UploadButton from "src/components/molecules/upload-button/upload-button";
import { IFileInfo, IPhoneNumber } from "src/types/models/apiModels";
import ButtonPro from "src/components/atoms/primary-button/primary-button";

const AddOutgoingNumber: React.FC<AddOutgoingNumberProps> = ({
	onAdd,
	fileInfo,
}) => {
	const [fileSrc, setFileSrc] = useState<IFileInfo | undefined>(fileInfo);
	const { user } = useAuth();
	const [number, setNumber] = useState<IPhoneNumber>({
		countryCode: "",
		number: "",
	});
	const [outgoingNumber, setOutgoingNumber] = useState("");
	const [fileName, setFileName] = useState("");

	const viewModel = useRef<AddOutgoingNumberViewModel | null>(null);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new AddOutgoingNumberViewModel(
				user.accessToken
			);
		}
	}, [user]);

	useEffect(() => {
		// console.log("###### imageSrc-useEffect", fileSrc);
		if (fileSrc?.fileName) {
			setFileName(fileSrc.fileName);
		}
	}, [fileSrc]);

	const handleFileChange = async (uploadedImage: IFileInfo) => {
		if (uploadedImage) {
			// console.log("#####uploadedFile", uploadedImage);
			setFileSrc(() => uploadedImage);
		}
	};

	const handleAdd = () => {
		console.log(
			`handleAdd: outgoingNumber: ${outgoingNumber}, file`,
			fileSrc
		);
		if (outgoingNumber && fileSrc) {
			// console.log("calling onAdd");
			onAdd(outgoingNumber, fileSrc);
		}
	};

	return (
		<Banner label="OUTGOING NUMBER">
			<div className="flex flex-col items-start justify-center gap-4 w-full">
				<PhoneNumberField
					phoneNumber={number}
					value={outgoingNumber}
					onChange={(phoneNumber, value) => setOutgoingNumber(value)}
				></PhoneNumberField>
				<span className="font-semibold text-lg">
					UPLOAD PROOF OF OWNERSHIP
				</span>
				<UploadButton
					buttonType="File"
					onUploadSuccess={handleFileChange}
				></UploadButton>

				{fileName && (
					<>
						<span className="font-semibold text-sm">
							Uploaded file
						</span>
						<span className="font-semibold text-sm">
							{fileName || "No file selected"}
						</span>
					</>
				)}

				<ButtonPro
					label={"add"}
					onClick={handleAdd}
					disabled={!outgoingNumber || !fileSrc}
				></ButtonPro>
			</div>
		</Banner>
	);
};

export default AddOutgoingNumber;
