import { ApiService } from "src/network/apiService";

import { LoggedInUser } from "../auth/auth-context";

import { parsePhoneNumber } from "libphonenumber-js";
import { ContactModel, IPhoneNumber } from "src/types/models/apiModels";

export class BusinessContactViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async initContact(
    useLoggedInUser: boolean,
    user: LoggedInUser,
    contact?: ContactModel
  ): Promise<ContactModel> {
    if (useLoggedInUser) {
      const phone = this.ParseNumber(user.phoneNumber);
      return {
        firstName: user.givenName,
        lastName: user.familyName,
        emailId: user.username,
        phoneNumber: phone,
      };
    }
    if (contact) {
      return contact;
    }

    return {
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: {
        countryCode: "",
        number: "",
      },
    };
  }

  ParseNumber(phoneNumberString: string): IPhoneNumber {
    const phoneNumber = parsePhoneNumber(phoneNumberString);

    if (phoneNumber.isValid()) {
      return {
        countryCode: phoneNumber.countryCallingCode,
        number: phoneNumber.nationalNumber,
      };
    } else {
      console.log("The phone number is not valid.");
      return {
        countryCode: "",
        number: "",
      };
    }
  }
}
