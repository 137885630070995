import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";

import "./imageUploader.css"; // Use the same CSS file for styling
import { useAuth } from "src/components/organisms/auth/auth-context";
import { ImageUploaderViewModel } from "./imageUploaderViewModel";
import { ImageUploadProps } from "./imageUpload.props";
import UploadButton from "../upload-button/upload-button";
import { IFileInfo } from "src/types/models/apiModels";

const ImageUploadButton: React.FC<ImageUploadProps> = ({
	image,
	onUploadSuccess,
	borderRadius = "24px",
	defaultImage,
}) => {
	const blankImage = "https://via.placeholder.com/150";
	const [imageSrc, setImageSrc] = useState<IFileInfo | undefined>(image);
	const [imageUrl, setImageUrl] = useState<string | undefined>(
		defaultImage ?? blankImage
	);
	const { user } = useAuth(); // Extract user from useAuth hook

	// Create a ref to hold the viewModel instance
	const viewModel = useRef<ImageUploaderViewModel | null>(null);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new ImageUploaderViewModel(user.accessToken);
		}
	}, [user]);
	useEffect(() => {
		if (defaultImage) {
			setImageUrl(defaultImage);
		}
	}, [defaultImage]);
	useEffect(() => {
		console.log("###### imageSrc-useEffect", imageSrc);
		if (imageSrc?.signedUrl) {
			setImageUrl(() => imageSrc?.signedUrl);
		} else {
			setImageUrl(() => blankImage);
		}
		onUploadSuccess(imageSrc);
	}, [imageSrc]);

	const handleImageChange = async (uploadedImage: IFileInfo) => {
		if (uploadedImage) {
			console.log("#####uploadedFile", uploadedImage);
			setImageSrc(() => uploadedImage);
		}
	};

	return (
		<Grid
			container
			direction="column"
			alignItems="flex-start" // Align items to the left
			justifyContent="flex-start" // Align items to the top (optional)
			className="image-uploader-container"
		>
			{/* <Grid item>
        <img
          src={imageUrl}
          alt="Uploaded"
          className="image-uploader-preview"
          style={{ borderRadius }}
        />
      </Grid> */}
			<Grid item sx={{ minWidth: "50px" }}>
				<UploadButton
					onUploadSuccess={handleImageChange}
					buttonType="Primary"
					borderRadius={borderRadius}
				></UploadButton>
			</Grid>
		</Grid>
	);
};

export default ImageUploadButton;
