import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmSignUp, signIn } from "src/components/organisms/auth";
import { useAuth } from "src/components/organisms/auth/auth-context";
import { ArrowLeft } from "lucide-react";
import { TokenHelper } from "../../auth/token-helper";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import ButtonPro from "src/components/atoms/primary-button/primary-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";

interface EmailVerificationProps {
	emailAddress: string;
	onVerification: (isSuccess: boolean) => void;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({
	emailAddress,
	onVerification,
}) => {
	const [verificationCode, setVerificationCode] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false); // Added loading state
	const location = useLocation();
	const { login } = useAuth(); // Get the login function from the AuthContext
	const navigate = useNavigate();
	const username = location.state?.businessEmail;
	const password = location.state?.password;
	const tokenHelper = useRef<TokenHelper | null>(null);

	useEffect(() => {
		tokenHelper.current = new TokenHelper();
	}, []);

	const handleVerify = (event: React.FormEvent) => {
		console.log(`clicked...`);
		event.preventDefault();
		if (!username || !password) {
			console.log(`error`);
			navigate("/login");
			setError("Missing username or password");
			return;
		}

		setLoading(true); // Set loading to true when verification starts
		confirmSignUp(username, verificationCode, async (err, result) => {
			if (err) {
				console.log(`error = ${err}`);
				setError(err.message || JSON.stringify(err));
				setLoading(false); // Set loading to false if there is an error
			} else {
				try {
					// console.log(`before sign-in`);
					signIn(username, password, async (err, result) => {
						if (err) {
							console.log(`error sign-in`);
							setError(err.message || JSON.stringify(err));
							setLoading(false); // Set loading to false if there is an error
						} else {
							// console.log(`sign-in success`);
							// Call the API after successful sign-in
							// console.log(`Login result ${JSON.stringify(result)}`);
							const tokenProps =
								await tokenHelper.current?.decodeToken(
									result.accessToken.jwtToken
								);
							login({
								id: result.idToken.payload.sub,
								givenName: result.idToken.payload.given_name,
								familyName: result.idToken.payload.family_name,
								username,
								phoneNumber:
									result.idToken.payload.phone_number,
								ex_user_id:
									result.accessToken.payload.ex_user_id,
								accessToken: result.accessToken.jwtToken,
								num: result.accessToken.payload.num,
								accountId: tokenProps?.account_id ?? "",
								email: tokenProps?.email ?? "",
								role: tokenProps?.role ?? "",
								exp: result.accessToken.payload.exp,
							}); // Set the authentication state to true with user data
							// Update the authentication state
							onVerification(true);
						}
					});
				} catch (apiError) {
					setError("Error calling the API");
					setLoading(false); // Set loading to false if there is an API error
				}
			}
		});
	};

	return (
		<>
			<div className="flex flex-col items-center justify-center gap-8 w-full md:px-0">
				<label className="font-medium flex-wrap">
					If you don&apos;t find the email in your inbox, please check
					your junk or spam folder.
				</label>
				{emailAddress && (
					<span className="bg-sky-700 py-2 px-4 text-gray-100 font-semibold w-fit rounded-full">
						{emailAddress}
					</span>
				)}

				<form
					className="flex flex-col items-center justify-center gap-6 w-full"
					onSubmit={handleVerify}
				>
					<InputBox
						value={verificationCode}
						label={"Code"}
						type={""}
						onChange={(value) => setVerificationCode(value)}
						required
					></InputBox>
					<ButtonPro label={"Verify"} type="submit"></ButtonPro>
					<label className="ms-2 text-md font-semibold text-white md:text-gray-900">
						<a
							href="/login"
							className="text-blue-600 dark:text-blue-500 hover:underline flex items-center"
						>
							<ArrowLeft className="mr-2" />{" "}
							{/* Add margin to separate arrow from text */}
							Back to login
						</a>
					</label>
				</form>
				<span className="text-red-500 font-semibold">{error}</span>
				<LoadingModal open={loading} message="Please wait..." />
			</div>
		</>

		// <Grid>
		// 	<Typography variant="h6" align="center">
		// 		{emailAddress}
		// 	</Typography>
		// 	<form className="verify-form" onSubmit={handleVerify}>
		// 		<TextField
		// 			fullWidth
		// 			label="Code"
		// 			type="text"
		// 			variant="filled"
		// 			margin="normal"
		// 			required
		// 			className="login-input"
		// 			value={verificationCode}
		// 			onChange={(e) => setVerificationCode(e.target.value)}
		// 		/>
		// 		<Typography variant="body2">
		// 			Did not receive?{" "}
		// 			<Link
		// 				href="https://securedcalls.com/terms.pdf?v=111"
		// 				target="_blank"
		// 			>
		// 				resend
		// 			</Link>{" "}
		// 		</Typography>
		// 		<PrimaryButton
		// 			type="submit"
		// 			variant="outlined"
		// 			color="info"
		// 			label="Verify"
		// 			cssClassName="primary-button"
		// 			disabled={loading || !verificationCode}
		// 		></PrimaryButton>
		// 	</form>
		// 	<LoadingModal open={loading} message="Please wait..." />
		// </Grid>
	);
};

export default EmailVerification;
