import { ApiService } from "src/network/apiService";
import { ApiResponse } from "src/network/models/ApiResponse";
import { DeviceCredentialModel } from "src/types/models/apiModels";

export class ApplicationCredentialsViewModel {
	private apiService: ApiService;

	constructor(accessToken: string) {
		this.apiService = new ApiService({ accessToken: accessToken });
	}

	async fetchDeviceCredentials(
		brandingAppCode: string
	): Promise<DeviceCredentialModel | null> {
		const Url = `/branding/device-credentials?brandingAppCode=${brandingAppCode}`;
		console.log(Url);
		const response =
			await this.apiService.getAsync<ApiResponse<DeviceCredentialModel>>(
				Url
			);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				// console.log(`response -> ${JSON.stringify(response.scResponse.data)}`)
				return response.scResponse.data;
			}

			return null;
		}

		return null;
	}
}
