import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";

export interface LoggedInUser {
	id: string;
	givenName: string;
	familyName: string;
	username: string;
	phoneNumber: string;
	ex_user_id?: string;
	accessToken?: string;
	num?: string;
	accountId: string;
	email: string;
	role?: string;
	exp?: number; // Token expiration time
	[key: string]: any;
}

interface AuthContextType {
	user: LoggedInUser | null;
	isAuthenticated: boolean;
	login: (user: LoggedInUser) => void;
	logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [user, setUser] = useState<LoggedInUser | null>(null);

	const login = (user: LoggedInUser) => {
		setUser(user);

		if (user.exp) {
			const currentTime = Date.now() / 1000; // Get current time in seconds
			if (user.exp <= currentTime) {
				logout(); // Expired token, log out immediately
			} else {
				// Schedule logout when the token expires
				const timeout = (user.exp - currentTime) * 1000;
				setTimeout(() => {
					logout();
				}, timeout);
			}
		}
	};

	const logout = () => {
		setUser(null);
		// You can also clear the token from localStorage or cookies if needed
	};

	const isAuthenticated = !!user;

	useEffect(() => {
		if (user?.exp) {
			const currentTime = Date.now() / 1000;
			if (user.exp <= currentTime) {
				logout(); // Log out immediately if the token is already expired
			} else {
				// Set a timer to log out the user when the token expires
				const timeout = (user.exp - currentTime) * 1000;
				const timer = setTimeout(() => {
					logout();
				}, timeout);

				// Clear the timer if the component unmounts or user logs out manually
				return () => clearTimeout(timer);
			}
		}
	}, [user]);

	return (
		<AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
