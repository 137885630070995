import Logo from "src/components/molecules/logo/Logo";

const LeftSection: React.FC = () => {
	return (
		<div className="hidden md:flex flex-col items-center justify-evenly bg-gradient-to-r from-sky-700 to-sky-950 max-w-[550px] p-12 rounded-l-2xl md:shadow-[0_30px_50px_rgba(0,0,0,.5)] border-r-8 border-orange-600 text-center gap-4">
			<span className="text-white text-3xl mt-4 md:text-6xl font-bold lg:text-4xl uppercase drop-shadow-3xl">
				Secured calls
			</span>
			<div className="border-t-2 border-dotted border-white w-full mt-2"></div>
			<span className="text-white text-md mt-4 font-bold uppercase pb-4">
				Enhance your communication reliability
			</span>
			<p className="text-white tracking-wide pb-2">
				Unanswered calls can have a significant impact on reputable
				businesses. Critical messages may remain uncommunicated,
				consumers could miss important notifications, and brand trust
				may erode.
			</p>
			<p className="text-white tracking-wide pb-2">
				The Secured calls business portal ensures your business
				legitimacy and validates your calling numbers, guaranteeing
				effective communication.
			</p>
			<p className="text-white tracking-wide pb-2">
				Additionally, bolstering your communication channels can further
				solidify your business&apos;s standing. With the Secured calls
				business portal in place, you not only validate your legitimacy
				but also fortify your connections with customers.
			</p>
			<p className="text-white tracking-wide pb-2">
				By ensuring that your messages reach their intended recipients,
				you safeguard vital interactions and foster enduring brand
				loyalty.
			</p>
			<div className="py-4">
				<Logo></Logo>
			</div>
		</div>
	);
};

export default LeftSection;
