import { ApiService } from "src/network/apiService";

import {
  APNSCertificateType,
  MobileAppModel,
} from "src/types/models/apiModels";

export class NewMobileAppViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async validateMobileApp(mobileApp: MobileAppModel): Promise<string[]> {
    const errors: string[] = [];
    if (mobileApp.androidAppSettings) {
      if (
        !mobileApp.androidAppSettings.certificateFile ||
        !mobileApp.androidAppSettings.certificateFile?.signedUrl
      ) {
        errors.push(
          "certificateFile is required field in androidAppSettings with key and version properties"
        );
      }

      if (!mobileApp.androidAppSettings.packageName) {
        errors.push("packageName is a required field in androidAppSettings");
      }
    }
    if (mobileApp.iOSAppSettings) {
      if (
        !mobileApp.iOSAppSettings.certificateFile ||
        !mobileApp.iOSAppSettings.certificateFile.signedUrl
      ) {
        errors.push(
          "certificateFile is required field in androidAppSettings with key and version properties"
        );
      }

      if (!mobileApp.iOSAppSettings.bundleId) {
        errors.push("bundleId is a required field in iOSAppSettings");
      }
    }
    if (
      mobileApp &&
      mobileApp.iOSAppSettings &&
      !mobileApp.iOSAppSettings.certificateType
    ) {
      errors.push("certificateType is a required field");
    }
    if (
      mobileApp &&
      mobileApp.iOSAppSettings &&
      mobileApp.iOSAppSettings.certificateType === APNSCertificateType.P8
    ) {
      if (!mobileApp.iOSAppSettings.p8FileSettings) {
        errors.push(
          "p8FileSettings is a required field in iOSAppSettings when certificate type is P8"
        );
      }
      if (
        mobileApp.iOSAppSettings.p8FileSettings &&
        !mobileApp.iOSAppSettings.p8FileSettings.applePlatformBundleID
      ) {
        errors.push(
          "applePlatformBundleID is a required field in p8FileSettings"
        );
      }
      if (
        mobileApp.iOSAppSettings.p8FileSettings &&
        !mobileApp.iOSAppSettings.p8FileSettings.applePlatformKeyID
      ) {
        errors.push("applePlatformKeyID is a required field in p8FileSettings");
      }
      if (
        mobileApp.iOSAppSettings.p8FileSettings &&
        !mobileApp.iOSAppSettings.p8FileSettings.applePlatformTeamID
      ) {
        errors.push(
          "applePlatformTeamID is a required field in p8FileSettings"
        );
      }
    }
    if (
      mobileApp &&
      mobileApp.iOSAppSettings &&
      mobileApp.iOSAppSettings.certificateType === APNSCertificateType.P12
    ) {
      if (!mobileApp.iOSAppSettings.p12FileSettings) {
        errors.push(
          "p12FileSettings is a required field in iOSAppSettings when certificate type is P8"
        );
      }
      if (
        mobileApp.iOSAppSettings.p12FileSettings &&
        !mobileApp.iOSAppSettings.p12FileSettings.p12Password
      ) {
        errors.push("p12Password is a required field in p12FileSettings");
      }
    }
    return errors;
  }

  async saveMobileApp(
    mobileApp: MobileAppModel
  ): Promise<{ result: boolean; error?: string }> {
    const input = {
      name: mobileApp.name,
      iOSAppSettings: mobileApp.iOSAppSettings,
      androidAppSettings: mobileApp.androidAppSettings,
    };

    try {
      const response: any = await this.apiService.postAsync(
        "/onboard/branding-app",
        input
      );

      if (response) {
        if (response.scResponse && response.scResponse.code === 2000) {
          return { result: true };
        } else {
          return { result: false, error: response.scResponse?.message };
        }
      }
    } catch (error) {
      return {
        result: false,
        error: "There was an error performing the operation",
      };
    }

    return {
      result: true,
      error: "There was an error performing the operation",
    };
  }
}
