import React, { useEffect, useRef, useState } from "react";
import {
	Alert,
	Avatar,
	Box,
	Dialog,
	DialogContent,
	ListItemAvatar,
} from "@mui/material";
import Banner from "src/components/molecules/banner/Banner";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import AddOutgoingNumber from "../add-outgoing-number/add-outgoing-number";
import { BusinessNumbersProps } from "../business-numbers/business-numbers.props";
import BusinessList from "../business-list/business-list";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import { NewBusinessNumberViewModel } from "./number-viewmodel";
import { useAuth } from "../auth/auth-context";
import {
	BusinessListModel,
	IFileInfo,
	NewNumberModel,
	ServiceStatus,
} from "src/types/models/apiModels";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import ButtonPro from "src/components/atoms/primary-button/primary-button";
import StatusBadge from "src/components/molecules/status-badge/status-badge";

const Numbers: React.FC<BusinessNumbersProps> = ({ editNumbers }) => {
	const { user } = useAuth();
	const viewModel = useRef<NewBusinessNumberViewModel>();
	const [loading, setLoading] = useState(false);
	const [business, setBusiness] = useState<BusinessListModel | undefined>(
		undefined
	);
	const [businessId, setBusinessId] = useState<string>("");
	const [selectBusinessDialogOpen, setSelectBusinessDialogOpen] =
		useState<boolean>(false);
	const [businessNumbers, setBusinessNumbers] = useState<NewNumberModel[]>(
		editNumbers ?? []
	);
	const [addNumberDialogOpen, setAddNumberDialogOpen] =
		useState<boolean>(false);
	const [numberAddError, setNumberAddError] = useState("");
	const [showNumberAddingError, setShowNumberAddingError] = useState(false);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new NewBusinessNumberViewModel(
				user?.accessToken
			);
		}
	}, []);

	const fetchData = async () => {
		if (businessId && viewModel.current) {
			setLoading(true);
			setNumberAddError("");
			setShowNumberAddingError(false);
			const data = await viewModel.current.getNumbers(businessId);
			if (data) {
				setBusinessNumbers(data);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		fetchData(); // Call the async function inside useEffect
	}, [businessId]); // Dependency array ensures this runs when businessId changes

	const toggleBusinessDialog = () => {
		console.log("In toggleBusinessDialog");
		setSelectBusinessDialogOpen((prevValue) => !prevValue);
	};

	const toggleDialog = () => {
		setAddNumberDialogOpen((prevValue) => !prevValue);
	};

	function handleOnSelectBusiness(business: BusinessListModel): void {
		setBusiness(business);
		setBusinessId(business.businessId);
		toggleBusinessDialog();
	}

	function showBusinessList(): void {
		toggleBusinessDialog();
	}

	function handleOnAddBusiness(): void {
		throw new Error("Function not implemented.");
	}

	const addNumber = (number: string, fileInfo: IFileInfo) => {
		console.log("In handleAddNumberCallback");
		setShowNumberAddingError(false);
		setNumberAddError("");

		setBusinessNumbers((prevNumbers) => {
			// Check if the number already exists in the list
			const numberExists = prevNumbers.some(
				(num) => num.number === number
			);

			if (numberExists) {
				// console.log("Number already exists. Update rejected.");
				setShowNumberAddingError(true);
				setNumberAddError("Number already exists. Update rejected.");
				// You can add more logic here, like showing an error message
				return prevNumbers; // Return the existing numbers without adding
			}

			const updatedNumbers = [
				...prevNumbers,
				{
					number: number,
					ownershipDocuments: [fileInfo],
					status: "Added" as ServiceStatus,
				},
			];

			return updatedNumbers;
		});

		setAddNumberDialogOpen(false);
	};

	const handleAddNewNumberClick = () => {
		setNumberAddError("");
		setShowNumberAddingError(false);
		setAddNumberDialogOpen(true);
	};

	const handleSubmit = async () => {
		// Filter the business numbers with status "New"
		const newBusinessNumbers = businessNumbers.filter(
			(number) => number.status === "Added"
		);

		// Ensure that business and businessId exist
		if (business && business.businessId) {
			setLoading(true);
			const result = await viewModel.current?.submitBusinessNumbers(
				business.businessId,
				newBusinessNumbers
			);

			if (result && result.result) {
				const failedNumbers = result.result.businessNumbers.filter(
					(number) => number.success === false
				);

				if (failedNumbers.length > 0) {
					let errorString = "";
					failedNumbers.map((failedNumber) => {
						errorString = errorString + " " + failedNumber.message;
					});

					setNumberAddError(errorString);
					setShowNumberAddingError(true);
				}
			}

			console.log(JSON.stringify(result));

			if (result) {
				const data = await viewModel.current?.getNumbers(businessId);
				if (data) {
					setBusinessNumbers(data);
				}
			}
			setLoading(false);
			// console.log(`Result of the operation = ${JSON.stringify(result)}`);
		}
	};

	const handleRemoveNumber = async (number: string) => {
		const updatedItems = businessNumbers.filter(
			(item) => item.number !== number
		);

		setBusinessNumbers(updatedItems);
	};

	return (
		<>
			<Banner label="NUMBER LIST">
				<div className="bg-white p-6 rounded-lg drop-shadow-2xl">
					{business && (
						<div className="flex items-center justify-start w-full p-2">
							<span className="font-semibold text-md uppercase">
								Select Business
							</span>
						</div>
					)}
					<SCLabel color="#000">
						{business ? (
							<span
								onClick={showBusinessList}
								className="flex bg-gradient-to-r from-slate-600 to-slate-300 p-3 rounded-xl"
							>
								{business.logo && (
									<ListItemAvatar>
										{business.logo.signedUrl && (
											<Avatar
												sx={{
													width: "75px",
													height: "75px",
													marginRight: 2,
												}}
												src={business.logo.signedUrl}
											/>
										)}
									</ListItemAvatar>
								)}
								<div className="flex flex-col items-start justify-center gap-2">
									<div className="w-full flex items-center justify-start gap-2 text-white">
										<span className="text-lg font-bold ">
											{business.name}
										</span>
										<span className="text-sm font-bold">
											({business.businessCode})
										</span>
									</div>

									<StatusBadge
										status={business.status}
									></StatusBadge>
								</div>
							</span>
						) : (
							<button
								onClick={showBusinessList}
								style={{
									display: "flex",
									cursor: "pointer",
									color: "blue",
									background: "none",
									border: "none",
									textDecoration: "underline",
								}}
							>
								<SCLabel color="">Select a business</SCLabel>
							</button>
						)}
					</SCLabel>
					<br></br>
					<div className="overflow-x-auto w-full">
						<table className="min-w-[650px]  w-full border-collapse border border-gray-300">
							{/* Table Header */}
							<thead className="bg-gradient-to-t from-sky-700 to-sky-950 text-white">
								<tr>
									<th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold">
										OUTGOING NO.
									</th>
									<th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold">
										PROOF OF OWNERSHIP
									</th>
									<th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold">
										STATUS
									</th>
									<th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold">
										ACTION
									</th>
								</tr>
							</thead>
							{/* Table Body */}
							<tbody>
								{businessNumbers.map((row, index) => (
									<tr
										key={index}
										className="hover:bg-gray-50"
									>
										{/* Outgoing Number */}
										<td className="border border-gray-300 px-4 py-2 text-center text-black">
											<span className="text-black text-md font-semibold">
												{row.number}
											</span>
										</td>
										{/* Proof of Ownership */}
										<td className="border border-gray-300 px-4 py-2 text-center">
											<div className="flex justify-center gap-2 items-center">
												<button
													className="text-blue-600 hover:text-blue-800 focus:outline-none"
													aria-label="Download Proof"
													onClick={() => {
														const url =
															row
																.ownershipDocuments[0]
																.signedUrl;
														if (url) {
															const anchor =
																document.createElement(
																	"a"
																);
															anchor.href = url;
															anchor.target =
																"_blank";
															anchor.download =
																""; // Optional: Add a specific filename if needed
															document.body.appendChild(
																anchor
															);
															anchor.click();
															document.body.removeChild(
																anchor
															);
														} else {
															console.error(
																"URL is not available."
															);
														}
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														fill="currentColor"
														className="size-6 text-gray-800"
													>
														<path d="M12 1.5a.75.75 0 0 1 .75.75V7.5h-1.5V2.25A.75.75 0 0 1 12 1.5ZM11.25 7.5v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h3.75Z" />
													</svg>
												</button>
											</div>
										</td>
										{/* Status */}
										<td className="border border-gray-300 px-4 py-2 text-center">
											<StatusBadge
												status={row.status}
											></StatusBadge>
										</td>
										{/* Action */}
										<td className="border border-gray-300 px-4 py-2 text-center">
											{row.status === "Added" ? (
												<button
													className="text-red-600 hover:text-red-800 focus:outline-none"
													onClick={() =>
														handleRemoveNumber(
															row.number
														)
													}
													aria-label="Remove Number"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="w-5 h-5"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M6 18L18 6M6 6l12 12"
														/>
													</svg>
												</button>
											) : (
												<div className="w-full flex items-center justify-center">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														fill="currentColor"
														className="size-6"
													>
														<path
															fillRule="evenodd"
															d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
															clipRule="evenodd"
														/>
													</svg>
												</div>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{showNumberAddingError && (
						<>
							<br></br>
							<Alert severity="error">{numberAddError}</Alert>
						</>
					)}

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: 2,
							marginBottom: 2,
						}}
					>
						<ButtonAddNew
							disabled={!business}
							onClick={handleAddNewNumberClick}
						></ButtonAddNew>
					</Box>
					<ButtonPro
						label={"SUBMIT"}
						onClick={handleSubmit}
						disabled={
							!business ||
							businessNumbers.filter(
								(number) => number.status === "Added"
							).length === 0
						}
					></ButtonPro>
				</div>
			</Banner>
			{/* loader */}
			<LoadingModal open={loading}></LoadingModal>
			{/* Add number popup */}
			<Dialog
				open={addNumberDialogOpen}
				onClose={toggleDialog}
				maxWidth="sm"
				fullWidth
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
			>
				<DialogContent>
					<AddOutgoingNumber onAdd={addNumber}></AddOutgoingNumber>
				</DialogContent>
			</Dialog>
			{/* Select business pop-up */}
			<Dialog
				open={selectBusinessDialogOpen}
				onClose={toggleBusinessDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectBusiness}
						selectMode={true}
						onClose={toggleBusinessDialog}
					></BusinessList>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default Numbers;
