import React, { useEffect, useRef, useState } from "react";
import { BusinessListProps } from "./business-list.props";
import { BusinessListViewModel } from "./business-list.view-model";
import { useAuth } from "../auth/auth-context";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import EntityList from "../entity-list/entity-list";
import { BusinessListModel } from "src/types/models/apiModels";
import StatusBadge from "src/components/molecules/status-badge/status-badge";

const BusinessList: React.FC<BusinessListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
	onClose,
	withStatuses,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<BusinessListModel[]>([]);
	const viewModel = useRef<BusinessListViewModel | null>(null);
	const [selectedBusiness, setSelectedBusiness] = useState<string | null>(
		null
	);

	const handleSelectBusiness = (business: BusinessListModel) => {
		setSelectedBusiness(business.businessId);
		if (onSelect) {
			onSelect(business);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData", response);

					if (withStatuses) {
						const filteredListOfBusiness = response.filter(
							(business) => withStatuses.includes(business.status)
						);

						setData(() => filteredListOfBusiness);
					} else {
						setData(() => response);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
			}
		};

		fetchData();
	}, [user]);

	const renderBusinessItem = (business: BusinessListModel) => (
		<>
			<div className="flex items-center justify-center gap-4 w-full">
				<div className="flex-shrink-0">
					{business.logo?.signedUrl ? (
						<img
							className="w-12 h-12 rounded-full flex-shrink-0"
							src={business.logo.signedUrl}
							alt="Business Logo"
						/>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="size-12"
						>
							<path
								fillRule="evenodd"
								d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z"
								clipRule="evenodd"
							/>
						</svg>
					)}
				</div>
				<div className="flex items-start justify-between w-full">
					<div className="flex items-center justify-center gap-2">
						<span className="text-lg font-bold">
							{business.name}
						</span>
						<span className="text-sm font-semibold">
							({business.businessCode})
						</span>
					</div>
					<div className="rounded-full">
						<StatusBadge status={business.status}></StatusBadge>
					</div>
				</div>
			</div>
		</>
	);

	return (
		<div className="rounded-lg drop-shadow-2xl">
			<EntityList<BusinessListModel>
				data={data}
				selectMode={selectMode} // or false depending on your requirements
				onSelect={(selectedBusiness) =>
					handleSelectBusiness(selectedBusiness)
				}
				renderChildren={renderBusinessItem}
				onAddNewClick={onAddNewClick}
				listLabel={"Select Business"}
				onClose={onClose}
				filterText="Filter on business name"
				enableView={false}
				enableEdit={false}
				enableDelete={false}
			/>
		</div>
	);
};

export default BusinessList;
