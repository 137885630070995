import React, { useEffect, useState } from "react";
import { PhoneNumberFieldProps } from "./PhoneNumberFieldProps";
import {
	parsePhoneNumberFromString,
	isValidPhoneNumber,
} from "libphonenumber-js";
import CountryCodeToNameMap from "./countryCodeToNameMap";
import { IPhoneNumber } from "src/types/models/apiModels";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import { Error } from "src/components/atoms/error/error";

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
	phoneNumber,
	value,
	onChange,
	disabled = false,
	showCountry = true,
	showDialCode = false,
	...props
}) => {
	const [error, setError] = useState<string | null>(null);
	const [country, setCountry] = useState<string | null>(null);
	const [dialCode, setDialCode] = useState("");

	useEffect(() => {
		const fetchCountryCode = async () => {
			const country = await getCountryCode();
			if (country) {
				const dialCode = CountryCodeToNameMap[country]?.dialCode || "";
				setDialCode(dialCode);

				// Set the default value with the dial code if it's not already set
				if (!value) {
					onChange({ countryCode: dialCode, number: "" }, dialCode);
				}
			}
		};

		if (showDialCode) {
			fetchCountryCode();
		}
	}, []);

	// Sync phone number object with value when value changes
	useEffect(() => {
		if (value && !isValidPhoneNumber(value)) {
			setError("Please enter a valid phone number.");
			setCountry(null);
		} else {
			const phoneNumberData = parsePhoneNumberFromString(value);
			if (phoneNumberData) {
				const countryName =
					CountryCodeToNameMap[phoneNumberData.country || ""] || null;
				setCountry(countryName?.name || null);
				setError(null);
			}
		}
	}, [value]);

	async function getCountryCode() {
		try {
			const response = await fetch(
				"https://get.geojs.io/v1/ip/country.json"
			);
			const data = await response.json();
			return data.country; // Returns the country code (e.g., 'US', 'IN')
		} catch (error) {
			console.error("Error fetching country code:", error);
			return null;
		}
	}

	const handleChange = (e: string) => {
		let newValue = e;
		// Remove all unwanted characters (spaces, dashes, etc.)
		newValue = newValue.replace(/[^+\d]/g, "");

		// Ensure the value always starts with the default dial code
		if (newValue && !newValue.startsWith(dialCode)) {
			newValue = `${dialCode}${newValue.replace(/^\+/, "")}`;
		}
		if (newValue && !newValue.startsWith("+")) {
			newValue = `+${newValue}`;
		}
		try {
			const phoneNumber = parsePhoneNumberFromString(newValue);
			if (!phoneNumber || !isValidPhoneNumber(newValue)) {
				setError("Please enter a valid phone number.");
				setCountry(null);
				onChange(
					{
						countryCode: dialCode,
						number: newValue.replace(dialCode, ""),
					},
					newValue
				);
			} else {
				setError(null);
				const countryCode = phoneNumber.countryCallingCode || "";
				const number = phoneNumber.nationalNumber || newValue;
				const countryName =
					CountryCodeToNameMap[phoneNumber.country || ""] || null;
				setCountry(countryName?.name || null);

				// Return an IPhoneNumber object instead of just a string
				const phoneNumberObject: IPhoneNumber = {
					countryCode: `+${countryCode}`,
					number,
				};
				onChange(phoneNumberObject, newValue);
			}
		} catch (err) {
			setError("Please enter a valid phone number.");
			setCountry(null);
			onChange(
				{
					countryCode: dialCode,
					number: newValue.replace(dialCode, ""),
				},
				newValue
			);
		}
	};

	return (
		<div className="flex flex-col items-start gap-2 w-full">
			<InputBox
				value={value || dialCode}
				label={"Phone Number"}
				type={""}
				onChange={(e) => handleChange(e)}
			></InputBox>
			{error && <Error error={error}></Error>}
			{showCountry && (
				<label className="text-blue-600 text-md left-0 font-semibold">
					{country}
				</label>
			)}
		</div>
		// <Box>
		//   <TextField
		//     {...props}
		//     value={value || dialCode} // Ensure the default dial code is shown by default
		//     onChange={handleChange}
		//     error={!!error} // Shows red border if error is not null
		//     helperText={error} // Shows error message if error is not null
		//     label="Phone Number"
		//     variant="filled"
		//     margin="normal"
		//     fullWidth
		//     required
		//     disabled={disabled}
		//     InputLabelProps={{
		//       sx: {
		//         textTransform: "uppercase", // This makes the label text all caps
		//       },
		//     }}
		//   />
		//   {showCountry && (
		//     <SCLabel align="left" fontSize={12} color="blue">
		//       {country}
		//     </SCLabel>
		//   )}
		// </Box>
	);
};

export default PhoneNumberField;
