import React from "react";
import { text } from "stream/consumers";

interface IButtonWithIcon {
	onClick: () => void;
	disabled?: boolean;
	icon: React.ReactNode;
	text?: string;
	className?: string; // Optional additional classes
}

const ButtonWithIcon: React.FC<IButtonWithIcon> = ({
	onClick,
	disabled = false,
	icon,
	text,
	className = "",
}) => {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={` flex items-center justify-center p-2 rounded ${
				disabled
					? "text-gray-400 cursor-not-allowed"
					: "text-gray-800 hover:text-blue-600"
			} ${className}`}
			aria-disabled={disabled} // For accessibility
		>
			<div className="flex items-center justify-center gap-2">
				{icon}
				{text && (
					<span className="font-semibold text-sm text-gray-800">
						{text}
					</span>
				)}
			</div>
		</button>
	);
};

export default ButtonWithIcon;
