import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import Banner from "src/components/molecules/banner/Banner";
import ImageUploader from "src/components/molecules/imageUploader/imageUploader";
import PhoneNumberField from "src/components/molecules/phoneNumberField/PhoneNumberField";
import CallAlert from "src/components/molecules/callAlerts/callAlert";
import { ConfigureBrandingProps } from "./configureBranding.props";
import PhoneComponent from "src/components/molecules/phoneComponent/phoneComponent";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import AndroidPhoneComponent from "src/components/molecules/phoneComponent/androidPhone";
import { title } from "process";
import { IFileInfo, IPhoneNumber } from "src/types/models/apiModels";

const ConfigureBranding: React.FC<ConfigureBrandingProps> = (props) => {
  const defaultImage = "https://via.placeholder.com/150";
  const [number, setNumber] = useState<IPhoneNumber>({
    countryCode: "",
    number: "",
  });
  const [outgoingNumber, setOutgoingNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState(defaultImage);
  const [acceptsIncomingCall, setAcceptsIncomingCall] = useState(false);
  const [ttl, setTtl] = useState("IMMEDIATE");
  const [department, setDepartment] = useState("SUPPORT");
  const [intent, setIntent] = useState("");

  useEffect(() => {
    console.log("ConfigureBranding - selected image updated");
  }, [selectedImage]);

  useEffect(() => {
    if (props.image) {
      setSelectedImage(props.image);
    }
    setIntent(props.defaultIntent);
  }, [props.image, props.defaultIntent]);

  const handleImageUpload = async (image: IFileInfo | undefined) => {
    setSelectedImage(image?.signedUrl ?? defaultImage);
  };

  useEffect(() => {
    console.log("ConfigureBranding - selected image updated");
  }, [selectedImage]); // The dependency array contains `count`, so it triggers when `count` changes

  useEffect(() => {
    if (props.image) {
      console.log(`Got image in configuration component`);
      setSelectedImage(props.image);
    }
    setIntent(() => props.defaultIntent);
  }, []); // The dependency array contains `count`, so it triggers when `count` changes

  useEffect(() => {
    console.log("ConfigureBranding - selected image updated");
  }, [selectedImage]);

  useEffect(() => {
    if (props.image) {
      setSelectedImage(props.image);
    }
    setIntent(props.defaultIntent);
  }, [props.image, props.defaultIntent]);

  const handleSubmit = async () => {
    // Handle form submission logic here
  };

  return (
    <Banner label="BRAND OUTGOING NUMBER">
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          xs={4}
          md={3}
          lg={4}
          container
          direction="column"
          justifyContent="space-between" // Space the items equally
          alignItems="center"
          spacing={2}
          style={{ height: "100%" }} // Ensure the height is 100%
        >
          <Grid
            item
            xs={12}
            alignContent="center"
            justifyContent="center"
            style={{ width: "100%" }}
          >
            <ImageUploader
              image={undefined}
              onUploadSuccess={handleImageUpload}
              borderRadius="50%"
            />
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <PhoneNumberField
              phoneNumber={number}
              value={props.number}
              onChange={(phoneNumber, value) => setOutgoingNumber(value)}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptsIncomingCall}
                  onChange={(e) => setAcceptsIncomingCall(e.target.checked)}
                />
              }
              label="Accepts Incoming Call"
            />
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl component="fieldset">
              <SCLabel align="left" color="#000000">
                DEFAULT TTL
              </SCLabel>
              <RadioGroup
                row
                value={ttl}
                onChange={(e) => setTtl(e.target.value)}
              >
                <FormControlLabel
                  value="IMMEDIATE"
                  control={<Radio />}
                  label="IMMEDIATE"
                />
                <FormControlLabel
                  value="5 MINS"
                  control={<Radio />}
                  label="5 MINS"
                />
                <FormControlLabel
                  value="10 MINS"
                  control={<Radio />}
                  label="10 MINS"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <TextField
              variant="filled"
              label="Department"
              fullWidth
              required
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <TextField
              variant="filled"
              label="Default Intent"
              fullWidth
              required
              value={intent}
              onChange={(e) => setIntent(e.target.value)}
              inputProps={{ maxLength: 32 }}
            />
          </Grid>

          <Grid item xs={12} style={{ width: "100%" }}>
            <PrimaryButton
              variant="outlined"
              color="info"
              label="Submit"
              cssClassName="primary-button"
              onClick={handleSubmit}
              disabled={false}
            />
          </Grid>
        </Grid>

        <Grid item xs={8} md={9} lg={8}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="row"
            sx={{ height: "100%" }}
          >
            <Grid item xs={5}>
              <Box sx={{ marginBottom: "8px" }}>
                {/* <CallAlert
                  headerLabel="Incoming call alert"
                  title={props.businessName}
                  image={selectedImage}
                  body={"Incoming call scheduled..."}
                /> */}
              </Box>

              <PhoneComponent
                image={selectedImage}
                title={props.businessName}
                subtitle={intent}
              />
              <SCLabel color="#ff344">iPhone</SCLabel>
            </Grid>

            <Grid item xs={5} gap={1}>
              <Box sx={{ marginBottom: "8px" }}>
                {/* <CallAlert
                  headerLabel="Branding clear Alert"
                  
                  title={props.businessName}
                  image={selectedImage}
                  body={"Branding cleared"}
                /> */}
              </Box>
              <AndroidPhoneComponent
                image={selectedImage}
                title={props.businessName}
                subtitle={intent}
                borderRadius="24px"
              />
              <SCLabel color="#ff344">Android</SCLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Banner>
  );
};

export default ConfigureBranding;
