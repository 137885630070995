import { useState } from "react";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import SelectableListItem from "src/components/molecules/selectable-list-item/selectable-list-item";
import HelpText from "src/components/atoms/help-text/help-text";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";

interface EntityListProps<T> {
	data: T[];
	selectMode?: boolean;
	helpText?: string[];
	listLabel: string;
	onAddNewClick: () => void;
	onSelect?: (item: T) => void;
	onClose?: () => void;
	renderChildren: (item: T) => React.ReactNode;
	filterText?: string;
	enableView?: boolean;
	enableEdit?: boolean;
	enableDelete?: boolean;
}

const EntityList = <T,>({
	data,
	selectMode,
	helpText,
	listLabel,
	onAddNewClick,
	onSelect,
	renderChildren,
	onClose,
	filterText = "Filter",
	enableView = true,
	enableEdit = true,
	enableDelete = true,
}: EntityListProps<T>) => {
	const [selectedEntity, setSelectedEntity] = useState<T | null>(null);
	const [filterValue, setFilterValue] = useState(""); // State for filter input
	const filteredData = data.filter((item) =>
		JSON.stringify(item).toLowerCase().includes(filterValue.toLowerCase())
	);

	const handleSelectItem = (item: T) => {
		if (selectMode) {
			setSelectedEntity(item);
		}
		if (onSelect) {
			// console.log(`on select in EntityList ${JSON.stringify(item)}`);
			onSelect(item);
		}
	};

	const handleClose = () => {
		if (onClose) {
			if (onSelect && selectedEntity) {
				onSelect(selectedEntity);
			}
			onClose();
		}
	};

	return (
		<>
			{/* main div */}
			<div className="bg-white w-full overflow-auto p-6 rounded-lg">
				<div className="w-full">
					<div
						className={`w-full flex items-center justify-end gap-4 ${selectMode ? "" : "pb-6"}`}
					>
						{/* refresh button */}
						{/* <button
							className="p-2 drop-shadow-lg flex items-center justify-center gap-2 bg-white border-b-4 border-blue-400 text-blue-700"
							onClick={() => console.log("refresh here")}
						>
							<div className="drop-shadow-lg flex items-center justify-center gap-2 ">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-6 "
								>
									<path
										fillRule="evenodd"
										d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
						</button> */}
						{!selectMode && (
							<ButtonAddNew
								onClick={onAddNewClick}
							></ButtonAddNew>
						)}
					</div>

					{selectMode && (
						<div className="pb-4">
							<span className="text-gray-800 min-h-12 flex items-center justify-center uppercase drop-shadow-lg text-2xl tracking-wider font-semibold border-b-4 border-orange-600 w-full p-2">
								{listLabel}
							</span>
						</div>
					)}
					<div className="w-full flex flex-col items-center justify-center gap-2">
						{/* InputBox for filtering */}
						<div className="w-full flex items-center justify-center pb-6">
							<InputBox
								label={filterText}
								type={"text"}
								value={filterValue} // Bind the state to the input box
								onChange={(value: string) => {
									setFilterValue(value); // Update the state when input changes
								}}
							/>
						</div>

						{/* Render filtered data */}
						{filteredData.map((item, index) => (
							<SelectableListItem
								key={index} // Adjust the key as needed based on T's properties
								item={item}
								selectMode={selectMode ?? false}
								selected={selectedEntity === item}
								onSelect={() => handleSelectItem(item)}
								enableView={enableView}
								enableEdit={enableEdit}
								enableDelete={enableDelete}
							>
								{renderChildren(item)}
							</SelectableListItem>
						))}

						{/* Display message if no results match the filter */}
						{filteredData.length === 0 && (
							<div className="text-gray-500 font-semibold">
								No results found
							</div>
						)}
					</div>

					{selectMode && (
						<div className="flex items-center justify-center gap-2 py-4">
							<ButtonPro
								label={"OK"}
								onClick={handleClose}
							></ButtonPro>
							<ButtonPro
								variant={ButtonVariant.Secondary}
								label={"close"}
								onClick={handleClose}
							></ButtonPro>
						</div>
					)}
				</div>

				{data.length === 0 && helpText && helpText.length > 0 && (
					<HelpText help={helpText} alignment="center" />
				)}
			</div>
		</>
	);
};

export default EntityList;
