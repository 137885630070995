import { ApiService } from "src/network/apiService";
import { InviteUserModel } from "../invite-user/invite-user.view-model";

export class UserManagementViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async inviteUsers(
    businessId: string,
    user: InviteUserModel
  ): Promise<{ result: boolean; error?: string }> {
    const input = {
      countryCode: "AU",
      contacts: user.contacts,
    };
    console.log("####input", input);
    try {
      const response: any = await this.apiService.postAsync(
        `/onboard/business/${businessId}/contacts`,
        input
      );

      if (response) {
        if (response.scResponse && response.scResponse.code === 2000) {
          return { result: true };
        } else {
          return { result: false, error: response.scResponse?.message };
        }
      }
    } catch (error) {
      return {
        result: false,
        error: "There was an error performing the operation",
      };
    }

    return {
      result: true,
      error: "There was an error performing the operation",
    };
  }
}
