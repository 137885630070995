import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

import { ErrorPopupProps } from "./error-popup.props";
import "./error-popup.css";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const ErrorPopup: React.FC<ErrorPopupProps> = ({ open, onClose, message }) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {"Processing error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An error occurred while processing your request. Please try again
            later. If the problem persists, contact support for assistance.
            <SCLabel
              fontWeight="regular"
              textTransform="none"
              align="left"
              color="#000"
              marginTop={3}
            >
              {message}
            </SCLabel>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ErrorPopup;
