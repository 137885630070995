import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";

interface StatisticsCardProps {
  value: string;
  title: string;
  isLoading: boolean;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  value,
  title,
  isLoading = false,
}) => {
  return (
    <Grid item xs={12} md={3}>
      <Card
        sx={{
          width: "300px",
          height: "230px",
          backgroundColor: "#2F8ECC", // Matching top background color from the image
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#2F8ECC", // Same background color
            height: "70%", // Adjusted for a better match
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <Typography
              sx={{
                fontSize: "80px",
                fontWeight: "bold",
                color: "#FFFFFF", // White color for number
                lineHeight: 1,
              }}
            >
              {value}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: "#003060", // Matching bottom black background
            height: "30%", // Adjusted height for label section
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#FFFFFF", // White text color
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
};

export default StatisticsCard;
