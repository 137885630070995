import { ApiService } from "src/network/apiService";
import { BusinessNumberModel } from "src/types/models/apiModels";

export class BrandingConfigurationViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async saveDataAsync(name: string): Promise<boolean> {
    // const response = await this.apiService.postAsync('/onboard/upload-file', name);

    // if (response) {
    //     console.log(`Response -> ${JSON.stringify(response)}`);
    //     return true;
    // }

    console.log(`Name -> ${name}`);

    return false;
  }

  async fetchNumber(
    businessId: string,
    number: string
  ): Promise<BusinessNumberModel | undefined> {
    const response = await this.apiService.getAsync<any>(
      `/onboard/business/${businessId}/number/${number}`
    );

    if (response) {
      // console.log(`/onboard/business/${businessId}/number/${number}`, response);
      if (response.scResponse && response.scResponse.code === 2000) {
        return response.scResponse.data;
      }

      return undefined;
    }

    return undefined;
  }
}
