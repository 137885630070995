import { useCallback, useState } from "react";

import { Box } from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";
import { BusinessTrialUsersProps } from "./business-trial-users.props";
import BusinessTrialUsersList from "../business-trial-users-list/business-trial-users-list";
import BusinessTrialUsersAddNew from "../business-trial-users-add-new/business-trial-users-add-new";
import { TrialUsersModel } from "src/types/models/apiModels";

const BusinessTrialUsers: React.FC<BusinessTrialUsersProps> = ({
  openInEditMode,
}) => {
  const [editMode, setEditMode] = useState<boolean>(openInEditMode ?? false);

  {
    console.log("Current Edit Mode: ", editMode);
  }

  const handleSave = useCallback((trialUser: TrialUsersModel) => {
    console.log("submitContactCentreCallBack!", trialUser);
    toggleEditMode();
  }, []);

  const handleOnCancel = useCallback(() => {
    console.log("cancelBusinessCallBack!");
    toggleEditMode();
  }, []);

  const handleOnAdd = useCallback(() => {
    console.log("cancelBusinessCallBack!");
    toggleEditMode();
  }, []);

  const toggleEditMode = () => {
    console.log("In toggleEditMode");
    setEditMode((prevValue) => !prevValue);
  };

  // Functions to handle opening and closing the dialog

  return (
    <Box
      className="main-content"
      sx={{
        flexGrow: 1,
        padding: 3,
        marginLeft: `10px`,
        transition: "margin-left 0.3s ease",
      }}
    >
      <Banner label="Contact Centre">
        <Box>
          <BusinessTrialUsersAddNew
            onSave={handleSave}
            onCancel={handleOnCancel}
          ></BusinessTrialUsersAddNew>
          <BusinessTrialUsersList
            onAddNewClick={handleOnAdd}
          ></BusinessTrialUsersList>
        </Box>
      </Banner>
    </Box>
  );
};

export default BusinessTrialUsers;
