// src/services/clipboardService.ts

export const ClipboardService = {
    copyToClipboard: async (text: string): Promise<void> => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    },
};