interface CopyRightProps {
	textColor?: string; // Optional prop for text color
}

const CopyRight: React.FC<CopyRightProps> = ({ textColor = "text-white" }) => {
	return (
		<span className={`${textColor} text-sm`}>
			© 2024 Expertstack Pty Ltd | All Rights Reserved.
		</span>
	);
};

export default CopyRight;
