import React, { useEffect, useRef, useState } from "react";

import UploadFile from "../upload-file/upload-file";
import { NewMobileAppProps } from "./mobile-app-add-new.props";
import { NewMobileAppViewModel } from "./mobile-app-add-new.view-model";
import { useAuth } from "../auth/auth-context";
import ErrorPopup from "../error-popup/error-popup";
import HelpText from "src/components/atoms/help-text/help-text";
import {
	APNSCertificateType,
	IFileInfo,
	MobileAppModel,
} from "src/types/models/apiModels";
import CheckBox from "src/components/molecules/check-box/check-box";
import RadioGroup from "src/components/molecules/radio-button-group/radio-button-groups";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import ActionPanel from "../action-panel/action-panel";

const NewMobileApp: React.FC<NewMobileAppProps> = ({
	mobileApp,
	onCancel,
	onSave,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook

	type CertificateFileFormat = "P8" | "P12";
	const [showAPN, setShowAPN] = useState(true);
	const [showFCM, setShowFCM] = useState(true);
	const [iosCertFileFormat, setIOSCertFileFormat] =
		useState<CertificateFileFormat>("P8");
	const [iosCertFile, setIOSCertFile] = useState<IFileInfo | undefined>(
		undefined
	);
	const [androidCertFile, setAndroidCertFile] = useState<
		IFileInfo | undefined
	>(undefined);
	const [name, setName] = useState<string>("");
	const [bundleId, setBundleId] = useState<string>("");
	const [applePlatformKeyID, setApplePlatformKeyID] = useState<string>("");
	const [applePlatformTeamID, setApplePlatformTeamID] = useState<string>("");
	const [p12Password, setP12Password] = useState<string | undefined>(
		undefined
	);
	const [packageName, setPackageName] = useState<string>("");
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [mode, setMode] = useState("New");
	const viewModel = useRef<NewMobileAppViewModel | null>(null);

	useEffect(() => {
		if (!mobileApp) return;

		setMode("Edit");
		setName(mobileApp.name);

		const { iOSAppSettings, androidAppSettings } = mobileApp;

		if (iOSAppSettings) {
			setShowAPN(true);
			setBundleId(iOSAppSettings.bundleId);

			const { p12FileSettings, p8FileSettings } = iOSAppSettings;

			if (p12FileSettings) {
				setIOSCertFileFormat(APNSCertificateType.P12);
				setP12Password(p12FileSettings.p12Password);
			} else if (p8FileSettings) {
				setIOSCertFileFormat(APNSCertificateType.P8);
				setApplePlatformKeyID(p8FileSettings.applePlatformKeyID);
				setApplePlatformTeamID(p8FileSettings.applePlatformTeamID);
			}
		}

		if (androidAppSettings) {
			setShowFCM(true);
			setPackageName(androidAppSettings.packageName);
		}
	}, [mobileApp]);

	useEffect(() => {
		if (user?.accessToken) {
			viewModel.current = new NewMobileAppViewModel(user.accessToken);
		}
	}, [user]);

	const onIOSCertUploadSuccess = (fileInfo: IFileInfo | undefined) => {
		if (fileInfo) {
			setIOSCertFile(fileInfo);
		}
	};

	const onAndroidCertUploadSuccess = (fileInfo: IFileInfo | undefined) => {
		if (fileInfo) {
			setAndroidCertFile(fileInfo);
		}
	};

	const onIOSCertClear = () => {
		setIOSCertFile(undefined);
	};

	const onAndroidCertClear = () => {
		setAndroidCertFile(undefined);
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleBackToList = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleRadioChange = (selectedOption: {
		label: string;
		value: string;
	}) => {
		if (selectedOption.value === "P8" || selectedOption.value === "P12") {
			setIOSCertFileFormat(selectedOption.value as CertificateFileFormat);
		} else {
			console.error("Invalid certificate file format selected.");
		}
	};

	async function handleSubmit(): Promise<void> {
		if (!name) {
			return;
		}

		const mobileApp: MobileAppModel = {
			id: "",
			code: "",
			name: name,
			iOSAppSettings:
				bundleId && iosCertFile
					? {
							bundleId: bundleId,
							certificateType:
								iosCertFileFormat === "P8"
									? APNSCertificateType.P8
									: APNSCertificateType.P12,
							certificateFile: iosCertFile,
							p8FileSettings:
								iosCertFileFormat === "P8"
									? {
											applePlatformTeamID:
												applePlatformTeamID ?? "",
											applePlatformKeyID:
												applePlatformKeyID ?? "",
											applePlatformBundleID:
												bundleId ?? "",
										}
									: undefined,
							p12FileSettings:
								iosCertFileFormat === "P12"
									? { p12Password: p12Password ?? "" }
									: undefined,
						}
					: undefined,
			androidAppSettings:
				packageName && androidCertFile
					? {
							packageName: packageName,
							certificateFile: androidCertFile,
						}
					: undefined,
			bundleId: bundleId,
			packageName: packageName,
		};

		if (mobileApp) {
			const errors =
				await viewModel.current?.validateMobileApp(mobileApp);
			if (errors && errors.length > 0) {
				setError(() => errors.join(","));
				toggleError(true);
			} else {
				const response =
					await viewModel.current?.saveMobileApp(mobileApp);
				if (response?.result === true) {
					if (onSave) {
						onSave(mobileApp);
					}
				} else {
					setError(() => response?.error ?? "");
					toggleError(true);
				}
			}
		}
	}

	return (
		<>
			{/* Help Text */}
			<HelpText
				help={[
					"You have the option to register an iOS app, an Android app, or both.",
					"To proceed, select the appropriate checkboxes below. Based on your selection, you will be prompted to provide the necessary details for Apple Push Notification (APN) or Firebase Cloud Messaging (FCM). This will ensure that your applications are properly configured for branding the calls",
				]}
				alignment="center"
			/>

			{/* Checkboxes to control section visibility */}
			<div className="flex flex-col items-center justify-start gap-12 w-full mt-12">
				<InputBox
					label="Mobile App Name"
					value={name}
					type={"text"}
					onChange={function (value: string): void {
						setName(value);
					}}
					disabled={mode === "Edit"}
				></InputBox>

				<div className="flex flex-col items-start justify-start gap-4 w-full">
					<span className="font-semibold text-lg uppercase">
						Supported Platforms
					</span>
					<div className="flex items-center justify-start gap-12 w-full">
						<CheckBox
							title={"iOS App"}
							onChange={function (value: boolean): void {
								setShowAPN(value);
							}}
							checked={showAPN}
						></CheckBox>

						<CheckBox
							title={"Android App"}
							onChange={function (value: boolean): void {
								setShowFCM(value);
							}}
							checked={showFCM}
						></CheckBox>
					</div>
				</div>
				<div className="flex items-start justify-start w-full h-full gap-6">
					{showAPN && (
						<>
							<div className="flex flex-col items-start justify-center gap-4 w-full">
								<span className="font-semibold text-2xl uppercase">
									Apple Push Notification (APN)
								</span>

								<span className="font-semibold text-md">
									Choose Authentication Format
								</span>

								<RadioGroup
									options={[
										{ label: "P8", value: "P8" },
										{ label: "P12", value: "P12" },
									]}
									onChange={handleRadioChange}
								/>

								<UploadFile
									label="Upload credential file"
									onUploadSuccess={onIOSCertUploadSuccess}
									onClear={onIOSCertClear}
								/>

								<InputBox
									label="Bundle ID"
									value={bundleId}
									type={"text"}
									onChange={function (value: string): void {
										setBundleId(value);
									}}
									disabled={mode === "Edit"}
								></InputBox>

								{iosCertFileFormat === "P8" && (
									<>
										<InputBox
											label="Key ID"
											type={"text"}
											value={applePlatformKeyID}
											onChange={function (
												value: string
											): void {
												setApplePlatformKeyID(value);
											}}
										></InputBox>
										<InputBox
											label="Team ID"
											type={"text"}
											value={applePlatformTeamID}
											onChange={function (
												value: string
											): void {
												setApplePlatformTeamID(value);
											}}
										></InputBox>
									</>
								)}
								{iosCertFileFormat === "P12" && (
									<>
										<InputBox
											label={"Password (optional)"}
											type={"password"}
											value={p12Password}
											onChange={function (
												value: string
											): void {
												setP12Password(value);
												console.log(value);
											}}
										></InputBox>
									</>
								)}
							</div>
						</>
					)}

					{showFCM && (
						<>
							<div className="flex flex-col items-start justify-start gap-4 w-full h-full">
								<span className="font-semibold text-2xl uppercase">
									Firebase Cloud Messaging (FCM)
								</span>
								<div className="flex flex-col items-start justify-start w-full">
									<UploadFile
										label="Upload Service account key"
										onUploadSuccess={
											onAndroidCertUploadSuccess
										}
										onClear={onAndroidCertClear}
									/>
									<InputBox
										label="Package Name"
										value={packageName}
										type={"text"}
										onChange={function (
											value: string
										): void {
											setPackageName(value);
										}}
										disabled={
											packageName !== "" &&
											mode === "Edit"
										}
									></InputBox>
								</div>
							</div>
						</>
					)}
				</div>
				<ErrorPopup
					message={error}
					open={errorDialogOpen}
					onClose={closeError}
				></ErrorPopup>

				<ActionPanel
					primaryButtonLabel={"Submit"}
					primaryButtonEnabled={true}
					secondaryButtonLabel={"Cancel"}
					secondaryButtonEnabled={true}
					primaryButtonAction={handleSubmit}
					secondaryButtonAction={handleBackToList}
				></ActionPanel>
			</div>
		</>
	);
};

export default NewMobileApp;
