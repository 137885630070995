import { TextField } from "@mui/material";
import { InputProps } from "./input.props";
import "./input.css";

const Input: React.FC<InputProps> = (props) => {
  return (
    <>
      <TextField
        className="input-field"
        variant="filled"
        fullWidth
        label={props.label}
        required={props.required}
        value={props.value}
        onChange={(e) => props.onChange?.(e)}
        error={props.error} // Set the error state
        helperText={props.helpText} // Display the error message
        disabled={props.disabled}
        inputProps={props.maxLength ? { maxLength: props.maxLength } : {}} // Set the maximum length
      />
    </>
  );
};

export default Input;
