import React, { useEffect, useState } from "react";
import { BusinessNumberModel } from "src/types/models/apiModels";
import StatusBadge from "src/components/molecules/status-badge/status-badge";
import CheckBox from "src/components/molecules/check-box/check-box";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";

interface BusinessNumbersListProps {
	businessNumbers: BusinessNumberModel[];
	selectMode?: boolean;
	onAddNewClick: () => void;
	onSelect?: (business: BusinessNumberModel[]) => void;
	onClose: () => void;
}

const BusinessNumbersList: React.FC<BusinessNumbersListProps> = ({
	businessNumbers,
	onAddNewClick,
	selectMode,
	onSelect,
	onClose,
}) => {
	const [data, setData] = useState<BusinessNumberModel[]>(businessNumbers);
	const [selectedNumbers, setSelectedNumbers] = useState<
		BusinessNumberModel[] | null
	>(null);
	const [selectAllClicked, setSelectAllClicked] = useState(false);

	useEffect(() => {
		if (data.filter((p) => p.selected === false)) {
			setSelectAllClicked(false);
		}
	}, []);

	const handleOnOkClicked = () => {
		setSelectedNumbers(() => data.filter((p) => p.selected));

		if (onSelect && data.filter((p) => p.selected)) {
			onSelect(data.filter((p) => p.selected));
		}

		onClose();
	};

	const handleClose = () => {
		onClose();
	};

	const handleDeSelectAll = () => {
		const updatedData = data.map((n) => ({
			...n, // Spread existing properties
			selected: false, // Update the `selected` property
		}));

		setSelectAllClicked(false);
		setData(updatedData); // Update state with the new array reference
	};

	const handleSelectAll = () => {
		const updatedData = data.map((n) => ({
			...n, // Spread existing properties
			selected: true, // Update the `selected` property
		}));
		setSelectAllClicked(true);
		setData(updatedData); // Update state with the new array reference
	};

	return (
		<>
			{data.filter((p) => p.status === "Approved").length === 0 ? (
				// Render this when there are no approved items
				<div className="bg-white p-6 rounded-lg w-full">
					<span className="font-semibold text-black text-xl w-full text-justify">
						No more approved business numbers available. Please add
						more number using the Number tab under the registration
						menu.
					</span>
				</div>
			) : (
				<div className="bg-white flex flex-col items-center justify-center gap-4 p-6 w-full rounded-2xl max-h-[1000px]">
					<span className="text-gray-800 min-h-12 flex items-center justify-center uppercase drop-shadow-lg text-2xl tracking-wider font-semibold border-b-4 border-orange-600 w-full p-2">
						{"Select O/G Business Number(s)"}
					</span>

					<InputBox
						label={"Filter your O/G Business numbers"}
						type={"text"}
						onChange={function (value: string): void {
							console.log(`filtering OG numbers ${value}`);
						}}
					></InputBox>

					<div className="w-full">
						<a
							href="#"
							onClick={() =>
								selectAllClicked
									? handleDeSelectAll()
									: handleSelectAll()
							}
							className="ps-2 text-left font-semibold text-blue-600 underline"
						>
							{selectAllClicked ? "deselect all" : "select all"}
						</a>
					</div>

					<div className="flex flex-col items-center justify-center w-full gap-2 overflow-y-scroll max-h-[600px] scroll-pt-12">
						{data.length > 5 && (
							<>
								{/* hack */}
								<br></br>
								<br></br>
								<br></br>
								<br></br>
								<br></br>
							</>
						)}
						{data.map((number, index) => (
							<>
								<div
									className={`flex items-center justify-center p-2 px-3 w-full rounded-lg ${
										number.selected
											? "bg-gradient-to-r from-sky-300 to-sky-200"
											: "bg-gradient-to-r from-sky-200 to-sky-100"
									} `}
								>
									<div className="flex items-center justify-start gap-2 w-full">
										<CheckBox
											onChange={(
												value: boolean
											): void => {
												const updatedData = data.map(
													(item, idx) =>
														idx === index
															? {
																	...item,
																	selected:
																		value,
																}
															: item
												);
												setData(updatedData); // Update state with the new array reference
											}}
											checked={number.selected}
										/>
										<span className="font-semibold text-lg">
											+{number.businessNumber}
										</span>
									</div>
									<StatusBadge
										status={number.status}
									></StatusBadge>
								</div>
							</>
						))}
					</div>
					<div className="flex items-center justify-center gap-2 py-4 w-full">
						<ButtonPro
							label={"OK"}
							onClick={handleOnOkClicked}
						></ButtonPro>
						<ButtonPro
							variant={ButtonVariant.Secondary}
							label={"close"}
							onClick={handleClose}
						></ButtonPro>
					</div>
				</div>
			)}
		</>
	);
};

export default BusinessNumbersList;
