import { ApiService } from "src/network/apiService";


import {
	NewApplicationModel,
	BusinessListModel,
	ContactCentreModel,
	MobileAppModel,
} from "src/types/models/apiModels";

export class NewApplicationViewModel {
	private apiService: ApiService;

	constructor(accessToken: string) {
		this.apiService = new ApiService({ accessToken: accessToken });
	}

	async validateApplication(
		application: NewApplicationModel
	): Promise<string[]> {
		const errors: string[] = [];
		if (!application.brandingOption) {
			errors.push("brandingOption is required");
		}
		if (!application.businessId) {
			errors.push("businessId is required");
		}
		if (!application.brandingApplicationId) {
			errors.push("brandingApplicationId required");
		}
		return errors;
	}

	async submitApplication(
		application: NewApplicationModel
	): Promise<{ result: boolean; error?: string }> {
		console.log("####application", application);
		const input = {
			countryCode: "AU",
			brandingApplicationId: application.brandingApplicationId,
			contactCentreId: application.contactCentreId,
			businessId: application.businessId,
			businessUnitId: "",
			brandingOption: application.brandingOption,
			businessNumbers: application.businessNumbers,
			scProvidedNumbers: application.scProvidedNumbers,
		};
		console.log("####input", input);
		try {
			const response: any = await this.apiService.postAsync(
				"/onboard/branding/application",
				input
			);
			if (response) {
				if (response.scResponse && response.scResponse.code === 2000) {
					return { result: true };
				} else {
					return {
						result: false,
						error: response.scResponse?.message,
					};
				}
			}
		} catch (error) {
			return {
				result: false,
				error: "There was an error performing the operation",
			};
		}

		return {
			result: true,
			error: "There was an error performing the operation",
		};
	}

	async fetchBusinessList(): Promise<BusinessListModel[]> {
		const response = await this.apiService.getAsync<any>(
			"/onboard/business/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				return response.scResponse.data;
			}

			return [];
		}

		return [];
	}

	async fetchContactCentreList(): Promise<ContactCentreModel[]> {
		const response = await this.apiService.getAsync<any>(
			"/onboard/contact-centre/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				return response.scResponse.data;
			}

			return [];
		}

		return [];
	}
	async fetchMobileAppList(): Promise<MobileAppModel[]> {
		const response = await this.apiService.getAsync<any>(
			"/onboard/branding-app/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				return response.scResponse.data;
			}

			return [];
		}

		return [];
	}
}
