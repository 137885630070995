/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import { Box } from "@mui/material";
import Sidebar from "src/components/organisms/sidebar/Sidebar";
import Application from "src/components/organisms/application/application";
import { MenuItems } from "src/types/models/menu";
import Numbers from "src/components/organisms/numbers/numbers";
import MobileApp from "src/components/organisms/mobile-app/mobile-app";
import Business from "src/components/organisms/business-main/business-main";
import UserProfile from "src/components/organisms/user-profile/user-profile";
import UserManagement from "src/components/organisms/user-management/user-management";
import HighLevelStatistics from "src/components/organisms/high-level-statistics/high-level-statistics";
import BusinessTrialUsers from "src/components/organisms/business-trial-users/business-trial-users";
import ContactCentre from "src/components/organisms/contact-centre/contact-centre";

const Dashboard: React.FC = () => {
	const [containerMargine, setContainerMargine] = useState(20);
	const [menuSections, setMenuSections] = useState<MenuItems>(
		MenuItems.HelpDocumentation
	);

	const toggleDrawer = (open: boolean) => {
		const mar = open ? 20 : 20;
		setContainerMargine(mar);
	};
	const handleMenuClick = (sectionName: string) => {
		setMenuSections(() => sectionName as MenuItems);
	};

	const renderComponent = () => {
		switch (menuSections) {
			case MenuItems.RegistrationBusiness:
				return <Business></Business>;
			case MenuItems.RegistrationNumbers:
				return <Numbers></Numbers>;
			case MenuItems.RegistrationMobileApps:
				return <MobileApp></MobileApp>;
			case MenuItems.RegistrationContactCentres:
				return <ContactCentre></ContactCentre>;
			case MenuItems.RegistrationTrialUsers:
				return <BusinessTrialUsers></BusinessTrialUsers>;
			case MenuItems.BrandingConfigurations:
				return <Application></Application>;
			case MenuItems.BrandingApplication:
				return <Application></Application>;
			case MenuItems.UserProfile:
				return <UserProfile></UserProfile>;
			case MenuItems.UserManagement:
				return <UserManagement></UserManagement>;
			case MenuItems.HelpDocumentation:
				return <HighLevelStatistics></HighLevelStatistics>;
			default:
				return <HighLevelStatistics></HighLevelStatistics>;
		}
	};

	return (
		<div className="flex items-start justify-start h-full bg-gradient-to-r from-gray-700 to-gray-900">
			<Sidebar
				OnToggleDrawer={toggleDrawer}
				OnItemClick={handleMenuClick}
			/>
			{/* Main Content Area */}
			<div className="flex items-start justify-start h-full w-full ">
				{renderComponent()}
			</div>
		</div>
	);
};

export default Dashboard;
