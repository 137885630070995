// ValidatedInput.tsx
import React, { useState, useEffect } from "react";
import Input from "src/components/atoms/input/input";
import { FormInputProps } from "./validated-input.props";
import {
  is_aEmail,
  is_aNumber,
  is_aPhoneNumber,
  is_aString,
  is_aUrl,
} from "src/helpers/inputValidationHelper";

const ValidatedInput: React.FC<FormInputProps> = ({
  label,
  value,
  type,
  index,
  onChange,
  maxLength,
  disabled = false,
}) => {
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    // Validate initial value on mount
    validate(value);
  }, []);

  const validate = (value: string) => {
    let message = "";

    switch (type) {
      case "RequiredString":
      case "OptionalString":
        if (type === "RequiredString" && !value) {
          message = `${label} is required`;
          break;
        }
        message =
          value && !is_aString(value) ? `${value} is not a valid ${label}` : "";
        break;
      case "RequiredNumber":
      case "OptionalNumber":
        if (type === "RequiredNumber" && !value) {
          message = `${label} is required`;
          break;
        }
        message =
          value && !is_aNumber(value) ? `${value} is not a valid ${label}` : "";
        break;
      case "RequiredPhoneNumber":
      case "OptionalPhoneNumber":
        if (type === "RequiredPhoneNumber" && !value) {
          message = `${label} is required`;
          break;
        }
        message =
          value && !is_aPhoneNumber(value)
            ? `${value} is not a valid ${label}`
            : "";
        break;
      case "RequiredEmail":
      case "OptionalEmail":
        if (type === "RequiredEmail" && !value) {
          message = `${label} is required`;
          break;
        }
        message =
          value && !is_aEmail(value) ? `${value} is not a valid ${label}` : "";
        break;
      case "RequiredUrl":
      case "OptionalUrl":
        if (type === "RequiredUrl" && !value) {
          message = `${label} is required`;
          break;
        }
        message =
          value && !is_aUrl(value) ? `${value} is not a valid ${label}` : "";
        break;
      default:
        message = "";
        break;
    }
    setValidationError(message ? message : null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (onChange) {
      onChange(newValue, index);
    } // Notify parent of the change
    validate(newValue);
  };

  return (
    <Input
      label={label}
      required={type.startsWith("Required")}
      value={value}
      onChange={handleInputChange}
      error={!!validationError}
      helpText={validationError || undefined}
      maxLength={maxLength}
      disabled={disabled}
    />
  );
};

export default ValidatedInput;
