// src/components/PageNotFound.tsx
import { Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = () => {
  return (
    <Container style={{ textAlign: "center", padding: "50px" }}>
      <Typography variant="h3" color="error" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="h6" gutterBottom>
        The page you are looking for might have been removed or is temporarily
        unavailable.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Home
      </Button>
    </Container>
  );
};

export default PageNotFound;
