import { ApiService } from "src/network/apiService";

import { LoggedInUser } from "../auth/auth-context";
import { InputType } from "src/types/models/common";
import {
  BusinessDetailsModel,
  RegionalAttribute,
} from "src/types/models/apiModels";

export class BusinessDetailsViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async mapAttributeType(
    type: string,
    isRequired: boolean
  ): Promise<InputType> {
    switch (type) {
      case "N":
        return isRequired === true ? "RequiredNumber" : "OptionalNumber";

      default:
        return isRequired === true ? "RequiredString" : "OptionalString";
    }
  }

  async fetchMasterAttributes(
    countryCode: string
  ): Promise<RegionalAttribute[]> {
    const returnData: RegionalAttribute[] = [];
    const response = await this.apiService.getAsync<any>(
      `/onboard/regional-attributes/${countryCode}`
    );

    if (response) {
      if (response.scResponse && response.scResponse.code === 2000) {
        const data = response.scResponse.data;
        if (
          !data ||
          data.length === 0 ||
          !data[0].attributes ||
          data[0].attributes.length === 0
        ) {
          return returnData;
        }
        for (let index = 0; index < data[0].attributes.length; index++) {
          const element = data[0].attributes[index];
          const type = await this.mapAttributeType(
            element.dataType,
            element.isMandatory
          );
          returnData.push({
            name: element.name,
            value: "",
            type: type,
            title: element.title
          });
        }
      }

      return returnData;
    }

    return returnData;
  }

  async initBusinessDetails(
    user: LoggedInUser,
    countryCode: string,
    businessDetails?: BusinessDetailsModel
  ): Promise<BusinessDetailsModel> {
    const attributes = businessDetails
      ? []
      : await this.fetchMasterAttributes(countryCode);

    const initModel: BusinessDetailsModel = {
      fields: businessDetails ? businessDetails.fields : attributes,
    };
    return initModel;
  }
}
