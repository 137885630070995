import { ApiService } from "src/network/apiService";
import { ServiceStatus } from "src/types/models/common";
import {
	BusinessListModel,
	ContactCentreModel,
	MobileAppModel,
	NewNumberModel,
} from "src/types/models/apiModels";
import { ApiResponse } from "src/network/models/ApiResponse";

export class HighLevelStatisticsViewModel {
	private apiService: ApiService;

	constructor(accessToken: string) {
		this.apiService = new ApiService({ accessToken: accessToken });
	}

	async fetchBusinessList(): Promise<
		{ business: BusinessListModel; numbers: NewNumberModel[] }[]
	> {
		const returnData: {
			business: BusinessListModel;
			numbers: NewNumberModel[];
		}[] = [];
		const response = await this.apiService.getAsync<any>(
			"/onboard/business/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				const businesses = response.scResponse.data;
				for (let index = 0; index < businesses.length; index++) {
					const element = businesses[index];
					const numbers = await this.fetchBusinessNumberList(
						element.businessId
					);
					returnData.push({ business: element, numbers: numbers });
				}
				return returnData;
			}

			return [];
		}

		return [];
	}

	async fetchBusinessNumberList(
		businessId: string
	): Promise<NewNumberModel[]> {
		const url = `/onboard/business/${businessId}/number/list`;
		// console.log(url);

		const result = await this.apiService.getAsync<ApiResponse<any[]>>(url);

		if (result.scResponse.code === 2000) {
			const data = result.scResponse.data;

			const mappedData: NewNumberModel[] = data.map((number) => ({
				number: number.businessNumber.startsWith("+")
					? number.businessNumber
					: `+${number.businessNumber}`,

				ownershipDocuments: number.ownershipDocuments.map(
					(doc: any) => ({
						fileName: doc.fileName || "Unknown file",
						version: doc.version || "1.0",
					})
				),
				status: number.status as ServiceStatus, // Cast status if needed
			}));

			// console.log(`Returning data -> ${JSON.stringify(mappedData)}`);
			return mappedData;
		}
		return [];
	}

	async fetchContactCentreList(): Promise<ContactCentreModel[]> {
		const response = await this.apiService.getAsync<any>(
			"/onboard/contact-centre/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				return response.scResponse.data;
			}

			return [];
		}

		return [];
	}
	async fetchMobileAppList(): Promise<
		{ mobileApp: MobileAppModel; countOfCalls: number }[]
	> {
		const returnData: {
			mobileApp: MobileAppModel;
			countOfCalls: number;
		}[] = [];
		const response = await this.apiService.getAsync<any>(
			"/onboard/branding-app/list"
		);

		if (response) {
			if (response.scResponse && response.scResponse.code === 2000) {
				const apps = response.scResponse.data;
				for (let index = 0; index < apps.length; index++) {
					const element = apps[index];

					const countResponse = await this.apiService.getAsync<any>(
						`/branding/counts?brandingAppCode=${element.id}`
					);
					// console.log("^^^^^countResponse", countResponse);
					returnData.push({
						mobileApp: element,
						countOfCalls: countResponse.scResponse.data.count ?? 0,
					});
				}
				return returnData;
			}

			return [];
		}

		return [];
	}
}
