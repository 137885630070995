import { FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import "./business-info.css";

import { BusinessInfoProps } from "./business-info.props";
import { useEffect, useState } from "react";

import ValidatedInput from "src/components/molecules/validated-input/validated-input";
import SectionHeader from "src/components/molecules/section-header/section-header";
import ImageUploader from "src/components/molecules/imageUploader/imageUploader";
import { BusinessInfoModel, IFileInfo } from "src/types/models/apiModels";
import { start } from "repl";

const BusinessInfo: React.FC<BusinessInfoProps> = ({ info, onChange }) => {
	const defaultImage = "https://via.placeholder.com/150";
	const [businessInfo, setBusinessInfo] = useState<BusinessInfoModel>(
		info ?? {
			businessId: "",
			businessCode: "",
			name: "",
			website: "",
			dunsNumber: "",
			status: "New",
			countryCode: "AU",
		}
	);
	const [selectedImage, setSelectedImage] = useState(defaultImage);

	useEffect(() => {
		if (onChange) {
			onChange(businessInfo);
		}
	}, [businessInfo]);

	const handleFieldChange = (
		field: keyof typeof businessInfo,
		value: string
	) => {
		setBusinessInfo((prevInfo: BusinessInfoModel) => ({
			...prevInfo,
			[field]: value,
		}));
	};
	const handleImageUpload = async (image: IFileInfo | undefined) => {
		console.log("In handleImageUpload!", image);
		setBusinessInfo((prevInfo: BusinessInfoModel) => ({
			...prevInfo,
			logo: image,
		}));
		setSelectedImage(image?.signedUrl ?? defaultImage);
	};
	const handleTrialUsersToggle = (event: any) => {
		handleFieldChange("trialEnabled", event.target.checked);
		console.log(
			"trialEnabled",
			event.target.checked ? "Enabled" : "Disabled"
		);
	};
	return (
		<>
			<SectionHeader title="Business Details"></SectionHeader>
			<Grid container spacing={2} className="form-section">
				<Grid item xs={12} style={{ width: "100%" }}>
					<ImageUploader
						image={businessInfo.logo}
						onUploadSuccess={handleImageUpload}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidatedInput
						label="Business Name"
						value={businessInfo?.name ?? ""}
						type="RequiredString"
						onChange={(value) => handleFieldChange("name", value)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidatedInput
						label="Website"
						value={businessInfo?.website ?? ""}
						type="RequiredUrl"
						onChange={(value) =>
							handleFieldChange("website", value)
						}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidatedInput
						label="Duns Number"
						value={businessInfo?.dunsNumber ?? ""}
						type="OptionalString"
						onChange={(value) =>
							handleFieldChange("dunsNumber", value)
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<Tooltip
						title="Turn on this switch to request a trial for the business"
						arrow
					>
						<FormControlLabel
							sx={{ alignContent: "start" }}
							control={
								<Switch onChange={handleTrialUsersToggle} />
							}
							label="Enable trial users"
						/>
					</Tooltip>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessInfo;
