import React, { useState, useEffect } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

interface IInputProps {
	label: string;
	type: string;
	value?: string;
	onChange: (value: string) => void;
	required?: boolean;
	disabled?: boolean;
	validations?: ((value: string) => string | null)[]; // Array of validation functions
}

const InputBox: React.FC<IInputProps> = ({
	label,
	type,
	value = "",
	onChange,
	required = false,
	disabled = false,
	validations = [], // Default to an empty array
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [validationError, setValidationError] = useState<string | null>(null);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		// Run validations on value change
		for (const validate of validations) {
			const error = validate(value);
			if (error) {
				setValidationError(error);
				return;
			}
		}
		setValidationError(null); // No errors
	}, [value, validations]);

	return (
		<div
			className={`relative z-0 w-full flex flex-col items-start justify-center ${
				disabled ? "opacity-50 cursor-not-allowed" : ""
			}`}
		>
			<div className="relative w-full">
				<input
					type={
						type === "password" && !showPassword
							? "password"
							: "text"
					}
					id="floating_standard"
					value={value}
					onChange={(e) => onChange(e.target.value)}
					className="block py-2.5 px-0 w-full font-semibold text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-100 appearance-none dark:border-gray-400 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
					placeholder=" "
					disabled={disabled}
				/>
				{validationError && (
					<div className="w-full flex items-center justify-start pt-1">
						<span className="font-semibold text-xs text-red-500">
							{validationError}
						</span>
					</div>
				)}
				<label
					htmlFor="floating_standard"
					className={`absolute font-semibold text-md text-gray-900 duration-300 transform scale-75 -translate-y-6 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 ${
						value
							? "scale-75 -translate-y-6 left-0"
							: "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 left-0"
					}`}
				>
					{label}
					{required && (
						<span className="text-sm text-red-500"> *</span>
					)}
				</label>
				<div className="flex flex-row items-center justify-center gap-2">
					{value && type === "password" && (
						<span
							className="absolute right-6 top-0 h-full flex items-center pr-3 cursor-pointer"
							onClick={togglePasswordVisibility}
						>
							{showPassword ? (
								<EyeSlashIcon className="w-5 h-5 text-gray-500" />
							) : (
								<EyeIcon className="w-5 h-5 text-gray-500" />
							)}
						</span>
					)}
					{value && (
						<button
							type="button"
							className="absolute right-0 top-0 h-full flex items-center pr-3 cursor-pointer"
							onClick={() => onChange("")}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-5 h-5 text-gray-500"
							>
								<path
									fillRule="evenodd"
									d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default InputBox;
