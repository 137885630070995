export type InputType =
  | "RequiredString"
  | "OptionalString"
  | "RequiredNumber"
  | "OptionalNumber"
  | "RequiredPhoneNumber"
  | "OptionalPhoneNumber"
  | "RequiredEmail"
  | "OptionalEmail"
  | "RequiredUrl"
  | "OptionalUrl";

export type CustomAttributeType = "S" | "N" | "B" | "D" | "P";

export type OutGoingOnMobileOption = "AllOutgoing" | "Fallback" | "None";

export enum APNSCertificateType {
  P8 = "P8",
  P12 = "P12",
}

export type TBrandingOptions =
  | "CPaaSManagedCallBranding"
  | "UnmanagedCallBranding";

export type ServiceStatus =
  | "New"
  | "Approved"
  | "Active"
  | "Paused"
  | "Added"
  | "Pending";
