import React, { useEffect, useState } from "react";
import { Box, TextField, IconButton, Fab, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { InputArrayProps } from "./input-array.props";
import ValidatedInput from "../validated-input/validated-input";

const InputArray: React.FC<InputArrayProps> = ({
	inputString,
	label,
	onChange,
}) => {
	const [inputStrings, setInputStrings] = useState<string[]>(
		inputString ?? [""]
	);

	useEffect(() => {
		if (onChange) {
			onChange(inputStrings);
		}
	}, [inputStrings]);

	const handleAddUrl = () => {
		setInputStrings([...inputStrings, ""]); // Add a new empty textbox
	};

	const handleInputChange = (value: string, index?: number) => {
		const updatedUrls = [...inputStrings];
		updatedUrls[index ?? 0] = value;
		setInputStrings(updatedUrls);
	};

	const handleSaveUrls = () => {
		const filteredUrls = inputStrings.filter((url) => url.trim() !== "");
		setInputStrings(filteredUrls);
		console.log("Saved URLs:", filteredUrls);
	};
	const handleDeleteUrl = (index: number) => {
		setInputStrings(inputStrings.filter((_, i) => i !== index));
	};

	return (
		<Box>
			<Grid container spacing={2} alignItems="center">
				{inputStrings.map((url, index) => (
					<Grid item xs={12} key={index}>
						<Grid container spacing={1} alignItems="center">
							<Grid item xs={11}>
								<ValidatedInput
									type="RequiredUrl"
									label={`${label} ${index + 1}`}
									value={url}
									index={index}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item>
								<IconButton
									color="error"
									onClick={() => handleDeleteUrl(index)}
									disabled={inputStrings.length === 1}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				))}
				<Grid item xs={12}>
					<Grid container justifyContent="flex-start">
						<Fab
							color="primary"
							aria-label="add"
							onClick={handleAddUrl}
						>
							<AddIcon />
						</Fab>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default InputArray;
