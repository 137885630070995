import { Typography } from "@mui/material";
import { SectionTitleProps } from "./section-title.props";
import "./section-title.css";

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <>
      <Typography className="section-title" align="left">
        {props.title}
      </Typography>
    </>
  );
};

export default SectionTitle;
