import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { BusinessContactProps } from "./business-contact.props";
import "./business-contact.css";

import { BusinessContactViewModel } from "./business-contact.view-model";
import { useAuth } from "../auth/auth-context";

import Input from "src/components/atoms/input/input";
import SectionHeader from "src/components/molecules/section-header/section-header";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import PhoneNumberInput from "src/components/atoms/phone-number-input/phone-number-input";
import { ContactModel } from "src/types/models/apiModels";

const BusinessContact: React.FC<BusinessContactProps> = ({
	editContact,
	useLoggedInUser,
	onChange,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState<ContactModel | undefined>(
		editContact
	);
	const viewModel = useRef<BusinessContactViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessContactViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.initContact(
						useLoggedInUser ?? false,
						user
					);
					console.log("initBusiness", response);
					setContact(() => response);
				}
			} catch (error) {
				console.error("Error fetching initBusiness:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		if (onChange && contact) {
			onChange(contact);
		}
	}, [contact]);

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		);
	}
	return (
		<>
			<SectionHeader title="Primary contact"></SectionHeader>
			<Grid container spacing={2} className="form-section">
				<Grid item xs={12} sm={6}>
					<Input
						label="First Name"
						value={contact?.firstName ?? ""}
						disabled={true}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label="Last Name"
						value={contact?.lastName ?? ""}
						disabled={true}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label="Email Address"
						value={contact?.emailId ?? ""}
						disabled={true}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<PhoneNumberInput
						value={
							contact?.phoneNumber ?? {
								countryCode: "",
								number: "",
							}
						}
						disabled={true}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessContact;
