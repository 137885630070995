import React from "react";

interface IIPhoneProps {
	brandingImage: string;
	businessName: string;
	intent: string;
}

const IPhone: React.FC<IIPhoneProps> = ({
	brandingImage,
	businessName,
	intent,
}) => {
	return (
		<>
			<div className="flex flex-col items-center justify-center gap-4 drop-shadow-2xl">
				<div className="relative mx-auto border-gray-950 dark:border-gray-950 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
					<div className="h-[32px] w-[3px] bg-gray-950 dark:bg-gray-950 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
					<div className="h-[46px] w-[3px] bg-gray-950 dark:bg-gray-950 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
					<div className="h-[46px] w-[3px] bg-gray-950 dark:bg-gray-950 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
					<div className="h-[64px] w-[3px] bg-gray-950 dark:bg-gray-950 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
					<div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-gray-800">
						<div className="bg-gradient-to-bl from-gray-700 to-gray-900 h-full w-full flex flex-col items-center justify-between pb-8 pt-4 px-4">
							<div className="flex flex-col items-center justify-center w-full">
								{/* Dynamic Island */}
								<div className="bg-black h-8 w-28 rounded-2xl"></div>
								<div className="flex items-start space-x-4 pt-12 ps-4 w-full">
									{/* Image Circle */}
									<div className="h-16 w-16 rounded-full flex-shrink-0">
										<img
											src={brandingImage}
											alt=""
											className="w-16 h-16 rounded-full"
										></img>
									</div>

									{/* Text Content */}
									<div className="flex flex-col items-start justify-start text-left">
										<label className="font-Poppins font-normal text-white">
											{intent}
										</label>
										<label className="font-Poppins font-semibold text-lg text-white">
											{businessName}
										</label>
									</div>
								</div>
							</div>
							<div className="flex items-center justify-start gap-4 bg-gradient-to-r from-gray-900 to-slate-500 text-white p-2 rounded-full w-full mx-4 drop-shadow-lg">
								<div className="flex items-center justify-center bg-white h-14 w-14 rounded-full">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth="4"
										className="size-6 text-blue-500"
									>
										<path
											fillRule="evenodd"
											d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
											clipRule="evenodd"
										/>
									</svg>
								</div>

								<label className="font-Poppins font-semibold bg-gradient-to-bl from-gray-900 to-white bg-clip-text text-transparent">
									slide to answer
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IPhone;
